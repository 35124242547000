/* eslint-disable no-debugger */
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AnimatePresence } from 'framer-motion';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Layout from '../components/ui/layouts/layout/Layout';
import Preloader from '../components/ui/loaders/preloader/Preloader';
import LandingRoutes from '../routes/LandingRoutes';
import Head from '../components/head/Head';
import { RootStore } from '../store/rootStore';
import {
   getInitialLayoutConfigAction,
   getDefaultLanguage,
} from '../store/app/appAction';
import { selectAppLayout, selectServicesState } from '../store/selectors';
import { getUrlParams } from '../utils/helpers';
import ErrorFallback from '../components/ui/errorFallback/ErrorFallback';
import StickyNavbar from '../components/navbar/stickyNavbar/StickyNavbar';
import { useLocalStorage } from '../utils/hooks';
import { BROWSER_STORAGE_KEYS } from '../utils/constants';
import BannerRoutes from '../routes/BannerRoutes';
import { getBalanceAction } from '../store/user/userAction';
import BottomNavigation from '../components/bottomNavigation/BottomNavigation';
import CookieConsent from '../utils/cookiesConsent';

const App = () => {
   const { pathname } = useLocation();
   const { keycloak } = useKeycloak();
   const [cookie, setCookie] = useCookies([BROWSER_STORAGE_KEYS.cookies.sessionId]);
   const localStorageDefaultLanguage = window?.localStorage.getItem(
      BROWSER_STORAGE_KEYS.localStorage.defaultLanguage,
   );

   const [localStorageValue, setLocalStorageValue] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.userId,
      '',
   );

   const hideLayoutOnCategoryBannerRoute = pathname.includes('banner');
   const dispatch = useDispatch();
   const {
      favIcon,
      includeHeader,
      font,
      metaDescription,
      siteTitle,
      stickyHeader,
      htmlFooter,
      gaCode,
      activeMomentsApi,
      momentsApiKey,
      enableBottomNavigation,
      subHeader,
   } = useSelector(selectAppLayout, shallowEqual);

   const isAppReady = useSelector((state: RootStore) => state.app.states.isAppReady);

   const { isServicesReady } = useSelector(selectServicesState);

   // Set analytics
   if (gaCode) {
      ReactGA.initialize(gaCode);
      ReactGA.send({
         hitType: 'pageview',
         page: window.location.pathname + window.location.search,
         title: 'gaSent',
      });
   }
   // Set local storage
   if (!localStorageValue) {
      setLocalStorageValue(uuidv4());
   }
   // Set cookie
   const isCookieSet = BROWSER_STORAGE_KEYS.cookies.sessionId in cookie;

   if (!isCookieSet) {
      setCookie(BROWSER_STORAGE_KEYS.cookies.sessionId, uuidv4());
   }

   useEffect(() => {
      const queryParams = getUrlParams();
      if (!localStorageDefaultLanguage) {
         (dispatch(getDefaultLanguage(queryParams)) as any).then(() =>
            dispatch(getInitialLayoutConfigAction(queryParams)),
         );
      } else {
         dispatch(getInitialLayoutConfigAction(queryParams)); // If lang is not in localStorage, dispatch the action with undefined.
      }
   }, []);

   useEffect(() => {
      if (activeMomentsApi && momentsApiKey) {
         (window as any)?.pe?.track('sfopen');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [momentsApiKey, activeMomentsApi]);

   useEffect(() => {
      if (keycloak.authenticated && keycloak.token) {
         dispatch(getBalanceAction(keycloak.token));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [keycloak.authenticated, keycloak.token]);

   return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
         <>
            <HelmetProvider>
               <Head
                  title={siteTitle ?? ' '}
                  description={metaDescription ?? ''}
                  favicon={favIcon}
                  font={font}
                  apiKey={activeMomentsApi ? momentsApiKey : null}
               />
            </HelmetProvider>
            <CookieConsent />
            <AnimatePresence>
               {!isAppReady && !isServicesReady ? (
                  <Preloader key="loader" />
               ) : (
                  <>
                     {!hideLayoutOnCategoryBannerRoute && (
                        <>
                           {subHeader && parse(subHeader ?? '')}

                           {/* <div
                              className="sticky-banner"
                              style={{ display: 'none' }}
                           >
                              <div className="first-column">
                                 <img src={SteamCards} alt="" />
                                 <img src={ClaroLogo} alt="" />
                              </div>
                              <div className="second-column">
                                 Brings you closer to the games you love to play
                              </div>
                              <div className="third-column">
                                 <div className="button-column-wrap">
                                    <Button
                                       title="Say HI to our AI !"
                                       className="register-button"
                                       onClick={() => {
                                          window.location.href =
                                             'https://wa.me/385997828570';
                                       }}
                                    />
                                    <img
                                       className="ai-avatar"
                                       src={CuantoAvatar}
                                       alt=""
                                    />
                                 </div> */}
                           {/* <a
                                    href="https://wa.me/385997828570"
                                    target="_blank"
                                    rel="noreferrer"
                                 >
                                    Say hi to our AI !
                                 </a> */}
                           {/* </div>
                           </div> */}
                           {/* <div
                              className="red-stripe-links-container"
                              style={{ display: 'none' }}
                           >
                              <a href="https://demo.gobile.ca/">GOBILE PLAY</a>
                              <a href="https://demo.gobile.ca/store">GOBILE DEALS</a>
                              <a href="https://demo.gobile.ca/sign-in">
                                 GOBILE POINTS
                              </a>
                              <a href="https://demo.gobile.ca/events">GOBILE WIN</a>
                              <a href="https://wa.me/385997828570">CUANTO AI</a>
                           </div> */}
                           <Layout key="content">
                              {stickyHeader && includeHeader && <StickyNavbar />}
                              <LandingRoutes />
                           </Layout>
                        </>
                     )}
                     <BannerRoutes />
                     {/* Footer */}
                     {htmlFooter &&
                        !hideLayoutOnCategoryBannerRoute &&
                        parse(htmlFooter ?? '')}
                     {enableBottomNavigation && <BottomNavigation />}
                  </>
               )}
            </AnimatePresence>
         </>
      </ErrorBoundary>
   );
};

export default App;
