import React from 'react';
import cx from 'classnames';
import { PaymentMethod } from '../../../store/services/servicesTypes';

export type PaymentMethodTypes = 'phone' | 'mbon';
export interface PaymentMethodBoxProps {
   label: string;
   name: string;
   iconUrl?: string;
   id?: string;

   selectedPaymentMethod: PaymentMethod;
   onClick: () => void;
   clearInputsCallback?: (name: string) => void;
}

const PaymentMethodBox = ({
   label,
   name,
   iconUrl,
   id,
   selectedPaymentMethod,
   onClick,
   clearInputsCallback,
}: PaymentMethodBoxProps) => {
   const onPaymentBoxClickHandler = () => {
      onClick();
      clearInputsCallback?.(name || '');
   };

   return (
      <div
         tabIndex={0}
         role="button"
         aria-labelledby="button-label"
         onClick={onPaymentBoxClickHandler}
         className={cx(
            'payment-method-box',
            selectedPaymentMethod.id === id && 'active',
         )}
      >
         <img src={iconUrl || ''} alt="" />
         <h3 className="payment-method-title">{label || '### LABEL MISSING ###'}</h3>
      </div>
   );
};

export default PaymentMethodBox;
