import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import { useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import ActiveServiceCard from '../../../components/services/activeServices/activeServicesCard/ActiveServiceCard';
import ServiceRow from '../../../components/services/serviceRow/ServiceRow';
import ActivePageTitleWithBack from '../../../components/ui/activePageTitleWithBack/ActivePageTitleWithBack';
import SubHeader from '../../../components/ui/subHeader/SubHeader';
import HeaderIconMobile from '../../../assets/img/icon-phone.svg';
import HeaderIconEmail from '../../../assets/img/icon-mail.svg';
import { ReactComponent as NoACtiveServicesIcon } from '../../../assets/img/icon-no-active-service.svg';
import Animation from '../../../components/ui/animation/Animation';
import {
   selectActiveServicesState,
   selectAppLayout,
   selectServicesState,
   selectValidationState,
} from '../../../store/selectors';
import { Routes } from '../../../utils/constants';

import {
   deactivateServiceAction,
   resetPinTimer,
   resetConsentAction,
} from '../../../store/services/servicesAction';
import Preloader from '../../../components/ui/loaders/preloader/Preloader';
import Button from '../../../components/ui/button/Button';
import Intro from '../../../components/ui/intro/Intro';
import ActiveServicesConsent from '../../../components/ui/consent/ActiveServicesConsent';
import { ReactComponent as CheckIcon } from '../../../assets/img/checkmark-icon.svg';

const ActiveServices = () => {
   const history = useHistory();
   const dispatch = useDispatch();

   const { isLoading } = useSelector(selectServicesState, shallowEqual);
   const { all, phoneNo, email } = useSelector(
      selectActiveServicesState,
      shallowEqual,
   );
   const { pin } = useSelector(selectValidationState, shallowEqual);
   const {
      activeServicesHeader,
      domain,
      defaultLanguage,
      emptyUserServicesLabel,
      emptyUserServicesHeaderLabel,
      activeServicesTitle,
      activeServicesDesc,
      backButtonLabel,
      emptyActiveServicesImageURL,
      syncLabel,
      unsubscribeWaitMessage,
      unsubscribeErrorMessage,
   } = useSelector(selectAppLayout, shallowEqual);

   const { isPinSent } = pin;

   useEffect(() => {
      return () => {
         dispatch(resetConsentAction());
      };
   });

   /**
    * * Redirect to home if user refresh Active Services page
    */

   // eslint-disable-next-line no-unused-expressions
   !isPinSent ? history.push(Routes.HOME_ROUTE) : dispatch(resetPinTimer());

   /**
    *
    * * Check are there Activated services available based on array length
    */

   const onDeactivateServiceHandler = (token: string | undefined | null) => {
      const queryForDeactivateSubscription = queryString.stringify({
         domain,
         language: defaultLanguage,
         unsubscribeToken: token,
      });
      if (unsubscribeWaitMessage) {
         toast.success(unsubscribeWaitMessage, {
            duration: 15000,
         });
      }
      dispatch(
         deactivateServiceAction(
            queryForDeactivateSubscription,
            unsubscribeErrorMessage,
         ),
      );
   };

   return (
      <Animation animationType="page">
         <Toaster
            containerClassName="toaster-container"
            toastOptions={{
               className: 'toaster',
               icon: <CheckIcon />,
            }}
         />
         <section className="active-services-page">
            {activeServicesHeader === 'DESCRIPTION' ? (
               <Intro
                  type="text"
                  title={activeServicesTitle}
                  activeServiceAccessLabel={phoneNo || email || ''}
                  description={activeServicesDesc}
               />
            ) : (
               <>
                  <SubHeader
                     icon={phoneNo ? HeaderIconMobile : HeaderIconEmail}
                     text={phoneNo || email}
                  />
                  <ActivePageTitleWithBack
                     text={
                        activeServicesTitle ||
                        '#### No localization for param activeServicesTitle ####'
                     }
                  />
               </>
            )}

            <div className="sync-label">
               {syncLabel && <h3>{parse(syncLabel || '')}</h3>}
            </div>

            {all.length > 0 ? (
               <>
                  <ServiceRow
                     key="row1"
                     categoryTitle=""
                     className="active-services-row"
                  >
                     {all.map((item, i) => (
                        <ActiveServiceCard
                           key={`${item.unsubscribeToken} ${item.name} ${i}`}
                           image={item.imageUrl}
                           title={item.name}
                           description={item.description}
                           price={item.price}
                           currency={item.currency}
                           subscribedTime={item.timeSubscribedLocal}
                           unsubscribedTime={item.timeUnsubscribedLocal}
                           timeChargedLocal={item.timeChargedLocal}
                           expirationTime={item.expirationTimeLocal}
                           subscriptionPeriod={item.subscriptionPeriod}
                           unsubscribeToken={item.unsubscribeToken}
                           deactivateService={onDeactivateServiceHandler}
                           packageName={item.packageName}
                        />
                     ))}

                     {isLoading && <Preloader />}
                  </ServiceRow>
               </>
            ) : (
               <div className="container-width">
                  <div className="no-active-services-container">
                     <div className="no-service-icon-container">
                        {emptyActiveServicesImageURL ? (
                           <img alt="" src={emptyActiveServicesImageURL} />
                        ) : (
                           <NoACtiveServicesIcon />
                        )}
                     </div>
                     <h2 className="empty-user-services-header">
                        {emptyUserServicesHeaderLabel ||
                           '#### No localization for param emptyUserServicesHeaderLabel ####'}
                     </h2>
                     <p className="empty-user-services-label">
                        {emptyUserServicesLabel ||
                           '#### No localization for param emptyUserServicesHeaderLabel ####'}
                     </p>
                     <div className="button-container">
                        <Button
                           className="return-home-from-no-active-services"
                           title={backButtonLabel}
                           onClick={() => history.push(Routes.HOME_ROUTE)}
                        />
                     </div>
                  </div>
               </div>
            )}

            <ActiveServicesConsent />
         </section>
      </Animation>
   );
};

export default ActiveServices;
