import React from 'react';
import { Helmet } from 'react-helmet-async';
import { addPeopleScriptSDK } from '../../utils/helpers';

interface SEOProps {
   title: string;
   description: string;
   favicon: string;
   font: string;
   apiKey: string | null;
}

const Head = ({ title, description, favicon, font, apiKey }: SEOProps) => {
   return (
      <Helmet
         title={title || 'Storefront'}
         meta={[
            {
               name: `description`,
               content: description,
            },
         ]}
         link={[
            { rel: 'icon', type: 'image/png', href: favicon },
            // { rel: 'stylesheet', href: font },
            {
               rel: 'preload stylesheet',
               as: 'style',
               href: font,
               crossOrigin: 'anonymous',
            },
         ]}
      >
         <script>{addPeopleScriptSDK(apiKey)}</script>
      </Helmet>
   );
};

export default Head;
