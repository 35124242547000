import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectAppLayout } from '../store/selectors';

const CookieConsent = () => {
   const { domain } = useSelector(selectAppLayout, shallowEqual);

   useEffect(() => {
      console.log(domain);
      if (domain === 'play.pluxee.uy') {
         const script = document.createElement('script');
         script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
         script.type = 'text/javascript';
         script.charset = 'UTF-8';
         script.dataset.domainScript = '018fab2a-9d24-7c26-ab4d-67520f2bb3de';
         document.body.appendChild(script);

         const script2 = document.createElement('script');
         script2.type = 'text/javascript';
         script2.innerHTML = 'function OptanonWrapper() { }';
         document.body.appendChild(script2);

         return () => {
            document.body.removeChild(script);
            document.body.removeChild(script2);
         };
      }
   }, [domain]);

   return null;
};

export default CookieConsent;
