import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isMobile } from 'react-device-detect';
import classes from './CategoryFilter.module.scss';
import SearchInput from '../form/input/searchInput/SearchInput';
import { sendAnalyticActions } from '../../store/app/appAction';
import { useCheckDomain } from '../../utils/hooks';
import { DOMAINS } from '../../utils/constants';
import {
   searchServicesAction,
   clearSearchServicesAction,
} from '../../store/services/servicesAction';
import {
   selectAppLayout,
   selectCategoryFilterLinks,
   selectDisplayedServices,
} from '../../store/selectors';

interface CategoryFilterProps {
   onClick?: (categoryName: string) => void;
}

const CategoryFilter = ({ onClick }: CategoryFilterProps) => {
   const location = useLocation();
   const dispatch = useDispatch();

   // App state
   const { selectedCategory } = useSelector(selectDisplayedServices);
   const { allCategoriesFilterLabel, domain } = useSelector(
      selectAppLayout,
      shallowEqual,
   );
   const categoryFilterLinks = useSelector(selectCategoryFilterLinks);

   // Local state
   const [searchValue, setSearchValue] = useState('');
   const [activeCategory, setActiveCategory] = useState(selectedCategory);
   const [isSticky, setIsSticky] = useState(false);
   const [, setDirection] = useState('up');

   const titleRef = useRef<any>([]);
   let oldScrollY = 0;
   const isSodexoDomain = useCheckDomain(DOMAINS.sodexo);
   const isAlmadarDomain =
      domain === 'almadar-store.ly' || domain === 'stage-almadar.centili.com';

   const searchServiceHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
         dispatch(searchServicesAction(e.target.value));
         setSearchValue(e.target.value);
         //* refactor this later
         if (isAlmadarDomain) {
            titleRef.current.scrollIntoView({
               behavior: 'smooth',
            });
         } else {
            window.scrollTo(0, isSodexoDomain ? 0 : 500);
         }
         return null;
      },
      [dispatch, isSodexoDomain],
   );

   const categoryChangedHandler = (categoryName: string) => {
      dispatch(
         sendAnalyticActions({
            domain,
            category: categoryName,
         }),
      );

      // Clear search on category change
      dispatch(clearSearchServicesAction());
      setSearchValue('');

      window.location.hash =
         categoryName.charAt(0).toLowerCase() + categoryName.slice(1);
      //* refactor this later
      if (isAlmadarDomain) {
         titleRef.current.scrollIntoView({
            behavior: 'smooth',
         });
      } else {
         window.scrollTo(0, isSodexoDomain ? 0 : 500);
      }
      setActiveCategory(categoryName);
      onClick?.(categoryName);
   };

   useEffect(() => {
      const pathWithoutHashtag = location.hash?.slice(1);
      const capitalizeHashPath =
         pathWithoutHashtag.charAt(0).toUpperCase() + pathWithoutHashtag.slice(1);

      const categoriesContainHashPath =
         categoryFilterLinks.includes(capitalizeHashPath);

      if (categoriesContainHashPath) {
         setActiveCategory(capitalizeHashPath);
         onClick?.(capitalizeHashPath);
         if (!isMobile) {
            window.scrollTo(0, 500);
         }
      } else {
         setActiveCategory('all');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [categoryFilterLinks]);

   // Logic
   const controlDirection = () => {
      if (window.scrollY > oldScrollY) {
         setDirection('down');
      } else {
         setDirection('up');
      }
      oldScrollY = window.scrollY;
   };

   // Effects
   useEffect(() => {
      window.addEventListener('scroll', controlDirection);
      return () => {
         window.removeEventListener('scroll', controlDirection);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const cachedRef = titleRef.current;
      const observer = new IntersectionObserver(
         ([e]) => {
            setIsSticky(e.intersectionRatio < 1);
         },

         { threshold: [1] },
      );
      observer.observe(cachedRef);

      return () => {
         observer.unobserve(cachedRef);
      };
   }, []);

   return (
      <>
         <div
            ref={titleRef}
            className={cx(
               'category-filter',
               isSticky ? 'category-filter-mobile-shadow' : '',
            )}
         >
            <div className={cx('category-filter-menu')}>
               {/* All services link-always fixed */}
               <span
                  className={cx(
                     'category-link',
                     classes.categoryLink,
                     activeCategory === 'all' && 'category-link-active',
                  )}
                  aria-hidden="true"
                  onClick={() => categoryChangedHandler('all')}
               >
                  {allCategoriesFilterLabel ??
                     '### No localization found for allCategoriesFilterLabel ###'}
               </span>

               {categoryFilterLinks.map((category, i) => (
                  <span
                     className={cx(
                        'category-link',
                        classes.categoryLink,
                        activeCategory === category && 'category-link-active',
                     )}
                     aria-hidden="true"
                     key={`${category}+${i}`}
                     onClick={() => categoryChangedHandler(category)}
                  >
                     {category}
                  </span>
               ))}
            </div>
            <SearchInput value={searchValue} onChange={searchServiceHandler} />
         </div>
      </>
   );
};

export default CategoryFilter;
