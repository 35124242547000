import cx from 'classnames';
import classes from './ServiceDescription.module.scss';
import { ReactComponent as HelpIcon } from '../../../assets/img/icon-help.svg';

interface TosProps {
   helpLinkLabel?: string;
   helpLinkUrl?: null | string;
   tosLabel?: string;
   tosUrlLabel?: string;
   tosUrl?: null | string;
}
const TOS = ({
   helpLinkLabel,
   helpLinkUrl,
   tosLabel,
   tosUrlLabel,
   tosUrl,
}: TosProps) => {
   return (
      <div className={cx('terms-and-conditions', classes.termsAndConditions)}>
         {tosUrl && (
            <div className={classes.tnsWrap}>
               <span className={cx('tns-link')}>
                  {tosLabel}{' '}
                  <a target="blank" href={tosUrl} rel="noreferrer">
                     {tosUrlLabel}
                  </a>
               </span>
            </div>
         )}
         {helpLinkUrl && (
            <div className={classes.helpLinkWrap}>
               <a target="blank" className={cx('help-link')} href={helpLinkUrl}>
                  {helpLinkLabel}
               </a>
               <HelpIcon />
            </div>
         )}
      </div>
   );
};

export default TOS;
