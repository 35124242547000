import Keycloak from 'keycloak-js';

const domainKeycloakMap = {
   'stage-store.centili.com': '/dev-keycloak-sf-stage-store.json',
   'stage-sf.centili.com': '/dev-keycloak-sf-store-demo.json',
   'stage-personal.centili.com': '/dev-keycloak-sf-py-personal-qa.json',
   'marketspace.centili.com': '/sf_marketspace.json',
   'play.pluxee.uy': '/sf_uy_sodexo.json',
   'pluxee-stage.centili.cloud': '/sf_uy_sodexo_qa.json',
   'stage-oodi.centili.com': '/sf_iq_oodi_qa.json',
};

const currentDomain = window.location.hostname;

const keycloakPath = domainKeycloakMap[currentDomain] || '/sf_marketspace.json';

const keycloak = new Keycloak(keycloakPath);

export default keycloak;
