import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Slider from '../../components/slider/Slider';
import Services from '../../components/services/Services';
import Animation from '../../components/ui/animation/Animation';
import { selectAppLayout } from '../../store/selectors';
import ActiveServicesSection from '../../components/services/activeServices/ActiveServicesSection';
import Accordion from '../../components/ui/accordion/Accordion';
import ActiveServicesSectionPersonal from '../../components/services/activeServices/ActiveServicesSectionPersonal';
import { ReactComponent as CheckIcon } from '../../assets/img/checkmark-icon.svg';
import IntroSection from '../../components/ui/intro/IntroSection';

const Homepage = () => {
   const {
      includeSlider,
      enableAccordionTos,
      accordionTosLabel,
      accordionTosText,
      domain,
   } = useSelector(selectAppLayout);

   return (
      <>
         <Animation animationType="page">
            <Toaster
               containerClassName="toaster-container"
               toastOptions={{
                  className: 'toaster',
                  icon: <CheckIcon />,
               }}
            />
            <IntroSection />
            {includeSlider && <Slider />}
            {domain === 'stage-personal.centili.com' && (
               <ActiveServicesSectionPersonal />
            )}
            <Services />

            {enableAccordionTos && (
               <Accordion title={accordionTosLabel} text={accordionTosText} />
            )}
            {domain !== 'stage-personal.centili.com' && <ActiveServicesSection />}
         </Animation>
      </>
   );
};

export default Homepage;
