import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface iFrameResizer extends Window {
   iFrameResizer: any;
   parentIFrame: any;
}

const ScrollToTop = () => {
   const { pathname } = useLocation();

   useEffect(() => {
      document.querySelector('body')?.scrollTo(0, 0);
      window.scrollTo(0, 0);
      if ('parentIFrame' in window) {
         (window as unknown as iFrameResizer).parentIFrame.scrollTo(0, 0);
      }
   }, [pathname]);

   return null;
};

export default ScrollToTop;
