import SwiperCore, {
   Navigation,
   Pagination,
   Scrollbar,
   A11y,
   EffectFade,
   Autoplay,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classes from './Slider.module.scss';

import Slide from './slide/Slide';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]);

const Slider = () => {
   return (
      <Swiper
         speed={600}
         effect="fade"
         grabCursor
         className={`slider ${classes.slider}`}
         spaceBetween={50}
         slidesPerView={1}
         navigation
         pagination={{ clickable: true }}
         // scrollbar={{ draggable: true }}
         autoplay={{
            delay: 4500,
            disableOnInteraction: false,
         }}
      >
         <SwiperSlide>
            <Slide
               // img={BLACK}
               img="https://mir-s3-cdn-cf.behance.net/project_modules/fs/4aded661826065.5a7b1faeb6401.png"
               title="Avatar: Hero"
               description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500Hold onto your chimichangas, folks. From the studio that brought you all 3 Taken films comes the block-busting, fourth-wall-breaking masterpiece about Marvel Comics sexiest anti-hero! Starring God’s perfect idiot Ryan Reynolds and a bunch of other actors"
            />
         </SwiperSlide>
         <SwiperSlide>
            <Slide
               // img={BLACK}
               img="https://mir-s3-cdn-cf.behance.net/project_modules/fs/4aded661826065.5a7b1faeb6401.png"
               title=""
               description=""
            />
         </SwiperSlide>
         <SwiperSlide>
            <Slide
               // img={BLACK}
               img=""
               title="Avatar: Hero"
               description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500Hold onto your chimichangas, folks. From the studio that brought you all 3 Taken films comes the block-busting, fourth-wall-breaking masterpiece about Marvel Comics sexiest anti-hero! Starring God’s perfect idiot Ryan Reynolds and a bunch of other actors"
            />
         </SwiperSlide>
         <SwiperSlide>
            <Slide
               img="DW"
               title="Star Wars"
               description="L is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
            />
         </SwiperSlide>
         <SwiperSlide>
            <Slide
               img=""
               title="Dark Knight"
               description="The man logging the most hours in the Batcave, of course, was William West Anderson, whom you probably know better as Adam West. Either you love him for his goofy charm or hate him for blemishing the Bat’s image for several decades.  His campy, over-the-top portrayal of Gotham’s Guardian infiltrated nearly every medium, including a 1966 movie and several animated series.  "
            />
         </SwiperSlide>

         <SwiperSlide>
            <Slide
               img="https://images.hdqwalls.com/wallpapers/pubg-land-loot-survive-kx.jpg"
               title="PUGB "
               description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500Hold onto your chimichangas, folks. From the studio that brought you all 3 Taken films comes the block-busting, fourth-wall-breaking masterpiece about Marvel Comics sexiest anti-hero! Starring God’s perfect idiot Ryan Reynolds and a bunch of other actors"
            />
         </SwiperSlide>
      </Swiper>
   );
};

export default Slider;
