import { motion } from 'framer-motion';
import cx from 'classnames';
import classes from './Preloader.module.scss';
import defaultLoader from '../../../../assets/img/centili-loader.gif';
import { PRELOADER } from '../../../../utils/constants';
import { getDomainFromUrl } from '../../../../utils/helpers';

type LoaderType = typeof PRELOADER;
type LoaderElement<T> = (T extends (infer U)[] ? U : never) | undefined;
const MEGASTORE_DOMAIN = 'megastore';

const Preloader = () => {
   const domain = getDomainFromUrl();
   const isMegastoreBlackPreloader = domain.includes(MEGASTORE_DOMAIN);
   const loaderType: LoaderElement<LoaderType> = PRELOADER.find((loaders) =>
      domain.includes(loaders.type),
   );

   const renderLoader = () => {
      if (loaderType) {
         // eslint-disable-next-line prefer-destructuring
         const loader: any = loaderType.loader;

         if (typeof loader === 'string') {
            return <img src={loaderType.loader as string} alt="loader" />;
         }
         if (loaderType.loader) {
            const Loader = loaderType.loader;
            return <Loader />;
         }
      }
      // Default fallback
      return <img src={defaultLoader} alt="loader" />;
   };

   return (
      <motion.div
         id="preloader"
         data-testid="preloader"
         key="modal"
         initial={{ opacity: 1 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}
         transition={{ delay: 1 }}
         {...(isMegastoreBlackPreloader && {
            style: { background: '#121212' },
         })}
         className={cx(
            'preloader',
            classes.preloaderContainer,
            classes[loaderType?.class || 'default'],
            classes[loaderType?.class || 'default'],
         )}
      >
         {renderLoader()}
      </motion.div>
   );
};

export default Preloader;
