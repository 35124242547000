import React from 'react';
import cx from 'classnames';
import classes from './AddInput.module.scss';
import { ReactComponent as AddIcon } from '../../../assets/img/icon-add.svg';

export interface AddInputProps {
   text: string;
   onClick: () => void;
   disabled: boolean;
}
const AddInput = ({ text, onClick, disabled }: AddInputProps) => {
   return (
      <div className={classes.addInputWrap}>
         <AddIcon />
         <span
            role="button"
            tabIndex={0}
            onClick={onClick}
            className={cx('add-input-text', { [classes.disabled]: disabled })}
         >
            {text}
         </span>
      </div>
   );
};

export default AddInput;
