import { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import {
   selectAppLayout,
   selectUser,
   selectUserBalance,
} from '../../store/selectors';
import Button from '../../components/ui/button/Button';
import classes from './UserProfilePage.module.scss';
import { RedLoader } from '../../components/ui/loaders/loaders/Loaders';
import {
   deactivateUserServiceAction,
   getActivatedServicesWithTokenAction,
   getBalanceAction,
   getBalanceHistoryAction,
} from '../../store/user/userAction';
import { ReactComponent as BalanceIcon } from '../../assets/img/coin-icon.svg';
import { ReactComponent as CompanyIcon } from '../../assets/img/company-icon.svg';
import { ReactComponent as TransactionIcon } from '../../assets/img/transaction-icon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/img/Icon metro-user.svg';

import {
   formatIsoDate,
   formatSimpleDate,
   getDomainWithoutProtocol,
   getRootUrl,
} from '../../utils/helpers';
import { BROWSER_STORAGE_KEYS } from '../../utils/constants';
import { useLocalStorage } from '../../utils/hooks';
import InlineEmailEdit from '../../components/ui/inlineEmailEdit/inlineEmailEdit';
import { emailChange } from '../../store/services/servicesAction';
import GoBackButton from '../../components/ui/button/goBackButton/GoBackButton';

const UserProfilePage = () => {
   const dispatch = useDispatch();
   const [showAllServices, setShowAllServices] = useState(false);
   const [showAllPurchases, setShowAllPurchases] = useState(false);

   const { keycloak } = useKeycloak();
   const [, , removeLoginAttempt] = useCookies([
      BROWSER_STORAGE_KEYS.cookies.loginAttempt,
   ]);

   const {
      signOutLabel,
      userProfileCompanyLabel,
      userProfileBalanceLabel,
      userProfileWalletHistoryLabel,
      userProfileActivatedServicesLabel,
      domain,
      stopSubscription,
      virtualCurrencyCode,
      oneTimePaymentLabel,
      subscriptionLabel,
      defaultLanguage,
      topUpUrl,
      enableTopUp,
      enableTopUpLabel,
      readMoreLabel,
      readLessLabel,
      userProfileEmptyActiveServices,
      userProfileEmptyWalletHistory,
   } = useSelector(selectAppLayout);
   const user = useSelector(selectUser);
   const { amount } = useSelector(selectUserBalance);

   const [, setLocalStorageLoginSuccessful] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.loginSuccessfull,
      false,
   );

   const [localStorageSelectedLanguage] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.selectedLanguage,
      '',
   );

   const unsubscribe = (token: string | undefined | null) => {
      const queryForDeactivateSubscription = queryString.stringify({
         domain,
         language: defaultLanguage,
         unsubscribeToken: token,
      });
      dispatch(deactivateUserServiceAction(queryForDeactivateSubscription));
   };

   const logoutHandler = () => {
      keycloak.logout({ redirectUri: getRootUrl() });
      removeLoginAttempt(BROWSER_STORAGE_KEYS.cookies.loginAttempt);
      setLocalStorageLoginSuccessful(false);
   };

   const prodRedirectHandler = () => {
      keycloak.logout({ redirectUri: 'https://connect.pluxee.app/am/uy/profile' });
   };

   const stageRedirectHandler = () => {
      keycloak.logout({
         redirectUri: 'https://connect.uat.pluxee.app/am/uy/profile',
      });
   };

   useEffect(() => {
      if (keycloak.authenticated && keycloak.token) {
         if (virtualCurrencyCode) {
            dispatch(getBalanceAction(keycloak.token));
            dispatch(getBalanceHistoryAction(keycloak.token));
         }
         // todo change domain before release
         dispatch(
            getActivatedServicesWithTokenAction(
               keycloak.token,
               getDomainWithoutProtocol(),
            ),
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (!keycloak.authenticated) {
         keycloak.login();
      }
   }, [keycloak]);
   // eslint-disable-next-line no-debugger
   const seeMoreLabel =
      readMoreLabel || '#### No localization for param readMoreLabel ####';
   const seeLessLabel =
      readLessLabel || '#### No localization for param readLessLabel ####';
   const companyName = keycloak.tokenParsed?.company || '';
   const keycloakMail = keycloak.tokenParsed?.email || '';

   const [email, setEmail] = useState(keycloakMail);
   const [emailErrorMessage, setEmailErrorMessage] = useState('');

   const handleUpdateEmail = async (newEmail: string) => {
      const error: any = await dispatch(
         emailChange(
            newEmail,
            newEmail,
            localStorageSelectedLanguage || defaultLanguage,
            keycloak.token!,
         ),
      );
      setEmailErrorMessage(error);
      if (!error) {
         setEmail(newEmail);
      }
      setTimeout(() => {
         setEmailErrorMessage('');
      }, 10000);
   };
   console.log(domain);

   return (
      <>
         <div className={classes.backButtonContainer}>
            <GoBackButton />
         </div>

         <div className={cx(classes.userInfo, 'user-info-container')}>
            <div className={classes.firstColumn}>
               <>
                  <ProfileIcon />
                  <InlineEmailEdit
                     email={email}
                     onUpdateEmail={handleUpdateEmail}
                     errorMessage={emailErrorMessage}
                  />
                  {companyName && (
                     <span>
                        <CompanyIcon className={classes.companyIcon} />
                        <h3 className={classes.companyName}>
                           {userProfileCompanyLabel} {companyName}
                        </h3>
                     </span>
                  )}
                  <div className={cx(classes.balanceContainer, 'balance-container')}>
                     {virtualCurrencyCode && (
                        <p>
                           <span className={classes.balanceLabel}>
                              {userProfileBalanceLabel} &nbsp;{' '}
                           </span>
                           <span>
                              <BalanceIcon /> {amount}
                           </span>
                        </p>
                     )}
                     {enableTopUp && (
                        <Button
                           enableTopUp={enableTopUp}
                           title={enableTopUpLabel}
                           className={cx(
                              classes.topUpButton,
                              'button top-up-button',
                           )}
                           onClick={() => {
                              window.location.href = topUpUrl;
                           }}
                        />
                     )}
                  </div>
                  <Button
                     title={signOutLabel}
                     className={cx('logout', classes.signOutButton)}
                     onClick={logoutHandler}
                  />
                  {domain === 'play.pluxee.uy' && (
                     <Button
                        title="Editar perfil"
                        className={cx('logout', classes.signOutButton)}
                        onClick={prodRedirectHandler}
                     />
                  )}
                  {domain === 'pluxee-stage.centili.cloud' && (
                     <Button
                        title="Editar perfil"
                        className={cx('logout', classes.signOutButton)}
                        onClick={stageRedirectHandler}
                     />
                  )}
               </>
            </div>
            {user.isLoading || user.activeServices.isLoading ? (
               <div className={cx(classes.loaderWrapper, 'loader-wrap')}>
                  <RedLoader />
               </div>
            ) : (
               <>
                  <div className={cx(classes.secondColumn, 'second-column')}>
                     <div className={cx(classes.title, 'user-profile-title')}>
                        {userProfileActivatedServicesLabel}
                     </div>
                     {user.activeServices.services.length > 0 ? (
                        user.activeServices.services
                           .slice(
                              0,
                              showAllServices
                                 ? user.activeServices.services.length
                                 : 5,
                           )
                           .map((svc, i) => (
                              <div
                                 key={`${svc.name} ${i}`}
                                 className={classes.serviceTile}
                              >
                                 <img
                                    src={svc.imageUrl}
                                    className={classes.serviceImage}
                                    alt="serviceImage"
                                 />
                                 <div
                                    className={cx(
                                       classes.serviceData,
                                       'service-data',
                                    )}
                                 >
                                    <div
                                       className={cx(
                                          classes.dateType,
                                          'active-service-date',
                                       )}
                                    >
                                       {formatSimpleDate(
                                          svc.timeSubscribedLocal ||
                                             svc.timeChargedLocal,
                                       )}{' '}
                                       /{' '}
                                       {svc.subscriptionPeriod
                                          ? subscriptionLabel
                                          : oneTimePaymentLabel}
                                    </div>
                                    <div
                                       className={cx(
                                          'active-service-name',
                                          classes.serviceName,
                                       )}
                                    >
                                       {svc.name}
                                    </div>
                                    <div
                                       className={cx(
                                          'active-service-balance',
                                          classes.servicePrice,
                                       )}
                                    >
                                       <BalanceIcon /> {svc.price}
                                    </div>
                                    {svc.unsubscribeToken && (
                                       <div
                                          role="button"
                                          tabIndex={0}
                                          onClick={() =>
                                             unsubscribe(svc.unsubscribeToken)
                                          }
                                          className={classes.unsubscribeLink}
                                       >
                                          {stopSubscription}
                                       </div>
                                    )}
                                 </div>
                              </div>
                           ))
                     ) : (
                        <>
                           <div
                              className={cx(
                                 'balance-no-icon-container',
                                 classes.noIconsContainer,
                              )}
                           >
                              <img
                                 src="https://marketspace.centili.com/res/sf/img/icon-no-services.png"
                                 alt="no-services"
                              />
                           </div>
                           <p className={classes.noActiveServiceTitle}>
                              {userProfileEmptyActiveServices ||
                                 '#### No localization for param userProfileEmptyActiveServices ####'}
                           </p>
                        </>
                     )}
                     {user.activeServices.services.length > 5 && (
                        <Button
                           title={showAllServices ? seeLessLabel : seeMoreLabel}
                           className={cx('show-more-button', classes.showMoreBtn)}
                           onClick={() => setShowAllServices(!showAllServices)}
                        />
                     )}
                  </div>
                  <div
                     className={cx(
                        classes.transactionsColumn,
                        'transactions-column',
                     )}
                  >
                     <div className={cx(classes.title, 'user-profile-title')}>
                        {userProfileWalletHistoryLabel}
                     </div>
                     {user.balanceHistory.length > 0 ? (
                        user.balanceHistory
                           .slice(
                              0,
                              showAllPurchases ? user.balanceHistory.length : 10,
                           )
                           .map((item, i) => (
                              <div
                                 key={i}
                                 className={cx(
                                    classes.transactionItem,
                                    'transaction-item',
                                 )}
                              >
                                 <TransactionIcon
                                    className={classes.transactionIcon}
                                 />
                                 <div className={classes.transactionDetails}>
                                    <div className={classes.lowOpacityDate}>
                                       {formatIsoDate(item.timeCreated)}
                                    </div>
                                    <div
                                       className={cx(
                                          'balance-history-name',
                                          classes.name,
                                       )}
                                    >
                                       {item.description}
                                    </div>
                                 </div>
                                 <div
                                    className={cx('balance-history-price', {
                                       [classes.negative]:
                                          parseInt(item.amount, 10) < 0,
                                       [classes.positive]:
                                          parseInt(item.amount, 10) > 0,
                                    })}
                                 >
                                    <BalanceIcon />{' '}
                                    {parseInt(item.amount, 10).toFixed(2)}
                                 </div>
                              </div>
                           ))
                     ) : (
                        <>
                           <div
                              className={cx(
                                 'balance-no-icon-container',
                                 classes.noIconsContainer,
                              )}
                           >
                              <img
                                 src="https://marketspace.centili.com/res/sf/img/icon-no-transations.png"
                                 alt="no-services"
                              />
                           </div>
                           <p className={classes.noActiveServiceTitle}>
                              {userProfileEmptyWalletHistory ||
                                 '#### No localization for param userProfileEmptyWalletHistory ####'}
                           </p>
                        </>
                     )}
                     {user.balanceHistory.length > 10 && (
                        <Button
                           title={showAllPurchases ? seeLessLabel : seeMoreLabel}
                           className={cx('show-more-button', classes.showMoreBtn)}
                           onClick={() => setShowAllPurchases(!showAllPurchases)}
                        />
                     )}
                  </div>
               </>
            )}
         </div>
      </>
   );
};

export default UserProfilePage;
