/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/button-has-type */
import cx from 'classnames';

import SwiperCore, {
   Navigation,
   Pagination,
   Scrollbar,
   A11y,
   Autoplay,
} from 'swiper';
import { Swiper } from 'swiper/react';
import { useSelector } from 'react-redux';
import React from 'react';
import classes from './ServiceRow.module.scss';
import {
   BIG_SLIDER_BREAKPOINTS,
   BIG_SLIDER_BREAKPOINTS_THUMBNAILS,
} from '../../../utils/constants';
import {
   ServiceGridProperties,
   SliderProperties,
} from '../../../store/services/servicesTypes';
import { selectAppLayout, selectSearchParam } from '../../../store/selectors';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

interface ServiceRowProps {
   categoryTitle?: string;
   children: React.ReactNode;
   className?: string;
   gridProperties?: ServiceGridProperties;
   sliderProperties?: SliderProperties;
   gridType?: string | null;
}

type ServiceRowVariants =
   | 'CAROUSEL'
   | 'DEFAULT'
   | 'SLIDING'
   | 'THUMBNAILS'
   | 'EXPANDABLE_GRID'
   | 'BIGBOX'
   | 'BANNER'
   | 'MULTI_IMAGE';
type PackageComponentObject = Record<ServiceRowVariants, React.ReactElement>;

const ServiceRowDefault = ({
   categoryTitle,
   children,
   className,
   gridProperties,
   gridType,
}: ServiceRowProps) => {
   const searchParam = useSelector(selectSearchParam);

   return (
      <section
         className={cx('service-row', classes.serviceRow, className, {
            [classes.hidden]: searchParam?.length > 0 && gridType === 'BIGBOX',
         })}
      >
         <h1
            className={cx(
               'main-title',
               'service-card-title',
               classes.serviceCategoryTitle,
            )}
         >
            {categoryTitle}
         </h1>

         <div
            className={cx(
               'service-row-grid',
               classes.serviceRowGrid,
               classes[`col-${gridProperties?.rowSize ?? 4}`],
            )}
         >
            {children}
         </div>
      </section>
   );
};

const ServiceRowSlider = ({
   categoryTitle,
   children,
   gridProperties,
   sliderProperties,
}: Omit<ServiceRowProps, 'className' | 'gridType'>) => {
   const width = window.innerWidth;
   const breakPoints = width < 1000 ? BIG_SLIDER_BREAKPOINTS : undefined;

   return (
      <section
         className={cx('new-services-wrap', 'service-row', classes.newServicesWrap)}
      >
         <h1
            className={cx(
               'main-title',
               'service-card-title',
               classes.serviceCategoryTitle,
            )}
         >
            {categoryTitle}
         </h1>
         <div>
            <Swiper
               style={{ direction: `${sliderProperties?.direction || 'ltr'}` }}
               breakpoints={breakPoints}
               loop
               // navigation
               grabCursor
               className={classes.newServiceSlider}
               speed={sliderProperties?.autoplaySpeed || 3500}
               slidesPerView={gridProperties?.rowSize ?? 4}
               spaceBetween={20}
               centeredSlides
               // navigation
               // scrollbar={{ draggable: true }}
               pagination={
                  sliderProperties?.showPagination
                     ? {
                          clickable: true,
                       }
                     : false
               }
               autoplay={
                  sliderProperties?.enableAutoplay
                     ? {
                          delay: 0,
                          disableOnInteraction: false,
                       }
                     : false
               }
            >
               {children}
            </Swiper>
         </div>
      </section>
   );
};

const ServiceRowThumbnailsSlider = ({
   categoryTitle,
   children,
   sliderProperties,
}: Pick<ServiceRowProps, 'categoryTitle' | 'children' | 'sliderProperties'>) => {
   const width = window.innerWidth;
   const breakPoints = width < 1000 ? BIG_SLIDER_BREAKPOINTS_THUMBNAILS : undefined;
   const searchParam = useSelector(selectSearchParam);
   const { domain } = useSelector(selectAppLayout);
   const isAlmadarDomain =
      domain === 'almadar-store.ly' || domain === 'stage-almadar.centili.com';

   const { direction } = document?.documentElement?.style;
   const slideDirection =
      direction === 'rtl' || direction === 'ltr' ? direction : 'ltr';

   return (
      <section
         className={cx('new-services-wrap', 'service-row', classes.newServicesWrap, {
            [classes.hidden]: searchParam?.length > 0,
         })}
      >
         <h1
            className={cx(
               'main-title',
               'service-card-title',
               classes.serviceCategoryTitle,
            )}
         >
            {categoryTitle}
         </h1>
         <div>
            <Swiper
               style={{
                  direction: `${sliderProperties?.direction || slideDirection}`,
               }}
               breakpoints={!isAlmadarDomain ? breakPoints : undefined}
               loop
               className={classes.newServiceSlider}
               speed={sliderProperties?.autoplaySpeed || 3500}
               slidesPerView={!isAlmadarDomain ? 9 : 'auto'}
               spaceBetween={10}
               pagination={
                  sliderProperties?.showPagination
                     ? {
                          clickable: true,
                       }
                     : false
               }
               autoplay={
                  sliderProperties?.enableAutoplay
                     ? {
                          delay: 0,
                          disableOnInteraction: false,
                       }
                     : false
               }
            >
               {children}
            </Swiper>
         </div>
      </section>
   );
};

const ServiceRowCarousel = ({
   categoryTitle,
   children,
   sliderProperties,
}: Pick<ServiceRowProps, 'categoryTitle' | 'children' | 'sliderProperties'>) => {
   return (
      <section className={classes.newServicesWrap}>
         <h1
            className={cx(
               'main-title',
               'service-card-title',
               classes.serviceCategoryTitle,
            )}
         >
            {categoryTitle}
         </h1>
         <div>
            <Swiper
               loop
               speed={sliderProperties?.autoplaySpeed || 3500}
               style={{
                  direction: `${sliderProperties?.direction || 'ltr'}`,
                  cursor: 'pointer',
               }}
               pagination={
                  sliderProperties?.showPagination
                     ? {
                          clickable: true,
                       }
                     : false
               }
               autoplay={
                  sliderProperties?.enableAutoplay
                     ? {
                          delay: 0,
                          disableOnInteraction: false,
                       }
                     : false
               }
               className={classes.newServiceSlider}
               slidesPerView={1}
               spaceBetween={20}
               centeredSlides

               // navigation
               // scrollbar={{ draggable: false }}
            >
               {children}
            </Swiper>
         </div>
      </section>
   );
};

const ServiceRow = ({ gridType, ...rest }: ServiceRowProps) => {
   const VARIANT = gridType as ServiceRowVariants;
   // const VARIANT = 'EXPANDABLE_GRID' as ServiceRowVariants;
   const SERVICE_ROW_COMPONENTS: PackageComponentObject = {
      DEFAULT: <ServiceRowDefault {...rest} />,
      CAROUSEL: <ServiceRowCarousel {...rest} />,
      SLIDING: <ServiceRowSlider {...rest} />,
      THUMBNAILS: <ServiceRowThumbnailsSlider {...rest} />,
      EXPANDABLE_GRID: <ServiceRowDefault {...rest} />,
      BIGBOX: <ServiceRowDefault gridType={gridType} {...rest} />,
      BANNER: <ServiceRowDefault {...rest} />,
      MULTI_IMAGE: <ServiceRowThumbnailsSlider {...rest} />,
   };

   return (
      <div>{SERVICE_ROW_COMPONENTS[VARIANT] ?? SERVICE_ROW_COMPONENTS.DEFAULT}</div>
   );
};

export default ServiceRow;
