import parse from 'html-react-parser';
// import { ReactComponent as A1Pictogram } from '../../../assets/img/A1-zabava-piktogram.svg';
// import { ReactComponent as A1Pictogram } from '../../../assets/img/a1-zabava-logo.svg';
import { useMediaQuery } from '../../../utils/hooks';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../utils/constants';

interface IntroProps {
   title?: string | null;
   mobileTitle?: string | null;
   activeServiceAccessLabel?: string;
   description: string | null;
   type?: 'image' | 'text';
}

const Intro = ({
   title,
   mobileTitle,
   description,
   activeServiceAccessLabel,
   type = 'image',
}: IntroProps) => {
   const isMobile = useMediaQuery(`(max-width: ${MEDIA_QUERY_BREAKPOINTS.phone})`);
   const titleText = isMobile && !!mobileTitle ? mobileTitle : title;
   return (
      <div className={type === 'image' ? 'intro-section' : 'intro-section-text'}>
         <div className="intro-section-wrap">
            {/* {type === 'image' && <A1Pictogram />} */}
            <h1 className="intro-title ">{titleText ?? ''}</h1>
            {activeServiceAccessLabel && (
               <h3 className="intro-phone ">{activeServiceAccessLabel}</h3>
            )}
            <p className="intro-description">{parse(description ?? '')}</p>
         </div>
      </div>
   );
};

export default Intro;
