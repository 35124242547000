import { createSelector } from 'reselect';
import { RootStore } from './rootStore';
import { ObjectKey } from '../types/globals';

// App selectors
export const selectAppStates = (state: RootStore) => state.app.states;
export const selectAppLayout = (state: RootStore) => state.app.layout;
export const selectAuthState = (state: RootStore) => state.app.auth;

// User selectors
export const selectUserBalance = (state: RootStore) => state.user.balance;
export const selectUser = (state: RootStore) => state.user;
// Transaction
export const selectTransaction = (state: RootStore) => state.services.transaction;

// Services selectors

// All services
export const selectAllServices = (state: RootStore) => state.services.all;
export const selectDisplayedServices = (state: RootStore) =>
   state.services.displayedServices;
export const selectSearchParam = (state: RootStore) => state.services.search;
export const selectServicesState = (state: RootStore) => state.services.states;
export const selectActiveServicesState = (state: RootStore) =>
   state.services.active.activeServices;
export const selectActiveServicesConsent = (state: RootStore) =>
   state.services.active.activeServicesConsent;
export const selectDisableConsentStatus = (state: RootStore) =>
   state.services.active.consentStatus;
export const selectValidationState = (state: RootStore) => state.services.validation;

// Form selectors
export const selectFormLoader = (state: RootStore) =>
   state.services.validation.form.isLoading;

// Single services
export const selectSingleServiceState = (state: RootStore) => state.services.single;
export const selectPhoneNumbers = (state: RootStore) => state.app.layout.msisdns;
export const selectEmails = (state: RootStore) => state.app.layout.emails as any;
export const selectParams = (state: RootStore) => state.services.single.params;
export const selectPaymentParams = (state: RootStore) =>
   state.services.single.paymentMethods;

// MEMOIZED SELECTORS

// Categories filter links (taken from all services)
export const selectCategoryFilterLinks = createSelector(
   [selectAllServices],
   (services) => {
      if (!services) return [];
      return services
         .map((service) => {
            if (service?.services.length === 0) return '';
            return service.categoryName || '###category null###';
         })
         .filter(Boolean);
   },
);

// Create msisdn phones to dropdown values
export const selectPhoneNumbersForDropdown = createSelector(
   [selectPhoneNumbers],
   (phoneNumbers) => {
      if (!phoneNumbers) return;

      if (typeof phoneNumbers === 'string') {
         return [{ value: phoneNumbers, label: phoneNumbers }];
      }

      return phoneNumbers.map((phones: string) => {
         return { value: phones, label: phones };
      });
   },
);

// Create emails to dropdown values
export const selectEmailsForDropdown = createSelector([selectEmails], (emails) => {
   if (!emails) return;

   if (typeof emails === 'string') {
      return [{ value: emails, label: emails }];
   }

   return emails.map((email: string) => {
      return { value: email, label: email };
   });
});

export const selectObjectFromParams = createSelector([selectParams], (params) => {
   if (!params) return;
   return params.reduce((acc: ObjectKey, item) => {
      acc[item.name] = ['String', 'Integer', 'Number'].includes(item.type) ? '' : [];
      return acc;
   }, {});
});

export const selectPaymentObjectFromParams = createSelector(
   [selectPaymentParams],
   (paymentParams) => {
      if (!paymentParams) return;
      return paymentParams.reduce((acc: ObjectKey, item) => {
         item.paymentMethodParams?.forEach((params) => {
            acc[params.name] = ['String', 'Integer', 'Number'].includes(
               params.columnType,
            )
               ? ''
               : [];
         }, {});

         return acc;
      }, {});
   },
);
