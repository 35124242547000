import cx from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import Image from '../../../ui/image/Image';
import Button from '../../../ui/button/Button';
// import classes from './ActiveServiceCard.module.scss';
import classes from './ActiveServiceCard.module.scss';
import { selectAppLayout } from '../../../../store/selectors';

interface ActiveServiceCardProps {
   image?: any;
   title?: string;
   description?: string;
   packageIcon?: string;
   price: string | number;
   currency: string;
   subscribedTime: string | null;
   unsubscribedTime: string | null;
   timeChargedLocal: string | null;
   expirationTime: string | null;
   subscriptionPeriod: string | null;
   categoryBadge?: string | null;
   unsubscribeToken?: string;
   packageName?: string | null;

   deactivateService?: (token: string | undefined | null) => void;
}

const ActiveServiceCard = ({
   image,
   title,
   description,
   packageIcon,
   price,
   currency,
   subscribedTime, //* Date of activation (Aktivirano - datum)
   unsubscribedTime, //* Date of subscription canceling (odjavljeno-datum)
   timeChargedLocal, //* Date of purchace (datum kupovine)
   expirationTime, //* Valid until - date (Vazi do - datum)
   subscriptionPeriod,
   unsubscribeToken,
   categoryBadge,
   deactivateService,
   packageName,
}: ActiveServiceCardProps) => {
   const {
      priceLocale,
      priceMaximumFractionDigits,
      priceMinimumFractionDigits,
      oneTimePaymentLabel,
      canceledActiveServicesButton,
      stopSubscription,
      dateOfPurchase,
      activated,
      validUntil,
      deactivated,
   } = useSelector(selectAppLayout, shallowEqual);

   let buttonTitle;

   if (timeChargedLocal) {
      buttonTitle = oneTimePaymentLabel;
   } else if (unsubscribedTime) {
      buttonTitle = canceledActiveServicesButton;
   } else {
      buttonTitle = stopSubscription;
   }

   const deactivateServiceHandler = (token: string | undefined | null) => {
      if (!token) return;
      deactivateService?.(token);
   };

   return (
      <div className={cx('service-card', 'error-field')}>
         <div className="service-card-image-wrap">
            <Image
               image={image}
               className={cx('service-card-image', classes.serviceImage)}
            />
            {categoryBadge && (
               <div className={cx('category-badge')}>{categoryBadge}</div>
            )}
         </div>

         <div className="service-info-wrap">
            <div className={classes.categories} />
            <div className="active-service-title">
               <div>
                  <span className="leftSpan">{title}</span>
                  <span className="package-name" style={{ display: 'none' }}>
                     {packageName}
                  </span>
               </div>
               <div className="price-span-container">
                  <span className="price">
                     {price.toLocaleString(
                        priceLocale != null ? priceLocale : 'en-US',
                        {
                           maximumFractionDigits: priceMaximumFractionDigits,
                           minimumFractionDigits: priceMinimumFractionDigits,
                        },
                     )}{' '}
                  </span>
                  <span className="currency"> {currency}</span>
                  {subscriptionPeriod && (
                     <span className="currency">/{subscriptionPeriod}</span>
                  )}
               </div>
            </div>
            <div className="token-container">
               {packageIcon && (
                  <span>
                     <img src="{packageIcon}" alt="icon" />
                  </span>
               )}
               <span className="package-info" />
            </div>
            <p className={cx('service-description', 'active-service-description')}>
               {parse(description || '')}
            </p>
            <hr />

            <div className="activation-info">
               {timeChargedLocal && (
                  <div>
                     <span>{dateOfPurchase}</span>
                     <span className="service-card-bold-items">
                        {timeChargedLocal}
                     </span>
                  </div>
               )}
               {unsubscribedTime === null && timeChargedLocal === null && (
                  <>
                     <div className="left">
                        <span>{activated} </span>
                        <span className="service-card-bold-items">
                           {subscribedTime}
                        </span>
                     </div>
                     {expirationTime && (
                        <div className="right">
                           <span>{validUntil} </span>
                           <span className="service-card-bold-items">
                              {expirationTime}
                           </span>
                        </div>
                     )}
                  </>
               )}
               {unsubscribedTime && [
                  <>
                     <div className="left">
                        <span>{activated} </span>
                        <span className="service-card-bold-items">
                           {subscribedTime}
                        </span>
                     </div>
                     {expirationTime && (
                        <div className="right">
                           <span>{validUntil} </span>
                           <span className="service-card-bold-items">
                              {expirationTime}
                           </span>
                        </div>
                     )}
                     {!expirationTime && (
                        <div className="right">
                           <span>{deactivated} </span>
                           <span className="service-card-bold-items">
                              {unsubscribedTime}
                           </span>
                        </div>
                     )}
                  </>,
               ]}
            </div>

            <Button
               title={buttonTitle || ''}
               onClick={() => deactivateServiceHandler(unsubscribeToken)}
               className={cx(
                  unsubscribedTime || timeChargedLocal
                     ? 'disabled-button'
                     : 'service-button',
               )}
            />
         </div>
      </div>
   );
};

export default ActiveServiceCard;
