import GoBackButton from '../button/goBackButton/GoBackButton';
import classes from './ActivePageTitleWithBack.module.scss';

export interface AddInputProps {
   text: string;
}
const ActivePageTitleWithBack = ({ text }: AddInputProps) => {
   return (
      <div className={classes.pageTitleContainer}>
         <GoBackButton className={classes.goBackBtnActive} />
         <span className={classes.pageLabel}>{text}</span>
      </div>
   );
};

export default ActivePageTitleWithBack;
