import { useState } from 'react';
import Modal from 'react-modal';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../../assets/img/icon-close.svg';
import {
   selectActiveServicesConsent,
   selectDisableConsentStatus,
   selectAppLayout,
} from '../../../store/selectors';
import Button from '../button/Button';
import { deactivateConsentAction } from '../../../store/services/servicesAction';

const ActiveServicesConsent = () => {
   const dispatch = useDispatch();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const { isConsentDisabled } = useSelector(selectDisableConsentStatus);
   const { domain } = useSelector(selectAppLayout);

   const consent = useSelector(selectActiveServicesConsent || {}, shallowEqual);
   const {
      active,
      disableContactDescription,
      disableContactDialogCancelLabel,
      disableContactDialogConfirmLabel,
      disableContactDialogDescription,
      disableContactLabel,
      disableContactTimeLabel,
      successfullyDisabledLabel,
      timeCreatedString,
      reference,
   } = consent || {};

   if (!active) return null;

   const onCloseHandler = () => {
      setIsModalOpen(false);
   };
   const disableConsentHandler = () => {
      dispatch(
         deactivateConsentAction(reference || '### reference missing ###', domain),
      );
   };

   const consentContent = (
      <>
         <h5 className="modal-title">{disableContactDialogDescription}</h5>
         <div className="consent-btn-container">
            <button
               onClick={disableConsentHandler}
               className="cancel-consent-button"
               type="button"
            >
               {disableContactDialogConfirmLabel}
            </button>

            <Button
               title={
                  disableContactDialogCancelLabel ||
                  '#### No localization for param disableBtnConsent ####'
               }
               onClick={onCloseHandler}
               className="button confirm-consent-button"
               name="activate"
            />
         </div>
      </>
   );
   return (
      <>
         {!isConsentDisabled && (
            <div className="active-services-consent">
               <div>
                  {disableContactDescription}{' '}
                  <span
                     className="open-modal-btn"
                     tabIndex={0}
                     role="button"
                     onClick={() => setIsModalOpen(!isModalOpen)}
                  >
                     {disableContactLabel}
                  </span>
               </div>

               <div className="consent-date-label">
                  {' '}
                  {disableContactTimeLabel} {timeCreatedString}
               </div>
            </div>
         )}
         <Modal
            overlayClassName="consent-modal-overlay"
            className="modal consent-modal"
            shouldCloseOnOverlayClick
            isOpen={isModalOpen}
            ariaHideApp={false}
         >
            <CloseIcon
               className="close-consent-modal-icon"
               onClick={onCloseHandler}
               fill="black"
            />

            {isConsentDisabled ? (
               <h4>{successfullyDisabledLabel}</h4>
            ) : (
               consentContent
            )}
         </Modal>
      </>
   );
};

export default ActiveServicesConsent;
