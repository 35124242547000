import React from 'react';
import Modal, { Props } from 'react-modal';
import cx from 'classnames';
import classes from './ConfirmationModal.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/img/close-icon.svg';

interface ConfirmModalProps {
   headerText: string;
   text: string;
   onConfirm: () => void;
   onCancel: () => void;
}

type ConfirmProps = Props & ConfirmModalProps;

const ConfirmationModal = ({
   headerText,
   text,
   isOpen,
   onConfirm,
   onCancel,
   onRequestClose,
}: ConfirmProps) => {
   return (
      <Modal
         isOpen={isOpen}
         className={cx(classes.confirmationModal, 'modal confirmation-modal')}
         style={{
            overlay: {
               backgroundColor: '#000000a8',
               zIndex: 9999,
            },
         }}
      >
         <>
            <div className={cx(classes.modalHeader, 'confirm-modal-header')}>
               <h2>{headerText}</h2>
               <CloseIcon
                  className={cx(classes.closeIcon, 'close-icon')}
                  onClick={onRequestClose}
               />
            </div>
            <div className={cx(classes.modalWrap, 'confirm-modal-wrap')}>
               <p>{text}</p>
               <div className={cx(classes.buttonsWrap, 'confirm-buttons-wrap')}>
                  <button type="button" className="cancel-button" onClick={onCancel}>
                     Cancel
                  </button>
                  <button
                     type="button"
                     className="confirm-button"
                     onClick={onConfirm}
                  >
                     Confirm
                  </button>
               </div>
            </div>
         </>
      </Modal>
   );
};

export default ConfirmationModal;
