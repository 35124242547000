import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { SERVICES_INITIAL_STATE, TRANSACTION_STATUS } from '../../utils/constants';
import {
   ServicesState,
   ServicesDispatchTypes,
   ServicesActionTypes,
} from './servicesTypes';

const servicesState: ServicesState = {
   ...SERVICES_INITIAL_STATE,
};
const servicesReducer: Reducer<ServicesState> = produce(
   (draft: Draft<ServicesState>, action: ServicesDispatchTypes) => {
      switch (action.type) {
         // Services
         case ServicesActionTypes.GET_SERVICES_START:
         case ServicesActionTypes.GET_SINGLE_SERVICE_START:
            draft.states.isLoading = true;
            break;
         case ServicesActionTypes.GET_SERVICES_SUCCESS:
            draft.states.isLoading = false;
            draft.states.isServicesReady = true;
            draft.all = action.payload;
            if (draft.displayedServices.services.length !== 0) return;
            draft.displayedServices.services = action.payload;
            break;
         case ServicesActionTypes.SEARCH_SERVICES:
            draft.search = action.payload;
            break;
         case ServicesActionTypes.CLEAR_SEARCH_SERVICES:
            draft.search = '';
            break;
         case ServicesActionTypes.CHANGE_SERVICE_CATEGORY:
            if (action.payload === 'all') {
               draft.displayedServices.services = draft.all;
               draft.displayedServices.selectedCategory = 'all';
               return;
            }
            draft.displayedServices.selectedCategory = action.payload;
            draft.displayedServices.services = draft.all.filter(
               (service) => service.categoryName === action.payload,
            );
            break;
         case ServicesActionTypes.GET_SINGLE_SERVICE_SUCCESS:
            draft.states.isLoading = false;
            draft.single = action.payload;
            break;
         case ServicesActionTypes.GET_SERVICES_ERROR:
         case ServicesActionTypes.GET_SINGLE_SERVICE_ERROR:
            draft.states.isLoading = false;
            break;
         // Active services
         case ServicesActionTypes.GET_ACTIVE_SERVICE_START:
            draft.states.isLoading = true;
            break;
         case ServicesActionTypes.GET_ACTIVE_SERVICE_SUCCESS:
            draft.states.isLoading = false;
            draft.active.activeServices.all = action.payload.servicesInfo;
            draft.active.activeServicesConsent = action.payload.contactConsent;
            break;
         case ServicesActionTypes.GET_ACTIVE_SERVICE_ERROR:
            draft.states.isLoading = false;
            draft.active.activeServices.errorMessage = action.payload;
            break;
         // Selected package
         case ServicesActionTypes.SELECT_PAYMENT_METHOD_FOR_PACKAGE:
            draft.states.selectedPaymentMethodId = action.payload;
            break;
         // VALIDATION
         // Input validation
         case ServicesActionTypes.VALIDATE_INPUT_START:
            draft.validation.input.isLoading = true;
            draft.validation.input.isInputValidated =
               draft.validation.input.isInputValidated || null;
            break;
         case ServicesActionTypes.VALIDATE_INPUT_SUCCESS:
            draft.validation.input.isLoading = false;
            draft.validation.input.isInputValidated = true;
            draft.validation.input.errorMessage = '';
            break;
         case ServicesActionTypes.VALIDATE_INPUT_ERROR:
            draft.validation.input.isLoading = false;
            draft.validation.input.isInputValidated = false;
            draft.validation.input.errorMessage = action.payload;
            break;
         // Payment method validation
         case ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_START:
            draft.validation.paymentMethodInput.isLoading = true;
            draft.validation.paymentMethodInput.isInputValidated =
               draft.validation.paymentMethodInput.isInputValidated || null;
            break;
         case ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_SUCCESS:
            draft.validation.paymentMethodInput.isLoading = false;
            draft.validation.paymentMethodInput.isInputValidated = true;
            draft.validation.paymentMethodInput.errorMessage = '';
            break;
         case ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_ERROR:
            draft.validation.paymentMethodInput.isLoading = false;
            draft.validation.paymentMethodInput.isInputValidated = false;
            draft.validation.paymentMethodInput.errorMessage = action.payload;
            break;
         // Phone valdiation
         case ServicesActionTypes.VALIDATE_PHONE_START:
            draft.validation.phone.isLoading = true;
            draft.validation.phone.isInputValidated =
               draft.validation.phone.isInputValidated || false;
            break;
         case ServicesActionTypes.VALIDATE_PHONE_SUCCESS:
            draft.validation.phone.isLoading = false;
            draft.validation.phone.isInputValidated = true;
            draft.validation.phone.errorMessage = '';
            break;
         case ServicesActionTypes.VALIDATE_PHONE_ERROR:
            draft.validation.phone.isLoading = false;
            draft.validation.phone.isInputValidated = false;
            draft.validation.phone.errorMessage = action.payload;
            break;
         case ServicesActionTypes.VALIDATE_FORM_SUBMIT_START:
            draft.validation.form.isLoading = true;
            break;
         case ServicesActionTypes.VALIDATE_FORM_SUBMIT_SUCCESS:
            draft.validation.form.isLoading = true;
            draft.validation.form.onSubmitValidated = true;
            break;
         case ServicesActionTypes.VALIDATE_FORM_SUBMIT_ERROR:
            draft.validation.form.isLoading = false;
            draft.validation.form.onSubmitValidated = false;
            draft.validation.form.errorMessage = action.payload;
            break;
         // Validation reset
         case ServicesActionTypes.VALIDATE_PHONE_RESET:
            draft.validation.phone.isLoading = false;
            draft.validation.phone.isInputValidated = null;
            draft.validation.phone.errorMessage = '';
            break;
         case ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_RESET:
            draft.validation.paymentMethodInput.isLoading = false;
            draft.validation.paymentMethodInput.isInputValidated = null;
            draft.validation.paymentMethodInput.errorMessage = '';
            break;
         case ServicesActionTypes.VALIDATION_RESET:
            draft.validation.form.isLoading = false;
            draft.validation.form.onSubmitValidated = false;
            draft.validation.phone.errorMessage = '';
            draft.validation.input.isLoading = false;
            draft.validation.input.isInputValidated = null;
            draft.validation.input.errorMessage = '';
            draft.validation.phone.isLoading = false;
            draft.validation.phone.isInputValidated = null;
            draft.validation.phone.errorMessage = '';
            draft.validation.paymentMethodInput.errorMessage = '';
            draft.validation.paymentMethodInput.isInputValidated = null;
            draft.validation.phone.errorMessage = '';
            draft.validation.error.message = '';
            break;
         case ServicesActionTypes.MODAL_ERRORS_RESET:
            draft.active.activeServices.errorMessage = '';
            break;
         case ServicesActionTypes.RESET_PIN_TIMER:
            draft.validation.pin.pinTimerActive = false;
            // draft.validation.pin.isPinSent = false;
            break;
         case ServicesActionTypes.VALIDATION_ERROR_MESSAGE:
            draft.validation.error.message = action.payload;
            break;
         case ServicesActionTypes.VALIDATION_ERROR_MESSAGE_RESET:
            draft.validation.error.message = '';
            draft.validation.form.errorMessage = '';
            break;
         case ServicesActionTypes.VALIDATION_ERROR_PAYMENT_METHOD_RESET:
            draft.validation.paymentMethodInput.errorMessage = '';
            draft.validation.paymentMethodInput.isInputValidated = null;
            break;
         // Activate service action
         case ServicesActionTypes.SUBMIT_FORM:
            draft.validation.form.isLoading = true;
            draft.validation.form.onSubmitValidated = true;
            break;
         // PIN ACTION
         case ServicesActionTypes.GET_PIN_START:
            draft.validation.pin.isPinLoading = true;
            break;
         case ServicesActionTypes.GET_PIN_SUCCESS:
            draft.validation.pin.isPinLoading = false;
            draft.validation.pin.isPinSent = true;
            draft.validation.pin.pinTimerActive = true;
            draft.active.activeServices.pinId = action.payload.pinId;
            draft.active.activeServices.phoneNo = action.phoneNo;
            draft.active.activeServices.email = action.email;
            break;
         case ServicesActionTypes.GET_PIN_ERROR:
            draft.validation.pin.isPinLoading = false;
            // draft.validation.pin.isPinSent = false;
            draft.active.activeServices.errorMessage = action.payload;
            draft.validation.pin.pinTimerActive = false;
            break;
         case ServicesActionTypes.RESET_PIN_VALIDATION:
            draft.validation.pin.isPinSent = false;
            break;
         case ServicesActionTypes.DEACTIVATE_CONSENT_START:
            draft.active.consentStatus.isConsentDisableLoading = true;
            break;
         case ServicesActionTypes.DEACTIVATE_CONSENT_SUCCESS:
            draft.active.consentStatus.isConsentDisableLoading = false;
            draft.active.consentStatus.isConsentDisabled = true;
            break;
         case ServicesActionTypes.DEACTIVATE_CONSENT_ERROR:
            draft.active.consentStatus.isConsentDisableLoading = false;
            break;
         case ServicesActionTypes.RESET_CONSENT:
            draft.active.consentStatus.isConsentDisabled = false;
            draft.active.consentStatus.isConsentDisableLoading = false;
            break;
         // Remove Active services states
         case ServicesActionTypes.REMOVE_ACTIVE_SERVICE_START:
            draft.states.isLoading = true;
            break;
         case ServicesActionTypes.REMOVE_ACTIVE_SERVICE_SUCCESS:
            draft.states.isLoading = false;
            draft.active.activeServices.all = action.payload.servicesInfo;
            draft.active.activeServicesConsent = action.payload.contactConsent;
            break;
         case ServicesActionTypes.REMOVE_ACTIVE_SERVICE_ERROR:
            draft.states.isLoading = false;
            break;
         case ServicesActionTypes.NEW_TRANSACTION_START:
            draft.transaction.transactionStatus = TRANSACTION_STATUS.PENDING;
            draft.transaction.isLoading = true;
            break;
         case ServicesActionTypes.NEW_TRANSACTION_SUCCESS:
            draft.transaction.isLoading =
               action.payload.status === TRANSACTION_STATUS.PENDING;
            draft.transaction.showTransactionStatus = true;
            draft.transaction.transactionStatus = action.payload.status;
            break;
         case ServicesActionTypes.NEW_TRANSACTION_ERROR:
            draft.transaction.isLoading = false;
            draft.transaction.transactionStatus = TRANSACTION_STATUS.FAILED;
            draft.transaction.showTransactionStatus = true;
            break;
         case ServicesActionTypes.NEW_TRANSACTION_RESET:
            draft.transaction.isLoading = false;
            draft.transaction.transactionStatus = null;
            draft.transaction.showTransactionStatus = false;
            break;
         default:
            return;
      }
      return draft;
   },
   servicesState,
);
export default servicesReducer;
