import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import SearchIcon from '../../../../assets/img/search-icon-red.svg';
import { selectAppLayout } from '../../../../store/selectors';
import Input from '../Input';
import { useCheckDomain } from '../../../../utils/hooks';
import { DOMAINS } from '../../../../utils/constants';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const SearchInput = ({ onChange, ...props }: SearchInputProps) => {
   const { searchLabel } = useSelector(selectAppLayout);

   const isSodexoDomain = useCheckDomain(DOMAINS.sodexo);
   return (
      <div className={cx('search-input-wrap')}>
         <Input
            wrapperClassName={cx('search-wrap')}
            className={cx('search')}
            icon={SearchIcon}
            labelText={isSodexoDomain ? null : searchLabel}
            name="search"
            type="text"
            value={props.value}
            onChange={onChange}
            placeholder={isSodexoDomain ? searchLabel : ''}
         />
      </div>
   );
};

export default SearchInput;
