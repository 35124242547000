/* eslint-disable jsx-a11y/label-has-associated-control */
import Select, { Props } from 'react-select';
import cx from 'classnames';
import classes from './Dropdown.module.scss';
import ErrorLabel from '../../../ui/errorLabel/ErrorLabel';

export interface DropDownProps extends Props {
   labelText: string;
   validationError?: boolean;
   validationText?: string;
   innerRef?: any;
}
const Dropdown = ({
   labelText,
   validationError,
   validationText,
   innerRef,
   ...rest
}: DropDownProps) => {
   return (
      <div className={classes.inputWrap}>
         <label className="input-label">{labelText || '#### No localization for param labelText ####'}</label>
         <Select
            ref={innerRef}
            className={cx({ [classes.error]: validationError })}
            {...rest}
         />
         {/* Validation message */}
         <ErrorLabel
            isVisible={!!validationError}
            text={validationText}
            size="small"
         />
      </div>
   );
};

export default Dropdown;
