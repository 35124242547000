import axios from 'axios';
import { Cookies } from 'react-cookie';
import { getDomainFromUrl } from '../utils/helpers';
import { AnalyticHeaderProps } from '../types/globals';
import { BROWSER_STORAGE_KEYS } from '../utils/constants';
import keycloak from '../Keycloak';

const axiosBaseURL =
   process.env.REACT_APP_ENV === 'production'
      ? getDomainFromUrl()
      : // : 'https://stage.centili.com';
        'https://api.centili.com';

export const axiosInstance = axios.create({
   baseURL: axiosBaseURL,
});
export const axiosAnalyticsInstance = axios.create({
   baseURL: axiosBaseURL,
});

axiosInstance.interceptors.request.use(
   async (config) => {
      if (keycloak.token) {
         const isTokenExpired = keycloak.isTokenExpired();
         if (isTokenExpired) {
            try {
               await keycloak.updateToken(30);
               config.headers = {
                  Authorization: `Bearer ${keycloak.token}`,
                  ...config.headers,
               };
            } catch (error) {
               console.error('Failed to refresh token', error);
            }
         } else {
            config.headers = {
               Authorization: `Bearer ${keycloak.token}`,
               ...config.headers,
            };
         }
      }

      return config;
   },
   (error) => Promise.reject(error),
);

axiosAnalyticsInstance.interceptors.request.use(
   async (config) => {
      const analyticHeaders: AnalyticHeaderProps = {
         'User-Id': JSON.parse(
            localStorage.getItem(BROWSER_STORAGE_KEYS.localStorage.userId) ||
               '###missing_local_storage_id###',
         ),
         'Session-Id':
            new Cookies().get(BROWSER_STORAGE_KEYS.cookies.sessionId) ||
            '###missing_cookie_session_id###',
      };

      if (analyticHeaders['Session-Id'] && analyticHeaders['User-Id']) {
         (config.headers as AnalyticHeaderProps) = analyticHeaders;
      }

      if (keycloak.token) {
         const isTokenExpired = keycloak.isTokenExpired();
         if (isTokenExpired) {
            try {
               await keycloak.updateToken(30);
               config.headers = {
                  Authorization: `Bearer ${keycloak.token}`,
                  ...config.headers,
               };
            } catch (error) {
               console.error('Failed to refresh token', error);
            }
         } else {
            config.headers = {
               Authorization: `Bearer ${keycloak.token}`,
               ...config.headers,
            };
         }
      }

      return config;
   },
   (error) => Promise.reject(error),
);
