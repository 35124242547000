import React, { Suspense } from 'react';
import { useImage } from 'react-image';
import Loader from 'react-loader-spinner';
import VisibilitySensor from 'react-visibility-sensor';
import { motion, AnimatePresence } from 'framer-motion';
import FallbackImage from '../../../assets/img/fallback-img.jpeg';
import LoaderGif from '../../../assets/img/img-loader.gif';

interface ImageProps {
   image: string;
   className?: string;
   onClick?: () => void;
}

const MyImageComponent = ({
   image,
   className,
   onClick = () => null,
}: ImageProps) => {
   const { src, isLoading } = useImage({
      srcList: [image, FallbackImage],
      useSuspense: false,
   });

   return (
      <React.StrictMode>
         <VisibilitySensor>
            <AnimatePresence>
               {isLoading ? (
                  <motion.div
                     exit={{ opacity: 0 }}
                     style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                     }}
                  >
                     <img
                        style={{ objectFit: 'none', transform: 'scale(0.35)' }}
                        src={LoaderGif}
                        alt=""
                     />
                  </motion.div>
               ) : (
                  <motion.img
                     onClick={onClick}
                     className={className}
                     alt=""
                     src={src}
                  />
               )}
            </AnimatePresence>
         </VisibilitySensor>
      </React.StrictMode>
   );
};

const Image = ({ image, className, onClick = () => null }: ImageProps) => {
   return (
      <Suspense
         fallback={<Loader type="Circles" color="orange" height={50} width={40} />}
      >
         <MyImageComponent image={image} className={className} onClick={onClick} />
      </Suspense>
   );
};

export default Image;
