import React from 'react';
import cx from 'classnames';
import classes from './StepProgress.module.scss';

export interface StepProgressProps {
   stepCount?: string;
   completed?: boolean;
}

const StepProgress = ({ stepCount, completed }: StepProgressProps) => {
   return (
      <div
         className={cx(
            'step-progress',
            completed && 'completed',
            classes.stepProgress,
            { [classes.completed]: completed },
         )}
      >
         <span>
            {completed ? <span className={classes.tickIcon}>✔</span> : stepCount}
         </span>
      </div>
   );
};

export default StepProgress;
