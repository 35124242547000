import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import store from './store/rootStore';
import './assets/styles/main.scss';
import history from './utils/history';
import keycloak from './Keycloak';
import { getCookieValue } from './utils/helpers';
import { BROWSER_STORAGE_KEYS } from './utils/constants';

const keycloakInitOptions = {
   onLoad: getCookieValue(BROWSER_STORAGE_KEYS.cookies.loginAttempt)
      ? 'check-sso'
      : undefined,
   checkLoginIframe: false,
};

const RootComponent = (
   <React.StrictMode>
      <ReactKeycloakProvider initOptions={keycloakInitOptions} authClient={keycloak}>
         <Provider store={store}>
            <Router history={history}>
               <CookiesProvider>
                  <App />
               </CookiesProvider>
            </Router>
         </Provider>
      </ReactKeycloakProvider>
   </React.StrictMode>
);

ReactDOM.render(RootComponent, document.getElementById('root'));
reportWebVitals();
