import React from 'react';
import cx from 'classnames';
import Button from '../../ui/button/Button';
import classes from './Slide.module.scss';
import PLACEHOLDER from '../../../assets/img/fallback-img.jpeg';

interface SlideProp {
   img: string;
   title: string;
   description: string;
}

const Slide = ({ img, title, description }: SlideProp) => {
   return (
      <>
         <div className={classes.slide}>
            <img
               className={classes.slideImage}
               placeholder={PLACEHOLDER}
               src={img}
               alt=""
            />
            <div className={cx('slide-text-wrap', classes.slideTextWrap)}>
               <h1 className={cx('slide-title', classes.slideTitle)}>{title}</h1>
               <p className={cx('slide-description', classes.slideDescription)}>
                  {description}
               </p>
               <Button title="Read More" />
            </div>
         </div>
      </>
   );
};

export default Slide;
