import React, { useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import Button from '../../ui/button/Button';
import ActiveServicesModal from '../../modal/activeServicesModal/ActiveServicesModal';
import { selectAppLayout } from '../../../store/selectors';

const ActiveServicesSectionPersonal = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const {
      buttonActiveServicesLabel,
      activeServiceModalTitle,
      activeServiceModalDescription,
   } = useSelector(selectAppLayout, shallowEqual);

   return (
      <section className="active-service-section-personal">
         <div className="active-service-description-personal">
            <h1 className={cx('main-title')}>
               {activeServiceModalTitle ||
                  '#### No localization for param activeServiceModalTitle ####'}
            </h1>
            <p>
               {activeServiceModalDescription ||
                  '#### No localization for param activeServiceModalDescription ####'}
            </p>
            <div className="button-container">
               <Button
                  title={
                     buttonActiveServicesLabel ||
                     '#### No localization for param buttonActiveServicesLabel ####'
                  }
                  className="active-service-button active-service-button-personal"
                  // onClick={() => history.push(`/service/${serviceKey}`)}
                  onClick={() => setIsModalOpen(true)}
               />
            </div>
         </div>

         <ActiveServicesModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
         />
      </section>
   );
};

export default ActiveServicesSectionPersonal;
