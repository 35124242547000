import { combineReducers } from 'redux';
import appReducer from './app/appReducer';
import serviceReducer from './services/servicesReducer';
import userReducer from './user/userReducer';

const rootReducer = combineReducers({
   app: appReducer,
   services: serviceReducer,
   user: userReducer,
});

export default rootReducer;
