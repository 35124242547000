export interface SideMenuProps {
   children: React.ReactNode;
   isOpen: boolean;
   className?: string;
   onOverlayClick?: (
      event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
   ) => void;
}

const SideMenu = ({
   isOpen,
   children,
   onOverlayClick,
   className,
}: SideMenuProps) => {
   return (
      <>
         <div className={`sidebar ${isOpen ? 'active' : ''} ${className || ''}`}>
            <div>{children}</div>
         </div>
         <div
            aria-hidden="true"
            role="region"
            onClick={onOverlayClick}
            className={`sidebar-overlay ${isOpen ? 'active' : ''}`}
         />
      </>
   );
};

export default SideMenu;
