import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Homepage from '../pages/homepage/Homepage';
import ServicePage from '../pages/servicePage/ServicePage';
import { Routes } from '../utils/constants';
import ScrollToTop from '../components/ui/scrollToTop/ScrollToTop';
import ErrorPage from '../pages/errorPage/ErrorPage';
import ActiveServices from '../pages/servicePage/activeServicesPage/ActiveServicesPage';
import { SepPage } from '../pages/sepPage/SepPage';
import ServiceDetailPage from '../pages/servicePage/serviceDetailsPage/ServiceDetailsPage';
import CategoryBanner from '../pages/categoryBannerPage/CategoryBanner';
import UserProfilePage from '../pages/userProfilePage/UserProfilePage';

// const Homepage = React.lazy(() => import('../containers/Homepage'));
// const ServicePage = React.lazy(() => import('../containers/ServicePage'));

const LandingRoutes = () => {
   return (
      <Suspense
         fallback={<Loader type="Bars" color="blue" height={150} width={150} />}
      >
         <ScrollToTop />
         <Switch>
            <Route exact path={Routes.SERVICE_ROUTE} component={ServicePage} />
            <Route exact path={Routes.SEP_ROUTE} component={SepPage} />
            <Route
               exact
               path={Routes.USER_PROFILE_ROUTE}
               component={UserProfilePage}
            />
            <Route
               exact
               path={Routes.ACTIVE_SERVICES_ROUTE}
               component={ActiveServices}
            />
            <Route
               exact
               path={Routes.SERVICE_CATEGORY_BANNER_ROUTE}
               component={CategoryBanner}
            />
            <Route
               exact
               path={Routes.SERVICE_DETAILS_ROUTE}
               component={ServiceDetailPage}
            />
            <Route path={Routes.ERROR_ROUTE} component={ErrorPage} />
            <Route exact path={Routes.HOME_ROUTE} component={Homepage} />
            {/* <Route>
                  <Redirect to={Routes.HOME_ROUTE} />
               </Route> */}
         </Switch>
      </Suspense>
   );
};

export default LandingRoutes;
