import React, { forwardRef } from 'react';
import Loader from 'react-loader-spinner';
import cx from 'classnames';
import classes from './Button.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/img/icon-arrow.svg';
import { ReactComponent as TopUpIcon } from '../../../assets/img/topUpIcon.svg';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   title: string;
   className?: string;
   onClick?: (e?: any) => void;
   loading?: boolean;
   disabled?: boolean;
   enableArrow?: boolean;
   enableTopUp?: any;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
   (
      {
         title,
         className,
         onClick,
         loading = false,
         disabled = false,
         enableArrow = false,
         enableTopUp = false,
         ...props
      },
      ref,
   ) => {
      return (
         <button
            className={cx('button', { [classes.disabled]: disabled }, className)}
            {...props}
            ref={ref}
            type="button"
            onClick={onClick}
            disabled={disabled}
         >
            {enableTopUp && <TopUpIcon className={classes.topUpIcon} />}
            {loading ? (
               <Loader type="Oval" color="white" height={30} width={30} />
            ) : (
               title
            )}

            {enableArrow && <ArrowIcon className={classes.arrowIcon} />}
         </button>
      );
   },
);
Button.displayName = 'Button';
export default Button;
