import React from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ReactComponent as ArrowLeft } from '../../../assets/img/icon-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/img/icon-right.svg';
import { selectAppLayout } from '../../../store/selectors';

type IntroCarouselProps = {
   slides: string[];
   slideSpeed: number | null;
};

const IntroCarousel = ({ slides, slideSpeed }: IntroCarouselProps) => {
   const { domain } = useSelector(selectAppLayout);
   const swiperDelay =
      domain === 'almadar-store.ly' || domain === 'stage-almadar.centili.com'
         ? 60000
         : 3000;

   return (
      <section className="intro-carousel-section">
         <div>
            <ArrowLeft className="image-prev" />
            <Swiper
               autoplay={{
                  delay: swiperDelay,
               }}
               loop
               speed={slideSpeed || 3000}
               style={{
                  direction: 'ltr',
               }}
               pagination={{
                  clickable: true,
               }}
               navigation={{
                  nextEl: '.image-next',
                  prevEl: '.image-prev',
               }}
               slidesPerView={1}
               spaceBetween={20}
               centeredSlides
            >
               <>
                  {slides.map((slide, i) => (
                     <>
                        <SwiperSlide key={i}>
                           <div className="carousel-container">
                              <div className="image">
                                 <img src={slide} alt="" />
                              </div>
                           </div>
                        </SwiperSlide>
                     </>
                  ))}
               </>
            </Swiper>
            <ArrowRight className="image-next" />
         </div>
      </section>
   );
};

export default IntroCarousel;
