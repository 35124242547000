import { useSelector } from 'react-redux';
import classes from './ErrorPage.module.scss';
import { Routes } from '../../utils/constants';
import { useErrorStatus } from '../../utils/hooks';
import { selectAuthState, selectAppLayout } from '../../store/selectors';

const ErrorPage = () => {
   const { errorIcon, errorTitle } = useErrorStatus();
   const { data } = useSelector(selectAuthState);
   const { mandatoryData } = useSelector(selectAppLayout);
   return (
      <div className={classes.errorPage}>
         <img src={errorIcon} alt="" />
         <h1 className={classes.errorTitle}>{errorTitle}</h1>
         {(data || !mandatoryData) && (
            <p className={classes.errorDescription}>
               Go back to our{' '}
               <a href={`${Routes.HOME_ROUTE}?data=${data}`}>homepage</a>
            </p>
         )}
      </div>
   );
};

export default ErrorPage;
