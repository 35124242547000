import { Dispatch } from 'redux';
import { AppActionTypes, AppDispatchTypes } from './appTypes';
import {
   getInitialLayoutConfig,
   sendAnalytics,
   sendChatbotClickedAnalytics,
   sendRegisterAnalytics,
   sendSignInAnalytics,
   sendViewProfileAnalytics,
} from '../../api/service';
import { insertExternalCSS } from '../../utils/helpers';
import {
   GetInitialLayoutProps,
   AnalyticsProps,
   UserAnalyticsProps,
} from '../../types/globals';
import history from '../../utils/history';
import { BROWSER_STORAGE_KEYS } from '../../utils/constants';

export const toggleDarkMode = () => (dispatch: Dispatch<AppDispatchTypes>) => {
   dispatch({
      type: AppActionTypes.DARK_MODE,
   });
};

export const setDarkMode =
   (isDarkModePreferred: boolean) => (dispatch: Dispatch<AppDispatchTypes>) => {
      dispatch({
         type: AppActionTypes.SET_DARK_MODE,
         payload: isDarkModePreferred,
      });
   };

export const setAppIsReadyAction = () => (dispatch: Dispatch<AppDispatchTypes>) => {
   dispatch({
      type: AppActionTypes.APP_IS_READY,
   });
};
export const resetStateAction = () => (dispatch: Dispatch<AppDispatchTypes>) => {
   dispatch({
      type: AppActionTypes.RESET_APP_STATE,
   });
};
export const setAppErrorAction = () => (dispatch: Dispatch<AppDispatchTypes>) => {
   dispatch({
      type: AppActionTypes.APP_ERROR,
   });
};

export const getDefaultLanguage = (query: GetInitialLayoutProps) => () => {
   return new Promise((resolve) => {
      getInitialLayoutConfig(query)
         .then((result) => {
            window?.localStorage.setItem(
               BROWSER_STORAGE_KEYS.localStorage.defaultLanguage,
               result.data.defaultLanguage,
            );
            resolve(result);
         })
         .catch((error) => {
            console.error(error);
         });
   });
};

export const getInitialLayoutConfigAction =
   (query: GetInitialLayoutProps) =>
   async (dispatch: Dispatch<AppDispatchTypes>) => {
      dispatch({
         type: AppActionTypes.GET_INITIAL_LAYOUT_START,
      });
      try {
         const queryWithSavedLanguage = {
            ...query,
            language:
               window?.localStorage
                  .getItem(BROWSER_STORAGE_KEYS.localStorage.selectedLanguage)
                  ?.replaceAll('"', '') ||
               window?.localStorage.getItem(
                  BROWSER_STORAGE_KEYS.localStorage.defaultLanguage,
               ) ||
               undefined,
         };

         const result = await getInitialLayoutConfig(queryWithSavedLanguage);
         const externalCSSLink = result.data.css;
         insertExternalCSS(externalCSSLink);

         dispatch({
            type: AppActionTypes.GET_INITIAL_LAYOUT_SUCCESS,
            payload: {
               layout: result.data,
               queryParams: query,
            },
         });
      } catch (error: any) {
         const errorStatus = error?.response?.status || '401';
         history.push(`/error/${errorStatus}`);
         dispatch({
            type: AppActionTypes.GET_INITIAL_LAYOUT_ERROR,
         });
      }
   };
export const sendAnalyticActions =
   (analyticProps: AnalyticsProps) =>
   async (dispatch: Dispatch<AppDispatchTypes>) => {
      try {
         const { category, domain } = analyticProps;
         await sendAnalytics(category, domain);

         dispatch({
            type: AppActionTypes.SEND_ANALYTICS,
         });
      } catch (error: any) {
         console.error(error);
      }
   };

export const sendRegisterAnalyticActions =
   (domain: string) => async (dispatch: Dispatch<AppDispatchTypes>) => {
      try {
         await sendRegisterAnalytics(domain);

         dispatch({
            type: AppActionTypes.SEND_REGISTER_ANALYTICS,
         });
      } catch (error: any) {
         console.error(error);
      }
   };

export const sendSignInAnalyticActions =
   (userAnalyticsProps: UserAnalyticsProps) =>
   async (dispatch: Dispatch<AppDispatchTypes>) => {
      try {
         const { domain, keycloakId } = userAnalyticsProps;
         await sendSignInAnalytics(domain, keycloakId);

         dispatch({
            type: AppActionTypes.SEND_SIGN_IN_ANALYTICS,
         });
      } catch (error: any) {
         console.error(error);
      }
   };

export const sendViewProfileAnalyticActions =
   (userAnalyticsProps: UserAnalyticsProps) =>
   async (dispatch: Dispatch<AppDispatchTypes>) => {
      try {
         const { domain, keycloakId } = userAnalyticsProps;
         await sendViewProfileAnalytics(domain, keycloakId);

         dispatch({
            type: AppActionTypes.SEND_VIEW_PROFILE_ANALYTICS,
         });
      } catch (error: any) {
         console.error(error);
      }
   };

export const sendChatbotClickedAnalyticActions =
   (userAnalyticsProps: UserAnalyticsProps) =>
   async (dispatch: Dispatch<AppDispatchTypes>) => {
      try {
         const { domain, keycloakId } = userAnalyticsProps;
         await sendChatbotClickedAnalytics(domain, keycloakId);

         dispatch({
            type: AppActionTypes.SEND_CHATBOT_CLICKED_ANALYTICS,
         });
      } catch (error: any) {
         console.error(error);
      }
   };
