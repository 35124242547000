import React from 'react';
import { motion } from 'framer-motion';
import cx from 'classnames';

interface AnimationProps {
   children: React.ReactNode;
   className?: string;
   animationType: 'loader' | 'services' | 'component' | 'page' | 'modal';
}

const easing = [0.6, -0.05, 0.01, 0.99];

// const stagger = {
//    animate: {
//       transition: {
//          staggerChildren: 0.05,
//       },
//    },
// };

const ANIMATION_PRESETS = {
   loader: {
      initial: {
         opacity: 1,
      },
      animate: {
         opacity: 1,
      },
      exit: {
         opacity: 0,
         transition: {
            duration: 1.6,
            ease: easing,
         },
      },
   },
   component: {
      initial: {
         opacity: 0,
      },
      animate: {
         opacity: 1,
      },
   },

   services: {
      initial: {
         opacity: 0.7,
         y: 70,
      },
      animate: {
         opacity: 1,
         y: 0,
         transition: { type: 'spring', duration: 0.6, bounce: 0.35 },
      },
   },

   modal: {
      initial: {
         y: 20,
         opacity: 0,
         // transition: { duration: 0.2, ease: easing },
      },
      animate: {
         y: 0,
         opacity: 1,
         // transition: {
         //    duration: 0.2,
         //    ease: easing,
         // },
      },
   },
   page: {
      initial: {
         y: 60,
         opacity: 0,
         transition: { duration: 0.6, ease: easing },
      },
      animate: {
         y: 0,
         opacity: 1,
         transition: {
            duration: 0.6,
            ease: easing,
         },
      },
   },
};

const Animation = ({ children, className, animationType }: AnimationProps) => {
   return (
      <motion.div
         variants={ANIMATION_PRESETS[animationType]}
         initial={ANIMATION_PRESETS[animationType].initial}
         animate={ANIMATION_PRESETS[animationType].animate}
         // exit={
         //    animationType === 'loader' ? ANIMATION_PRESETS.loader.exit : undefined
         // }
         // exit={exit || ''}
         className={cx(className)}
      >
         {children}
      </motion.div>
   );
};

export default Animation;
