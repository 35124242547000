import cx from 'classnames';
import React, { useState } from 'react';
import { ReactComponent as HoverImgIconMts } from '../../../assets/img/icon-hover.svg';
import { ReactComponent as HoverImgIconA1 } from '../../../assets/img/icon-help2.svg';
import classes from './HoverIcon.module.scss';

interface HoverIconProps {
   text: string | React.ReactNode;
   img: string;
   iconType: 'inside' | 'outside';
   children?: React.ReactElement;
}

const HoverIcon = ({ text, img, iconType, children }: HoverIconProps) => {
   const [showImage, setShowImage] = useState(false);

   const HoverIcon = iconType === 'inside' ? HoverImgIconMts : HoverImgIconA1;
   return (
      <div
         onMouseEnter={() => setShowImage(true)}
         onMouseLeave={() => setShowImage(false)}
         className={classes.hoverIconWrap}
      >
         <span>
            <HoverIcon />
         </span>

         <div
            className={cx(
               showImage ? classes.visible : classes.hidden,
               classes.hoverSection,
               'hover-section',
            )}
         >
            <span className={cx('description-id-text')}>{text}</span>
            <img src={img} alt="" />
         </div>
         {children}
      </div>
   );
};

export default HoverIcon;
