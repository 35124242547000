import React, { useState } from 'react';
import { useLocation } from 'react-router';
import Preloader from '../../components/ui/loaders/preloader/Preloader';
import { LocationServiceParams } from '../../types/globals';
import classes from './SepPage.module.scss';
import { BROWSER_STORAGE_KEYS } from '../../utils/constants';

export const SepPage = () => {
   const { state } = useLocation<LocationServiceParams>();
   const [isIframeLoaded, setIsIframeLoader] = useState(false);
   const iframeLocalSepLink =
      window?.localStorage.getItem(
         BROWSER_STORAGE_KEYS.localStorage.sepRedirectionLink,
      ) || '';

   return (
      <div>
         {!isIframeLoaded && <Preloader />}
         <iframe
            onLoad={() => {
               setIsIframeLoader(true);
            }}
            className={classes.sepWrap}
            title="sep"
            id="sep"
            src={state?.iframeLink || iframeLocalSepLink}
         />
      </div>
   );
};
