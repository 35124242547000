import cx from 'classnames';
import classes from './SubHeader.module.scss';

export interface subHeaderProps {
   text: string | number;
   icon?: string;
}

const SubHeader = ({ text, icon }: subHeaderProps) => {
   return (
      <div className={cx(classes.subHeaderContainer, 'sub-header')}>
         <div className={classes.content}>
            {icon && (
               <span className={classes.icon}>
                  <img alt="icon" src={icon} />
               </span>
            )}
            <span className={classes.text}>{text}</span>
         </div>
      </div>
   );
};

export default SubHeader;
