/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router';
// import queryString from 'query-string';
import { useDebouncedCallback } from 'use-debounce';
import { useCookies } from 'react-cookie';
import { useKeycloak } from '@react-keycloak/web';
import Modal, { Props } from 'react-modal';
import { useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { SelectedCardProps } from '../../../components/form/pricingCard/PricingCard';
import classes from './ServiceDetailsModal.module.scss';
import Col from '../../../components/ui/layouts/col/Col';
import Button from '../../../components/ui/button/Button';
import BalanceIcon from '../../../assets/img/coin-icon.svg';

import {
   EmailValues,
   // RedirectToSepUrlProps,
   // RouteServiceParams,
   DynamicObjValuesProp,
   // SepAnalytics,
} from '../../../types/globals';
// import { redirectToSepPageUrl } from '../../../api/service';
import {
   getSingleServiceAction,
   validateInputAction,
   // validateAndSubmitFormAction,
   resetValidationAction,
   resetValidationErrorMessageAction,
   validatePaymentMethodInputAction,
   resetPaymentMethodValidation,
   selectPaymentMethodForPackagesAction,
   createWalletTransactionAction,
   resetNewTransaction,
} from '../../../store/services/servicesAction';
import {
   selectServicesState,
   selectAppLayout,
   selectSingleServiceState,
   selectObjectFromParams,
   selectValidationState,
   selectPaymentObjectFromParams,
   selectFormLoader,
   selectUserBalance,
   selectTransaction,
} from '../../../store/selectors';
import {
   ifFirstColValidated,
   hasInputsPopulated,
   // getDomainFromUrl,
   // getDataFromUrl,
   findSelectedPaymentMethodParams,
} from '../../../utils/helpers';
import PricingFormSection from '../../../components/services/singleService/pricingFormSection/PricingFormSection';
import EmailSection from '../../../components/services/singleService/emailFormSection/emailSection/EmailSection';
import DynamicInputSection from '../../../components/services/singleService/emailFormSection/dynamicInputSection/DynamicInputSection';
import ErrorLabel from '../../../components/ui/errorLabel/ErrorLabel';
import PaymentMethodsFormSection, {
   PaymentInputErrorLabels,
} from '../../../components/services/singleService/paymentMethodsFormSection/PaymentMethodsFormSection';

import {
   PaymentMethod,
   PaymentMethodParam,
} from '../../../store/services/servicesTypes';
import { useSingleServiceQuery, useLocalStorage } from '../../../utils/hooks';
import {
   BROWSER_STORAGE_KEYS,
   CATEGORY_QUERY_KEY,
   MODAL_QUERY_KEY,
   SESSION_ID,
   TRANSACTION_STATUS,
} from '../../../utils/constants';
import ServiceDescriptionText from '../../../components/services/serviceDescription/ServiceDescriptionText';
import SuccessModalIcon from '../../../assets/img/success-modal-icon.png';
import FailedModalIcon from '../../../assets/img/failed-modal-icon.png';

type Cookie = Record<typeof SESSION_ID, any>;
type ConsentsState = {
   paymentConsent: boolean;
   contactConsent: boolean;
};
const ServiceDetailsModal = (modalProps: Props) => {
   const [cookie] = useCookies([BROWSER_STORAGE_KEYS.cookies.sessionId]);
   const [localStorageValue] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.userId,
      '',
   );

   const { keycloak } = useKeycloak();
   const { amount: userBalance } = useSelector(selectUserBalance);

   const {
      transactionStatus,
      isLoading: isTransactionLoading,
      showTransactionStatus,
   } = useSelector(selectTransaction);

   const key = new URLSearchParams(useLocation().search).get(MODAL_QUERY_KEY);
   const category = new URLSearchParams(useLocation().search).get(
      CATEGORY_QUERY_KEY,
   );
   const dispatch = useDispatch();
   const history = useHistory();
   const serviceQueryParams = useSingleServiceQuery(key || '', category || '');
   const ref = useRef<HTMLElement | null>(null);

   // APP STATE
   const paramsObjectValues = useSelector(selectObjectFromParams);
   const paymentParamsObjectValues = useSelector(selectPaymentObjectFromParams);
   const formSubmitted = useSelector(selectFormLoader);

   const {
      code,
      defaultLanguage,
      buttonActivateLabel,
      // mandatoryData,
      activeMomentsApi,
      momentsApiKey,
      paymentConsent,
      contactConsentDefaultValue,
      paymentConsentDefaultValue,
      importMerchantDetailsHeader,
      usersLogin,
      virtualCurrencyCode,
      topUpUrl,
      enableTopUp,
      enableTopUpLabel,
      buttonSignInToContinueLabel,
      disableBuyButton,
      successfulPurchaseMessage,
      failedPurchaseMessage,
      backPurchaseButtonMessage,
      userBalanceAfterPurchaseLabel,
   } = useSelector(selectAppLayout, shallowEqual);
   const { isLoading } = useSelector(selectServicesState, shallowEqual);
   const { input, phone, form, paymentMethodInput } = useSelector(
      selectValidationState,
      shallowEqual,
   );
   const {
      image,
      name,
      description,
      packages,
      validators,
      serviceKey,
      emailLabel,
      // activateURL,
      disabled,
   } = useSelector(selectSingleServiceState, shallowEqual);

   const [, setLoginAttempt] = useCookies([
      BROWSER_STORAGE_KEYS.cookies.loginAttempt,
   ]);
   // LOCAL STATE
   const [consents] = useState<ConsentsState>({
      contactConsent: !!contactConsentDefaultValue,
      paymentConsent: !!paymentConsentDefaultValue,
   });
   const [isUserTyping, setIsUserTyping] = useState(false);
   const [, setHasSelectedFromDropdown] = useState(false);
   // Dynamic fields
   const [dynamicVal, setDynamicVal] = useState<DynamicObjValuesProp>({});
   // Input values
   const [pricingValue, setPricingValue] = useState<SelectedCardProps | null>(null);
   const [emailValue, setEmailValue] = useState<EmailValues>({
      selectedEmail: null,
      // newEmail: keycloak.authenticated ? keycloak.tokenParsed?.email : '',
      newEmail: '',
   });

   // Validation
   const [isEmailValidated, setIsEmailValidated] = useState<boolean | null>(null);
   const [errorMessages, setErrorMessages] = useState<DynamicObjValuesProp>({});
   const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
      PaymentMethod | Record<string, any>
   >({});

   const isUserNeededAndNotLogged = usersLogin && !keycloak.authenticated;

   useEffect(() => {
      if (key === serviceKey && disabled) {
         history.push('/');
      }
   }, [disabled, serviceKey]);

   // EFFECTS
   useEffect(() => {
      if (keycloak.authenticated)
         setLoginAttempt(BROWSER_STORAGE_KEYS.cookies.loginAttempt, true);
      setEmailValue({ ...emailValue, newEmail: keycloak.tokenParsed?.email });

      dispatch(getSingleServiceAction(serviceQueryParams));
      dispatch(resetValidationErrorMessageAction());
      if (momentsApiKey && activeMomentsApi) {
         (window as any)?.pe?.track('serviceopen', { servicekey: key });
      }

      return () => {
         dispatch(resetValidationAction());
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [keycloak.authenticated, key, modalProps.isOpen]);

   useEffect(() => {
      /*
         Loops through selected payment method(default payment method is set in PaymentMethodsFormSection component).
         Main selected package has payment methods array with all the inputs, and inputs name are same as our local obj key.
         It iterate through that array, and find the inputs name that are selected, and add them to the local DYNAMIC STATE.
         In that way, it won't send all the payment methods input to error validation, it will only have state with inputs from selected payment method.
      */
      if (selectedPaymentMethod && selectedPaymentMethod?.paymentMethodParams) {
         selectedPaymentMethod.paymentMethodParams.forEach(
            (method: PaymentMethodParam) => {
               if (
                  paymentParamsObjectValues &&
                  method.name in paymentParamsObjectValues
               ) {
                  Object.keys(paymentParamsObjectValues)?.forEach((el: any) => {
                     if (el in dynamicVal) {
                        delete dynamicVal[el];
                     }
                  });

                  setDynamicVal(() => {
                     return {
                        ...paramsObjectValues,
                        [method.name]: paymentParamsObjectValues[method.name],
                     };
                  });
               }
               return null;
            },
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [paramsObjectValues, paymentParamsObjectValues, selectedPaymentMethod, key]);

   useEffect(() => {
      // Allow to click on activate when the input of payment method is not present - blank payment method
      if (selectedPaymentMethod.paymentMethodParams === null) {
         setDynamicVal({});
      }

      // Keep and update price for selected pricing option when changing payment method.
      if (!pricingValue) return;

      const selectedPackage = packages
         .filter((packageId) => packageId.uniqueReference === pricingValue?.id)
         .reduce((_, packages) => {
            const selectedPayment = packages.paymentMethods.find(
               (paymentMethodPrice) =>
                  paymentMethodPrice.id === selectedPaymentMethod?.id,
            );

            return {
               ...pricingValue,
               price: selectedPayment?.price,
               merchantReference: selectedPayment?.merchantReference,
               voucherPackageReference: selectedPayment?.reference,
               currencyCode: selectedPayment?.currencyCode,
               merchantToken: selectedPayment?.merchantToken,
               paymentPackageId: selectedPayment?.id,
            };
         }, {}) as SelectedCardProps;

      // Deselect already selected price package on payment method change, if that payment method doesn't contain price package
      if (!selectedPackage.price) {
         setPricingValue(null);
         return;
      }
      setPricingValue(selectedPackage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedPaymentMethod]);

   // LOGIC
   const resetErrorMessagesOnFocusHandler = () => {
      dispatch(resetValidationErrorMessageAction());
      dispatch(resetNewTransaction());
      const errors = {
         email: '',
         packages: '',
      };
      setErrorMessages(errors);
   };

   const debouncedRegexValidation = useDebouncedCallback(
      (paramName, regex?: string, prefix?: string | undefined) => {
         if (!regex) return;

         const valueWithPrefix = prefix + dynamicVal[paramName];
         const inputValue = prefix ? valueWithPrefix : dynamicVal[paramName];

         const isRegexValid = new RegExp(regex).test(inputValue);
         if (!isRegexValid) {
            const selectedPaymentMethod = findSelectedPaymentMethodParams(paramName);
            setErrorMessages({
               ...errorMessages,
               [`dynamic${paramName}`]:
                  selectedPaymentMethod?.warnInvalidInputFormat ??
                  '### REGEX NOT PASSED - PAYMENT METHODS REGEX LABEL MISSING###',
            });
         } else {
            /*
               If regex validation, passed, return empty value prop, with key inside
               It's important for overriding errors obj in Service Page(if we return just a {}, it wont update key in obj when destructing)
             */
            setErrorMessages({
               ...errorMessages,
               [`dynamic${paramName}`]: '',
            });
         }
         setIsUserTyping(false);
      },

      1000,
   );

   const debouncedValidationInputHandler = useDebouncedCallback(
      (paramName: string) => {
         if (!validators) return;

         validators.some((validationParams) => {
            if (
               validationParams?.params.length === 1 &&
               validationParams?.params[0] === paramName
            ) {
               const validationUrl = `${validationParams.url}?language=${defaultLanguage}&storefrontCode=${code}`;
               dispatch(
                  validateInputAction(validationUrl, {
                     [paramName]: dynamicVal[paramName],
                  }),
               );
            }

            return null;
         });
         setIsUserTyping(false);
      },
      1000,
   );

   // PAYMENT METHOD VALIDATION
   const debouncedValidationPaymentInputHandler = useDebouncedCallback(
      (
         paramName: string,
         validationUrl: string,
         errorLabels: PaymentInputErrorLabels,
         prefix?: string | undefined,
      ) => {
         // break if validation URL is empty
         if (!validationUrl) return;

         const valueWithPrefix = prefix + dynamicVal[paramName];
         const inputValue = prefix ? valueWithPrefix : dynamicVal[paramName];
         const validationUrlWithQuery = `${validationUrl}?${paramName}=${inputValue}`;
         dispatch(
            validatePaymentMethodInputAction(validationUrlWithQuery, errorLabels),
         );
         setIsUserTyping(false);
      },
      1000,
   );

   // Queries
   // const emailSepQueryParams = () => {
   //    return (selectedPaymentMethod as PaymentMethod).emailSepParams?.reduce(
   //       (acc: Record<string, string>, emailQueryParam) => {
   //          acc[emailQueryParam] =
   //             emailValue.selectedEmail?.value ?? emailValue.newEmail;
   //          return acc;
   //       },
   //       {},
   //    );
   // };

   // const paymentSepQueryParams = () => {
   //    if ((selectedPaymentMethod as PaymentMethod).paymentMethodParams) {
   //       return (selectedPaymentMethod as PaymentMethod).paymentMethodParams.reduce(
   //          (acc: Record<string, string>, paymentMethodParams) => {
   //             paymentMethodParams.sepParams.forEach((paramQueryParam) => {
   //                if (paymentMethodParams.name in dynamicVal) {
   //                   acc[paramQueryParam] = dynamicVal[paymentMethodParams.name];
   //                }
   //             });
   //             return acc;
   //          },
   //          {},
   //       );
   //    }
   // };

   // const createSepQueryParams = () => {
   //    let dynamicValWithPrefix: Record<string, string> = dynamicVal;
   //    // eslint-disable-next-line no-extra-boolean-cast
   //    if (!!selectedPaymentMethod.paymentMethodParams) {
   //       // eslint-disable-next-line no-restricted-syntax
   //       for (const method of (selectedPaymentMethod as PaymentMethod)
   //          .paymentMethodParams) {
   //          if (method.name in dynamicVal && method.prefix) {
   //             dynamicValWithPrefix = {
   //                ...dynamicVal,
   //                [method.name]: method.prefix + dynamicVal[method.name],
   //             };
   //          }
   //       }
   //    }
   //    const failedReturnUrl = `${getDomainFromUrl()}/?data=${getDataFromUrl()}`;
   //    // Payment method reference - Select dynamic  payment input value  and copy value to the packageMethodReference param
   //    const selectedPaymentName = (selectedPaymentMethod as PaymentMethod)
   //       .paymentMethodParams?.[0].name;
   //    const paymentMethodReference = dynamicVal[selectedPaymentName];

   //    const paymentMethodType = (selectedPaymentMethod as PaymentMethod).type;

   //    const analyticParam: SepAnalytics = {
   //       sessionId: (cookie as Cookie)['Session-Id'],
   //       userId: localStorageValue,
   //       packageAmount: pricingValue?.amount || 0,
   //       packageUnit: pricingValue?.unit || '',
   //       paymentMethodType,
   //       paymentMethodReference,
   //    };

   //    const sepPropsValues: RedirectToSepUrlProps | DynamicObjValuesProp = {
   //       domain: window.location.origin.replace(/https?:\/\//i, ''),
   //       apikey: serviceKey,
   //       activateUrl: activateURL,
   //       price: virtualCurrencyCode ? 0 : pricingValue?.price,
   //       ...(pricingValue?.merchantReference && {
   //          packageReference: pricingValue?.merchantReference,
   //       }),
   //       ...(pricingValue?.reference && {
   //          voucherPackageReference: pricingValue?.reference,
   //       }),
   //       merchantToken: pricingValue?.merchantToken,
   //       storefrontCode: code,
   //       country: selectedPaymentMethod.countryCode,
   //       ...(selectedPaymentMethod.operatorCode && {
   //          operator: selectedPaymentMethod.operatorCode,
   //       }),
   //       ...(selectedPaymentMethod.providerCode && {
   //          providerCode: selectedPaymentMethod.providerCode,
   //       }),
   //       language: defaultLanguage || 'en',
   //       ...(hasSelectedFromDropdown && { forceWap: true }),
   //       ...(mandatoryData && { failedreturnurl: failedReturnUrl }),
   //       dynamicParams: queryString.stringify({
   //          ...emailSepQueryParams(),
   //          ...paymentSepQueryParams(),
   //          ...dynamicValWithPrefix,
   //       }),
   //       ...analyticParam,
   //       ...(selectedPaymentMethod.paymentAppContext && {
   //          paymentAppContext: selectedPaymentMethod.paymentAppContext,
   //          currency: pricingValue?.currencyCode,
   //       }),
   //       ...(consents.paymentConsent && { activeConsent: consents.paymentConsent }),
   //       ...(consents.contactConsent && { contactConsent: consents.contactConsent }),
   //       ...(virtualCurrencyCode && { virtualCurrency: virtualCurrencyCode }),
   //       ...(virtualCurrencyCode && { virtualPrice: pricingValue?.price }),
   //    };

   //    return queryString.stringify(sepPropsValues);
   // };

   const activateServiceHandler = () => {
      // const queryForSep = createSepQueryParams();

      if (isUserNeededAndNotLogged) {
         keycloak.login();
         return;
      }

      let dynamicValWithPrefix: Record<string, string> = dynamicVal;
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!selectedPaymentMethod.paymentMethodParams) {
         // eslint-disable-next-line no-restricted-syntax
         for (const method of (selectedPaymentMethod as PaymentMethod)
            .paymentMethodParams) {
            if (method.name in dynamicVal && method.prefix) {
               dynamicValWithPrefix = {
                  ...dynamicVal,
                  [method.name]: method.prefix + dynamicVal[method.name],
               };
            }
         }
      }

      // * Validation before submit - empty fields
      const errorInputMessages = hasInputsPopulated({
         dynamicInput: dynamicValWithPrefix,
         email: emailValue,
         packages: pricingValue?.price || '',
      });
      setErrorMessages(errorInputMessages);

      const paymentMethodType = (selectedPaymentMethod as PaymentMethod).type;

      const analyticsHeaders = {
         'Session-Id': (cookie as Cookie)['Session-Id'],
         'User-Id': localStorageValue,
      };

      if (
         enableTopUp &&
         pricingValue?.price &&
         userBalance < parseInt(pricingValue.price, 10)
      ) {
         // eslint-disable-next-line no-unused-expressions
         topUpUrl ? (window.location.href = topUpUrl) : null;
         // toast.error(insufficientBalanceErrorMessage);
      } else {
         dispatch(
            createWalletTransactionAction(
               {
                  country: selectedPaymentMethod.countryCode,
                  initiationToken: pricingValue?.merchantToken || '',
                  operator: selectedPaymentMethod.operatorCode,
                  serviceKey,
                  token: keycloak.token || '',
                  packageAmount: pricingValue?.amount || 0,
                  packageUnit: pricingValue?.unit || '',
                  ...(pricingValue?.merchantReference && {
                     merchantPackageReference: pricingValue.merchantReference,
                  }),
                  paymentMethodType,
               },
               analyticsHeaders,
            ),
         );
      }

      // ! If inputs not validated, forbid the redirect to the SEP
      const ifInputsHasBeenValidated =
         input.errorMessage.length > 0 ||
         phone.errorMessage.length > 0 ||
         paymentMethodInput.errorMessage.length > 0;
      if (
         Object.values(errorInputMessages).filter(Boolean).length !== 0 ||
         ifInputsHasBeenValidated
      ) {
         // Displays error labels on each input which didn't pass validation
      }
      // const validatorsProps = validators.filter(
      //    (validationType) => validationType.trigger === 'onsubmit',
      // );
      // const hasSubmitHandler = !!validatorsProps.length;

      // Validation endpoint for POST request
      // const validationUrl = `${validatorsProps[0]?.url}?language=${defaultLanguage}&storefrontCode=${code}`;

      // Create obj for POST SUBMIT validation method
      // const validationParams = validatorsProps[0]?.params.reduce((acc, item) => {
      //    acc[item] =
      //       // eslint-disable-next-line no-nested-ternary
      //       item === 'email'
      //          ? dynamicVal.email
      //          : item === 'package'
      //          ? pricingValue?.merchantReference
      //          : item;

      //    return acc;
      // }, {});

      // eslint-disable-next-line no-debugger

      // *SEP*
      // if (!isUserNeededAndNotLogged && virtualCurrencyCode) {
      //    redirectToSepPageUrl(queryForSep, false, keycloak.token);
      //    return;
      // }

      //* WHEN VALIDATION IS **NOT** PRESENT ON BACKEND */
      // If validation requirements are missing from backend, redirect directly to the SEP page, without validation methods
      // if (!validators || validators.length === 0) {
      //    if (momentsApiKey && activeMomentsApi) {
      //       const selectedPaymentName = (selectedPaymentMethod as PaymentMethod)
      //          .paymentMethodParams?.[0].name;
      //       const paymentMethodReference = dynamicVal[selectedPaymentName];

      //       const paymentMethodType = (selectedPaymentMethod as PaymentMethod).type;
      //       try {
      //          (window as any)?.pe.setPerson(
      //             {
      //                ...(paymentMethodType === 'MOBILE' && {
      //                   phone: paymentMethodReference,
      //                }),
      //                email: emailValue.selectedEmail?.value ?? emailValue.newEmail,
      //             },
      //             31536000,
      //          );
      //       } catch (error: any) {
      //          (window as any)?.pe.updatePerson({
      //             ...(paymentMethodType === 'MOBILE' && {
      //                phone: paymentMethodReference,
      //             }),
      //             email: emailValue.selectedEmail?.value ?? emailValue.newEmail,
      //          });
      //       }
      //    }

      //    redirectToSepPageUrl(queryForSep, false, keycloak.token);
      //    return;
      // }

      // dispatch(
      //    validateAndSubmitFormAction(
      //       hasSubmitHandler,
      //       validationUrl,
      //       validationParams,
      //       queryForSep,
      //       false,
      //       keycloak.token,
      //    ),
      // );
   };
   const isTopUpNeeded =
      enableTopUp &&
      pricingValue?.price &&
      userBalance < parseInt(pricingValue.price, 10);

   const statusFormLayout = (
      <>
         <div className="status-form-container">
            <img
               src={
                  transactionStatus === TRANSACTION_STATUS.FINISHED
                     ? SuccessModalIcon
                     : FailedModalIcon
               }
               alt=""
               className="status-icon"
            />
            <h5>
               {transactionStatus === TRANSACTION_STATUS.FINISHED
                  ? successfulPurchaseMessage ||
                    '#### No localization for param successfulPurchaseMessage ####'
                  : failedPurchaseMessage ||
                    '#### No localization for param failedPurchaseMessage ####'}
            </h5>
         </div>
         <Button
            loading={isTransactionLoading}
            title={backPurchaseButtonMessage || 'Back to home'}
            onClick={(e: any) => {
               modalProps.onRequestClose?.(e);
               dispatch(resetNewTransaction());
            }}
            className="activate-service-button"
            name="activate"
         />
      </>
   );
   const serviceFormLayout = (
      <>
         {!isUserNeededAndNotLogged && (
            <Col
               className="modal-email-col"
               heading={importMerchantDetailsHeader}
               stepCount="1"
               completed={
                  ifFirstColValidated(
                     dynamicVal,
                     input.isInputValidated,
                     emailValue,
                  ) && isEmailValidated
               }
            >
               <DynamicInputSection
                  dynamicState={dynamicVal}
                  setDynamicState={setDynamicVal}
                  errorMsgLabel={errorMessages}
                  debouncedValidationInputHandler={debouncedValidationInputHandler}
                  debouncedValidationRegex={debouncedRegexValidation}
               />
               <EmailSection
                  emailState={emailValue}
                  setEmailState={setEmailValue}
                  resetErrorAction={resetErrorMessagesOnFocusHandler}
                  setEmailValidatedState={setIsEmailValidated}
                  errorMsgLabel={errorMessages}
                  emailSingleServiceLabel={emailLabel}
               />
            </Col>
         )}
         <PricingFormSection
            errorMsgLabel={errorMessages}
            pricingState={pricingValue}
            setPricingState={setPricingValue}
            resetErrorAction={resetErrorMessagesOnFocusHandler}
            descriptionLabel={selectedPaymentMethod.descriptionLabel}
         />

         <PaymentMethodsFormSection
            hidden={!!virtualCurrencyCode}
            isUserTyping={setIsUserTyping}
            dynamicPaymentMethodState={dynamicVal}
            setDynamicPaymentMethodState={setDynamicVal}
            selectedPaymentMethod={selectedPaymentMethod as PaymentMethod}
            onSelectPaymentClick={(method) => {
               setSelectedPaymentMethod(method);
               dispatch(selectPaymentMethodForPackagesAction(method.id));
            }}
            debouncedValidationPaymentInputHandler={
               debouncedValidationPaymentInputHandler
            }
            debouncedValidationRegex={debouncedRegexValidation}
            clearInputsCallback={() => {
               setDynamicVal({
                  ...dynamicVal,
                  ...paymentParamsObjectValues,
               });
               resetErrorMessagesOnFocusHandler();
               dispatch(resetPaymentMethodValidation());
            }}
            resetErrorAction={resetErrorMessagesOnFocusHandler}
            errorMsgLabel={errorMessages}
            setSelectedFromDropdownState={setHasSelectedFromDropdown}
         />

         <Button
            disabled={
               disableBuyButton ||
               (!enableTopUp &&
                  pricingValue?.price &&
                  userBalance < parseInt(pricingValue.price, 10)) ||
               (paymentConsent && !consents.paymentConsent) ||
               isUserTyping ||
               (packages && packages.length === 0) ||
               formSubmitted
            }
            title={
               // eslint-disable-next-line no-nested-ternary
               isUserNeededAndNotLogged
                  ? buttonSignInToContinueLabel ||
                    '#### No localization for param buttonSignInToContinueLabel ####'
                  : enableTopUp &&
                    pricingValue?.price &&
                    userBalance < parseInt(pricingValue.price, 10)
                  ? enableTopUpLabel
                  : buttonActivateLabel ||
                    '#### No localization for param buttonActivateLabel ####'
            }
            loading={isTransactionLoading}
            onClick={activateServiceHandler}
            className="activate-service-button"
            name="activate"
            enableTopUp={isTopUpNeeded!}
         />
         <ErrorLabel
            isVisible={!!form.errorMessage}
            text={form.errorMessage}
            size="medium"
         />
         <ServiceDescriptionText
            className="service-desc-text-mobile"
            description={description}
         />
      </>
   );
   return (
      <Modal
         onAfterClose={resetErrorMessagesOnFocusHandler}
         key={key}
         {...modalProps}
         className="modal service-details-modal"
         style={{
            overlay: {
               backgroundColor: '#000000a8',
               zIndex: 9999,
            },
         }}
      >
         <section id="service-page-wrap" ref={ref}>
            {isLoading ? (
               <div className="modal-loader">
                  <Loader type="Oval" color="red" height={80} width={80} />
               </div>
            ) : (
               <>
                  <span
                     role="button"
                     className="close-modal-icon"
                     onClick={modalProps.onRequestClose}
                  >
                     X
                  </span>
                  <h3 className="service-desc-title-modal">{name}</h3>
                  <div className="single-service-img-modal-wrap">
                     <img className="single-service-img-modal" src={image} alt="" />
                  </div>

                  <div className="user-profile-balance-modal">
                     {userBalanceAfterPurchaseLabel}
                     <span className="user-profile-balance-amount">
                        {userBalance}
                     </span>
                     <span className="user-profile-balance-icon-modal">
                        <img alt="" src={BalanceIcon} />
                     </span>
                  </div>

                  <Toaster position="bottom-center" reverseOrder={false} />
                  <div className={cx('service-form', classes.serviceForm)}>
                     {!showTransactionStatus && serviceFormLayout}
                     {showTransactionStatus && statusFormLayout}
                  </div>
               </>
            )}
         </section>
      </Modal>
   );
};

export default ServiceDetailsModal;
