import React from 'react';
import cx from 'classnames';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import ServiceBoxDecorator from '../../ui/serviceBoxDecorator/ServiceBoxDecorator';
import { ServiceBoxDecoratorProperties } from '../../../store/services/servicesTypes';

interface ServiceCardProps {
   image?: any;
   title?: string;
   description?: string;
   /* serviceKey: string;
   categoryName?: string;
   serviceDetailsURL?: string | null;
   activateURL?: string | null; */
   serviceDecoratorProps?: ServiceBoxDecoratorProperties | null;
}

const BannerServiceCard = ({
   image,
   title,
   description,
   serviceDecoratorProps,
}: ServiceCardProps) => {
   const history = useHistory();
   const onActivateClickHandler = () => {
      // eslint-disable-next-line no-restricted-globals
      history.push('/profile');
   };

   return (
      <div
         tabIndex={0}
         role="button"
         onClick={() => onActivateClickHandler()}
         className={cx('banner', {
            // clickable: enableClickableServiceCard,
         })}
         style={{ backgroundImage: `url('${image}')` }}
      >
         <ServiceBoxDecorator
            type={serviceDecoratorProps?.serviceBoxDecoratorType || null}
            color={serviceDecoratorProps?.serviceBoxDecoratorColor || ''}
            text={serviceDecoratorProps?.serviceBoxDecoratorText || ''}
            orientation={serviceDecoratorProps?.serviceBoxDecoratorOrientation || ''}
         />
         <div className="banner-content">
            <h4 className="service-title">{title}</h4>
            <p className="service-description"> {parse(description || '')}</p>
         </div>
      </div>
   );
};

export default BannerServiceCard;
