import cx from 'classnames';
import parse from 'html-react-parser';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAppLayout } from '../../../store/selectors';
import { MEDIA_QUERY_BREAKPOINTS } from '../../../utils/constants';
import { useMediaQuery } from '../../../utils/hooks';

interface DescriptionProps {
   description?: string;
   className?: string;
}
const ServiceDescriptionText = ({ description, className }: DescriptionProps) => {
   const isMobile = useMediaQuery(`(max-width: ${MEDIA_QUERY_BREAKPOINTS.phone})`);
   const [showReadMore, setShowReadMore] = useState(true);
   const { readMoreLabel, readLessLabel, readMoreDescriptionOnMobile } =
      useSelector(selectAppLayout);

   const onReadMoreClickHandler = () => {
      setShowReadMore((prevState) => !prevState);
   };

   const showReadMoreOnMobile = readMoreDescriptionOnMobile && isMobile;
   const displayedDescription =
      showReadMoreOnMobile && showReadMore ? description?.slice(0, 80) : description;

   const readMoreLabelWithDots = `...${readMoreLabel}`;
   return (
      <p className={cx('service-desc-text', className)}>
         {parse(displayedDescription || '')}

         {showReadMoreOnMobile && (
            <span
               className={cx('read-more-btn', !showReadMore && 'read-less-btn')}
               tabIndex={0}
               role="button"
               onClick={onReadMoreClickHandler}
            >
               {showReadMore ? readMoreLabelWithDots : readLessLabel}
            </span>
         )}
      </p>
   );
};

export default ServiceDescriptionText;
