import React, { forwardRef } from 'react';
import cx from 'classnames';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import HoverIcon from '../../ui/hoverIcon/HoverIcon';
import classes from './Input.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/img/icon-close.svg';
import ErrorLabel from '../../ui/errorLabel/ErrorLabel';
import { selectAppLayout } from '../../../store/selectors';
import { INPUT_LABEL_TYPE } from '../../../utils/constants';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
   wrapperClassName?: string;
   className?: string;
   disabled?: boolean;
   labelText: any;
   name: string;
   showHoverIcon?: boolean;
   hoverText?: string | React.ReactNode;
   hoverImg?: string;
   hoverLabel?: string | null;
   showCloseIcon?: boolean;
   validationError?: boolean;
   validationText?: string | React.ReactNode;
   onClose?: () => void;
   isSendCodeButtonVisible?: boolean;
   isSendCodeButtonDisabled?: boolean;
   submitSendCodeButton?: () => void;
   isLoading?: boolean;
   bottomLabel?: string | null;
   icon?: string;
   prefixLabel?: string;
   prefix?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
   (
      {
         disabled,
         className,
         wrapperClassName,
         labelText,
         name,
         showHoverIcon = false,
         hoverText = '',
         hoverImg = '',
         hoverLabel = '',
         showCloseIcon = false,
         onClose,
         validationError = false,
         validationText,
         isSendCodeButtonVisible = false,
         isSendCodeButtonDisabled = false,
         submitSendCodeButton,
         isLoading = false,
         bottomLabel,
         icon,
         prefixLabel,
         prefix,
         ...props
      },
      ref,
   ) => {
      const { useLabelComponent, sendPinLabel } = useSelector(selectAppLayout);
      const movingLabel = useLabelComponent === INPUT_LABEL_TYPE.movingLabel;

      const onFocusHandler = () => {
         if (typeof props.value === 'string') {
            return !!props.value.length;
         }
         return false;
      };

      onFocusHandler();
      return (
         <>
            <div className={cx('input-wrap', classes.inputWrap, wrapperClassName)}>
               {!movingLabel && (
                  <label className="input-label" htmlFor={name}>
                     {labelText}
                     {showHoverIcon && (
                        <HoverIcon
                           iconType="inside"
                           text={hoverText}
                           img={hoverImg}
                        />
                     )}
                  </label>
               )}

               <span className={classes.inputInnerWrap}>
                  {prefix && <p className="prefix-mask">{prefixLabel}</p>}
                  <input
                     disabled={disabled}
                     className={cx(
                        'input',
                        className,
                        validationError && 'validation-error',
                     )}
                     {...props}
                     name={name}
                     min="0"
                     ref={ref}
                  />

                  {icon && <img alt="" src={icon} />}

                  {movingLabel && (
                     <span
                        className={cx(
                           'input-moving-label',
                           validationError && 'validation-error-moving-label',
                           onFocusHandler() && 'input-moving-label-focus',
                           'big-label',
                        )}
                     >
                        <p> {labelText}</p>
                     </span>
                  )}

                  {/* PIN btn */}
                  {isSendCodeButtonVisible && (
                     <span role="button" tabIndex={0} onClick={onClose}>
                        <span
                           role="button"
                           onClick={() => submitSendCodeButton?.()}
                           tabIndex={0}
                           className={cx('send-code-btn', {
                              disabled: isSendCodeButtonDisabled || disabled,
                           })}
                        >
                           {sendPinLabel || '### sendPinLabel no localization ###'}
                        </span>
                     </span>
                  )}

                  {/* Close icon on the input */}
                  {showCloseIcon && (
                     <span
                        role="button"
                        tabIndex={0}
                        onClick={onClose}
                        className="close-icon-wrap"
                        aria-label="Close icon"
                     >
                        <CloseIcon className="close-icon" />
                     </span>
                  )}

                  {/* Show loader on async actions */}
                  {isLoading && (
                     <span className="input-loader ">
                        <Loader type="Oval" color="red" height={80} width={80} />
                     </span>
                  )}
               </span>

               {bottomLabel && !validationError && (
                  <p className="bottom-label"> {bottomLabel}</p>
               )}

               {/* Validation message */}
               <ErrorLabel
                  isVisible={validationError}
                  text={validationText}
                  size="small"
               />

               {/* Hover icon under the input */}
               {movingLabel && showHoverIcon && (
                  <HoverIcon iconType="outside" text={hoverText} img={hoverImg}>
                     <label className="input-label hover-outside" htmlFor={name}>
                        {hoverLabel}
                     </label>
                  </HoverIcon>
               )}
            </div>
         </>
      );
   },
);

Input.displayName = 'Input';
export default Input;
