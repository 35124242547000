import { GetInitialLayoutProps } from '../../types/globals';
// 1.ACTION TYPES
// Actions constants
export enum AppActionTypes {
   // Dark mode
   DARK_MODE = '@@app/DARK_MODE',
   SET_DARK_MODE = '@@app/SET_DARK_MODE',

   // Initial Layout configuration
   RESET_APP_STATE = '@@app/RESET_APP_STATE',
   GET_INITIAL_LAYOUT_START = '@@app/GET_INITIAL_LAYOUT_START',
   GET_INITIAL_LAYOUT_SUCCESS = '@@app/GET_INITIAL_LAYOUT_SUCCESS',
   GET_INITIAL_LAYOUT_ERROR = '@@app/GET_INITIAL_LAYOUT_ERROR',

   // CSS LOADED
   CSS_LOADED = '@@app/CSS_LOADED',
   APP_IS_READY = '@@app/APP_IS_READY',
   // Error fetching initial config
   APP_ERROR = '@@app/APP_ERROR',

   // ANALYTIC ACTION
   SEND_ANALYTICS = '@@app/SEND_ANALYTICS',
   // REGISTER ANALYTIC ACTION
   SEND_REGISTER_ANALYTICS = '@@app/SEND_REGISTER_ANALYTICS',
   // SIGN IN ANALYTIC ACTION
   SEND_SIGN_IN_ANALYTICS = '@@app/SEND_SIGN_IN_ANALYTICS',
   // VIEW PROFILE ANALYTIC ACTION
   SEND_VIEW_PROFILE_ANALYTICS = '@@app/SEND_VIEW_PROFILE_ANALYTICS',
   // CHATBOT CLICKED ANALYTIC ACTION
   SEND_CHATBOT_CLICKED_ANALYTICS = '@@app/SEND_CHATBOT_CLICKED_ANALYTICS',
}

type DarkMode = {
   type: AppActionTypes.DARK_MODE;
};
type SetDarkMode = {
   type: AppActionTypes.SET_DARK_MODE;
   payload: boolean;
};
type CSSLoaded = {
   type: AppActionTypes.CSS_LOADED;
};
type AppIsReady = {
   type: AppActionTypes.APP_IS_READY;
};
type ResetApp = {
   type: AppActionTypes.RESET_APP_STATE;
};
type AppError = {
   type: AppActionTypes.APP_ERROR;
};
type InitialLayoutStart = {
   type: AppActionTypes.GET_INITIAL_LAYOUT_START;
};
type InitialLayoutSuccess = {
   type: AppActionTypes.GET_INITIAL_LAYOUT_SUCCESS;
   payload: {
      layout: StorefrontConfigResponse;
      queryParams: GetInitialLayoutProps;
   };
};
type InitialLayoutError = {
   type: AppActionTypes.GET_INITIAL_LAYOUT_ERROR;
};
type SendAnalytics = {
   type: AppActionTypes.SEND_ANALYTICS;
};
type SendRegisterAnalytics = {
   type: AppActionTypes.SEND_REGISTER_ANALYTICS;
};
type SendSignInAnalytics = {
   type: AppActionTypes.SEND_SIGN_IN_ANALYTICS;
};
type SendViewProfileAnalytics = {
   type: AppActionTypes.SEND_VIEW_PROFILE_ANALYTICS;
};
type SendChatbotClickedAnalytics = {
   type: AppActionTypes.SEND_CHATBOT_CLICKED_ANALYTICS;
};

// Actions dispatch types
export type AppDispatchTypes =
   | SendChatbotClickedAnalytics
   | SendSignInAnalytics
   | SendViewProfileAnalytics
   | SendRegisterAnalytics
   | ResetApp
   | CSSLoaded
   | DarkMode
   | SetDarkMode
   | InitialLayoutStart
   | InitialLayoutSuccess
   | InitialLayoutError
   | AppIsReady
   | AppError
   | SendAnalytics;

// 2.API REQUEST TYPES
export interface StorefrontConfigResponse {
   slider: null;
   css: string;
   activeServicesTitle: null;
   automaticDarkMode: boolean;
   tosLabel: null;
   choosePhoneLabel: string;
   phoneNumberLabel: string;
   metaDescription: null;
   includeThemeOptions: boolean;
   choosePackagesOneService: null;
   addNewEmailLabel: string;
   activeServiceModalTitle: null;
   msisdns: null | string | string[];
   id: string;
   emptyUserServicesLabel: string;
   emptyUserServicesHeaderLabel: string;
   serviceTermsAndConditionsLabel: string;
   defaultCountry: string;
   warnSelectPhoneNumberLabel: string;
   categoryServicesRowSize: number;
   contactLabel: null;
   headerTitleDescription: null;
   logoUrl: string;
   addNewPhoneLabel: null | string;
   internalDescription: string;
   tosUrl: string;
   footerTitleLabel: null;
   domain: string;
   includeFooter: boolean;
   name: string;
   viewType: string;
   emptyPackagesOneService: null;
   emailLabel: string;
   headerTitle: null;
   payWithMobileLabel: string;
   enableCategoryServicesSlider: boolean;
   code: string;
   serviceHelpLabel: string;
   siteTitle: null;
   chooseEmailLabel: string;
   smsPinLength: number;
   serviceTermsAndConditionsURLLabel: string;
   emails: null | string[];
   defaultLanguage: string;
   requiredField: null;
   favIcon: string;
   warnSelectPackageLabel: string;
   warnWrongEmailFormat: string;
   helpUrl: string;
   languageCodes: string;
   usersLogin: boolean;
   helpLabel: null;
   hlrMsisdnValidator: string;
   activeServicesDesc: null | string;
   paginationLayout: null;
   contactUrl: string;
   enableActiveServicesBanner: boolean;
   warnSelectEmailLabel: string;
   includeHeader: boolean;
   includeSlider: boolean;
   phoneNumberPlaceHolderLabel: string;
   hlrValidationFailedLabel: string;
   hlrValidationErrorLabel: string;
   importPhoneNumberLabel: string;
   importEmailLabel: string;
   pinModalPhoneNumberMessageLabel: string;
   pinModalEmailMessageLabel: string;
   checkEmailIsCorrectLabel: string;
   resendPinLabel: string;
   font: string;
   priceLocale: string;
   priceMaximumFractionDigits: number;
   priceMinimumFractionDigits: number;
   enableResendPinSeconds: number;
   stickyHeader: boolean;
   htmlFooter: any;
   subHeader: any;
   mandatoryData: null | boolean;
   includeStepCount: null | boolean;
   introSectionTitle: null | string;
   introSectionTitleMobile: null | string;
   introSectionDescription: null | string;
   useActiveServicesComponent: string | null;
   buttonActivateLabel: string;
   buttonActiveServicesLabel: string;
   useLabelComponent: string;
   activeServiceModalDescription: string;
   importPinLabel: string;
   emailRegex: string;
   phoneNumberRegex: string;
   warnWrongPhoneNumberFormat: string;
   activeServicesHeader: string;
   standalone: boolean;
   showCategoryFilter: boolean;
   categoryNameType: string;
   serviceDetailsURLLabel: string;
   warnPhoneNumberHLRError: string;
   error401Response: string;
   error403Response: string;
   error404Response: string;
   error500Response: string;
   warnPhoneNumberHLRForbiddenProfile: string;
   gaCode: null | string;
   allCategoriesFilterLabel: string;
   enableAccordionTos: boolean;
   accordionTosLabel: null | string;
   accordionTosText: null | string;
   activeServiceModalOptions: string[];
   sendPinLabel: string;
   phoneNumberModalTabHeader: string;
   buttonActivateAllServicesLabel: string;
   buttonActivateAllServicesExternalLabel: string;
   activeServicesEmailInputLabel: string;
   activeServiceEmailLabel: string;
   choosePaymentMethod: string;
   packageNoteLabel: string | null;
   backButtonLabel: string;
   searchLabel: string;
   searchNoResultLabel: string;
   activeMomentsApi: boolean | null;
   momentsApiKey: string | null;
   readMoreLabel: string | null;
   readLessLabel: string | null;
   readMoreDescriptionOnMobile: boolean;
   paymentConsent: boolean | null;
   paymentConsentLabel: string | null;
   contactConsent: boolean | null;
   contactConsentLabel: string | null;
   importMerchantDetailsHeader: string | null;
   contactConsentDefaultValue: string | null;
   paymentConsentDefaultValue: string | null;
   enableClickableServiceCard: boolean;
   hideViewServiceButtonOnServiceCard: boolean;
   emptyActiveServicesImageURL: string | null;
   syncLabel: string | null;
   seconds: string;
   oneTimePaymentLabel: string;
   subscriptionLabel: string;
   canceledActiveServicesButton: string;
   stopSubscription: string;
   dateOfPurchase: string;
   activated: string;
   validUntil: string;
   deactivated: string;
   virtualCurrencyCode: string;
   signOutLabel: string;
   userProfileCompanyLabel: string;
   userProfileBalanceLabel: string;
   userProfileActivatedServicesLabel: string;
   userProfileWalletHistoryLabel: string;
   enableBottomNavigation: boolean;
   insufficientBalanceErrorMessage: string | null;
   enableTopUp: boolean;
   topUpUrl: string;
   enableTopUpLabel: string;
   unsubscribeWaitMessage: string | null;
   unsubscribeErrorMessage: string | null;
   consentTermsAndConditionsUrl: string;
   consentTermsAndConditionsLabel: string | null;
   activeServicesHeaderButtonLabel: string | null;
   buttonSignInToContinueLabel: string | null;
   buttonSignInLabel: string | null;
   userProfileEmptyActiveServices: string | null;
   userProfileEmptyWalletHistory: string | null;
   packageNameLabelOneService: string | null;
   priceLabelOneService: string | null;
   disabledPackageLabel: string | null;
   disableInvalidPackages: boolean;
   disableBuyButton: boolean;
   successfulPurchaseMessage: string | null;
   failedPurchaseMessage: string | null;
   backPurchaseButtonMessage: string | null;
   userBalanceAfterPurchaseLabel: string | null;
   heroBanner: HeroBanner;
}

export interface HeroBanner {
   id: string;
   type: string;
   imageUrls: string[];
   slideSpeed: number | null;
}

// 3.STATE TYPES
export interface AppState {
   auth: {
      data: string;
   };
   states: {
      isDarkModeActive: boolean;
      isAppReady: boolean;
      isCSSReady: boolean;
      isError: boolean;
   };
   layout: StorefrontConfigResponse | Record<string, never>;
}
