import React from 'react';
import cx from 'classnames';
import classes from './RadioButton.module.scss';

interface RadioBtnProps {
   checked: boolean;
}

const RadioButton = ({ checked }: RadioBtnProps) => {
   return (
      <div
         tabIndex={0}
         role="checkbox"
         aria-labelledby="button-label"
         aria-checked="true"
         //  onClick={() => setChecked(!checked)}
         className={cx('radio-btn', checked && 'checked', {
            [classes.checked]: checked,
         })}
      />
   );
};

export default RadioButton;
