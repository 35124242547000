import { useSelector, shallowEqual } from 'react-redux';
import parse from 'html-react-parser';
import { useRef, useLayoutEffect } from 'react';
import {
   selectAppLayout,
   selectSingleServiceState,
   selectValidationState,
} from '../../../../../store/selectors';
import Input from '../../../../form/input/Input';
import Dropdown from '../../../../form/input/dropdown/Dropdown';

interface DynamicInputSectionProps {
   dynamicState: any;
   setDynamicState: (e: any) => void;
   debouncedValidationInputHandler: (paramName: string) => void;
   debouncedValidationRegex: (paramName: string) => void;
   errorMsgLabel?: any;
}

const DynamicInputSection = ({
   dynamicState,
   setDynamicState,
   debouncedValidationInputHandler,
   debouncedValidationRegex,
   errorMsgLabel,
}: DynamicInputSectionProps) => {
   const { params, validators } = useSelector(
      selectSingleServiceState,
      shallowEqual,
   );
   const { input } = useSelector(selectValidationState, shallowEqual);
   const { chooseEmailLabel } = useSelector(selectAppLayout, shallowEqual);
   const inputRef = useRef<any>(null);
   const paramsList = params?.map((param) => `dynamic${param.name}`);

   // *Function  that check if dynamic input has some external validation(api call...)
   const hasParamValidators = (paramName: string) => {
      if (validators === null || validators.length === 0) return false;
      /* 
         Validators array contains a object with trigger,params(array) and url props.
         We need to loop through initial object to check if validators are present. 
         Then, we need to loop through each validator params array to check input name and compare it with focused param name (on which input user type)
         */
      const hasValidator = validators.some((validator) => {
         if (validator.params) {
            return validator.params.some((param) => {
               return param === paramName;
            });
         }
         return false;
      });

      return hasValidator;
   };

   const areInputsValidated = () => {
      // Create all the keys from the error object in Service page, and filter the ones that have error(string is value if error is present)
      const errorKeys = Object.keys(errorMsgLabel).filter((param) =>
         paramsList.includes(param),
      );

      const errorWarningsFromInputs = errorKeys.map(
         (errorKey) => errorMsgLabel[errorKey],
      );

      return !!errorWarningsFromInputs.length;
   };

   useLayoutEffect(() => {
      if (
         (inputRef && inputRef.current && areInputsValidated()) ||
         // (inputRef && inputRef.current && errorMsgLabel.dynamicEmail) ||
         input.errorMessage
      ) {
         inputRef?.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errorMsgLabel, input.errorMessage]);

   return (
      <>
         {params &&
            dynamicState &&
            params.map((param) => {
               // Render input field if type is string or number`
               if (['String', 'Integer', 'Number'].includes(param.type)) {
                  return (
                     <Input
                        ref={inputRef}
                        isLoading={input.isLoading && hasParamValidators(param.name)}
                        validationError={
                           errorMsgLabel[`dynamic${param.name}`] ||
                           input.isInputValidated === false
                           // errorMsgLabel.dynamicEmail
                        }
                        validationText={
                           errorMsgLabel[`dynamic${param.name}`] ||
                           parse(input.errorMessage)
                        }
                        key={param.id}
                        showHoverIcon={!!param.description || !!param.imageUrl}
                        hoverImg={param.imageUrl ?? ''}
                        hoverText={parse(param.description || '')}
                        hoverLabel={param.additionalInfoLabel}
                        value={dynamicState[param.name] || ''}
                        onChange={(e) => {
                           setDynamicState({
                              ...dynamicState,
                              [param.name]: e.target.value,
                           });

                           if (e.target.value.length < 1) return;

                           // If input has regex value,debounce validation func
                           debouncedValidationRegex(param.name);
                           if (param.stringFormatRegex) {
                              debouncedValidationRegex(param.name);
                           }
                           // If input has external API validation call, debounce validation func
                           if (hasParamValidators(param.name)) {
                              debouncedValidationInputHandler(param.name);
                           }
                        }}
                        labelText={
                           param.label ||
                           `#### No localization for param  ${param.name} ####`
                        }
                        name={param.name}
                     />
                  );
               }

               // Render dropdown if type is enum
               if (param.type === 'Enum') {
                  return (
                     <Dropdown
                        key={param.id}
                        isSearchable={false}
                        classNamePrefix="phones-dropdown"
                        defaultValue={dynamicState[param.name]}
                        onChange={(value) =>
                           setDynamicState({
                              ...dynamicState,
                              [param.name]: value?.value,
                           })
                        }
                        options={param.enumValues?.map((singleEnum) => {
                           return {
                              label: singleEnum,
                              value: singleEnum,
                           };
                        })}
                        labelText={param.name}
                        placeholder={
                           chooseEmailLabel ||
                           '#### No localization for param chooseEmailLabel ####'
                        }
                     />
                  );
               }

               return null;
            })}
      </>
   );
};

export default DynamicInputSection;
