import queryString from 'query-string';
import moment from 'moment/moment';

import {
   EmailValues,
   GetInitialLayoutProps,
   AnalyticsInputValues,
} from '../types/globals';
import store from '../store/rootStore';
import { PaymentMethodParam } from '../store/services/servicesTypes';
import { ANALYTICS_INPUT_PARAMS } from './constants';

export const insertExternalCSS = (url: string) => {
   if (!url) return;
   const { head } = document;
   const link = document.createElement('link');
   link.type = 'text/css';
   link.rel = 'stylesheet';
   link.href = url;
   link.id = 'css';
   head.appendChild(link);
};

export const validateInputForRegex = (phone: boolean, valueForCheck: string) => {
   const { emailRegex, phoneNumberRegex } = store.getState().app.layout;
   const regexConst = phone ? phoneNumberRegex : emailRegex;
   if (regexConst != null) {
      const regex = new RegExp(regexConst);
      return regex.test(valueForCheck);
   }
   return true;
};

export const getDataFromUrl = () => {
   const { data } = queryString.parse(
      window.location.search,
   ) as unknown as GetInitialLayoutProps;
   return data;
};

export const getDomainFromUrl = () => {
   return window.location.origin;
};
export const getDomainWithoutProtocol = () => {
   return window.location.origin.replace(/https?:\/\//i, '');
};

export const getUrlParams = () => {
   // * 1.Get data from iframe src query param
   const data = getDataFromUrl();

   // * 2.Get domain URL without protocol
   const domain = window.location.origin.replace(/https?:\/\//i, '');

   return { data, domain };
};

export const isEmailEmpty = (email: EmailValues) => {
   if (email.selectedEmail !== null && Object.keys(email.selectedEmail).length > 0) {
      return false;
   }

   if (validateInputForRegex(false, email.newEmail)) {
      return false;
   }

   return true;
};

export const isPhoneInCorrectFormat = (phone: string) => {
   const { warnWrongPhoneNumberFormat } = store.getState().app.layout;
   if (!validateInputForRegex(true, phone)) {
      return (
         warnWrongPhoneNumberFormat ||
         '#### No localization for param warnWrongPhoneNumberFormat ####'
      );
   }
   return '';
};

export const formatIsoDate = (isoDate: string | null) => {
   if (isoDate) {
      return moment(isoDate).format('MMM DD, yyyy');
   }
   return '';
};
export const formatSimpleDate = (simpleDate: string | null) => {
   if (simpleDate) {
      return moment(simpleDate, 'DD.MM.yyyy.').format('MMM DD, yyyy');
   }
   return '';
};

export const getRootUrl = () => {
   let base = `${window.location.protocol}//${window.location.hostname}`;
   if (window.location.port) {
      base = `${base}:${window.location.port}`;
   }
   return base;
};

interface InputValidationProps {
   dynamicInput?: any;
   email: EmailValues;
   phone?: {
      isPhoneInputPopulated: string | boolean;
      isInputValidated: boolean | null;
      newPhoneValue: string;
      selectedPhoneValue: any | null;
   };
   packages: string;
   mbon?: string;
}

interface isDynamicInputRegexValidatedProps {
   dynamicVal: any;
   paramName: string;
}

// Check for regex validation on dynamic params
export const isDynamicInputRegexValidated = ({
   dynamicVal,
   paramName,
}: isDynamicInputRegexValidatedProps) => {
   const errors: any = {};
   const params = store.getState().services.single?.params;

   if (dynamicVal !== null) {
      Object.entries(dynamicVal).forEach(([,]) => {
         // If focused exist on the ServicePage state
         if (paramName in dynamicVal) {
            // Based in paramName, find that input obj in params obj in single service state
            const singleDynamicParam = params?.find(
               (singleParam) => singleParam.name === paramName,
            );

            // Check if dynamic input has regex prop
            if (singleDynamicParam?.stringFormatRegex) {
               const regex = new RegExp(singleDynamicParam.stringFormatRegex);
               const isRegexValid = regex.test(dynamicVal[paramName]);
               if (!isRegexValid) {
                  errors[`dynamic${paramName}`] =
                     singleDynamicParam.warnInvalidFormatLabel ??
                     '### warnInvalidFormatLabel not present###';
               } else {
                  /* 
                   If regex validation, passed, return empty value prop, with key inside
                  It's important for overading errors obj in Service Page(if we return just a {}, it wont update key in obj when destructing)
                   */
                  errors[`dynamic${paramName}`] = '';
               }
            }
         }
      });
   }
   return errors;
};

export const findSelectedPaymentMethodParams = (paramName: string) => {
   const paymentMethods = store.getState().services.single?.paymentMethods;
   let selectedPaymentMethod: PaymentMethodParam | undefined;

   paymentMethods.some((payments) => {
      selectedPaymentMethod = payments.paymentMethodParams?.find((paymentParam) => {
         return paymentParam.name === paramName;
      });
      return selectedPaymentMethod;
   });

   return selectedPaymentMethod;
};

export const hasInputsPopulated = ({
   dynamicInput,
   email,
   phone,
   packages,
}: InputValidationProps) => {
   const errors: Record<string, string> = {
      email: '',
      phone: '',
      packages: '',
   };

   //* Get localized error messages from tenant
   const {
      warnSelectEmailLabel,
      warnSelectPackageLabel,
      warnSelectPhoneNumberLabel,
      warnWrongEmailFormat,
      warnWrongPhoneNumberFormat,
   } = store.getState().app.layout;

   const params = store.getState().services.single?.params;
   //* Get localized error message from single service dynamic param
   const { warnEmptyPackagesMessage } = store.getState().services.single;

   // Verification for dynamic params
   if (dynamicInput !== null) {
      Object.entries(dynamicInput).forEach(([key]) => {
         const singleParam = params?.find((singleParam) => singleParam.name === key);
         const selectedPaymentMethod = findSelectedPaymentMethodParams(key);

         // !Validate regex - when fields are populated
         if (dynamicInput[key]?.length > 0) {
            // Regex validation for payment methods
            if (selectedPaymentMethod?.regex) {
               const regex = new RegExp(selectedPaymentMethod.regex);
               const isRegexValid = regex.test(dynamicInput[key]);

               if (!isRegexValid) {
                  errors[`dynamic${key}`] =
                     selectedPaymentMethod.warnInvalidInputFormat ??
                     '### warnInvalidFormatLabel NOT PRESENT ON PAYMENT METHODS###';
               }
            }

            // Regex validation for dynamic inputs methods
            if (singleParam?.stringFormatRegex) {
               const regex = new RegExp(singleParam.stringFormatRegex);
               const isRegexValid = regex.test(dynamicInput[key]);

               if (!isRegexValid) {
                  errors[`dynamic${key}`] =
                     singleParam.warnInvalidFormatLabel ??
                     '### warnInvalidFormatLabel NOT PRESENT ###';
               }
            }
         }

         // !Validate empty fields
         if (dynamicInput[key]?.length === 0) {
            errors[`dynamic${key}`] =
               selectedPaymentMethod?.warnEmptyInputField ||
               '### ERROR MISSING FIELD - PAYMENT METHODS ###';

            if (singleParam?.required) {
               errors[`dynamic${key}`] =
                  singleParam?.warnEmptyParamLabel ||
                  '###Add warnEmptyParamLabel###';
            }
         }
      });
   }

   // Emails

   if (window.location.hostname !== 'almadar-store.ly') {
      if (
         email.newEmail.length > 0 &&
         !validateInputForRegex(false, email.newEmail)
      ) {
         errors.email =
            warnWrongEmailFormat ||
            '#### No localization for param warnWrongEmailFormat ####';
      }

      if (email.selectedEmail === null && email.newEmail.length === 0) {
         errors.email =
            warnSelectEmailLabel ||
            '#### No localization for param warnSelectEmailLabel ####';
      }
   }

   // Phone
   // If phone obj has been passed - depends of the wallet payment boxes
   if (phone !== undefined) {
      if (!phone?.isPhoneInputPopulated) {
         errors.phone =
            warnSelectPhoneNumberLabel ||
            '#### No localization for param warnSelectPhoneNumberLabel ####';
      }

      if (
         phone &&
         phone.newPhoneValue.length > 0 &&
         !validateInputForRegex(true, phone.newPhoneValue)
      ) {
         errors.phone =
            warnWrongPhoneNumberFormat ||
            '#### No localization for param warnWrongPhoneNumberFormat ####';
      }
   }

   // Packages
   if (packages.length === 0) {
      errors.packages =
         warnEmptyPackagesMessage ||
         warnSelectPackageLabel ||
         '#### No localization for param warnSelectPackageLabel ####';
   }

   return errors;
};

export const ifFirstColValidated = (
   dynamicValue: any,
   isDynamicInputValidated: any,
   emailValue: EmailValues,
) => {
   const params = store.getState().services.single?.params;

   const isMailValidated =
      (!!emailValue.newEmail && emailValue.selectedEmail === null) ||
      (emailValue.newEmail?.length === 0 && !!emailValue.selectedEmail);

   if (dynamicValue && Object.keys(dynamicValue).length === 0) {
      return isMailValidated;
   }

   // *Dynamics fields
   // Return array of required fields keys
   const requiredFields =
      dynamicValue &&
      Object.keys(dynamicValue).filter((values) => {
         return params?.some((param) => {
            return param.name === values && param.required;
         });
      });

   // Delete un-required obj key from validation()
   const requiredObjFields = {};
   if (dynamicValue) {
      Object.keys(dynamicValue).forEach((key) => {
         if (requiredFields.includes(key)) {
            requiredObjFields[key] = dynamicValue[key];
         }
      });
   }
   const isDynamicParamValidated =
      dynamicValue &&
      Object.values(requiredObjFields).every((e) => Boolean(e)) &&
      isDynamicInputValidated;

   return isMailValidated && isDynamicParamValidated;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const removeObjProp = (key: string, { [key]: _, ...rest }) => rest;

export const inputsForAnalytics = (
   dynamicValues: Record<string, string>,
): AnalyticsInputValues => {
   if (!dynamicValues) return {};
   return Object.keys(dynamicValues).reduce((acc, key) => {
      if (key in ANALYTICS_INPUT_PARAMS && dynamicValues[key]) {
         acc[key] = dynamicValues[key];
      }
      return acc;
   }, {});
};

export const addPeopleScriptSDK = (apiKey: string | null) => {
   if (!apiKey) return null;

   const script = `
      (function(e,t,n,o){e.PeopleEventsObject=o;e[o]=e[o]||{init:function(t){e[o].apiKey=t},
      setPerson:function(t,n){e[o].person=t;e[o].personTtl=n},forgetPerson:function(){e[o].toForgetPerson=true},track:function(){(e[o].q=e[o].q||[]).push(arguments)},
      updatePerson:function(t){e[o].personToUpdate={person:t}},appendToList:function(t,n){e[o].attributeToAppend={attributeName:t,attribute:n}}};var r=t.createElement("script");
      var s=t.getElementsByTagName("script")[0];r.async=1;r.src=n;s.parentNode.insertBefore(r,s)})
      (window,document,"https://s3.eu-central-1.amazonaws.com/portal-cdn-production/people-events-sdk/pe.latest-2.js","pe");
      
      pe.init('${apiKey}');`;

   return script;
};

export const getCookieValue = (cookieName: string) => {
   const cookieString = document.cookie;
   const cookieList = cookieString.split(';');

   const foundCookie = cookieList.find((cookie) => {
      const [name] = cookie.trim().split('=');
      return name === cookieName;
   });

   return foundCookie ? foundCookie.split('=')[1] : '';
};
