/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import history from '../../utils/history';

const BottomNavigation = () => {
   return (
      <div id="bottom-nav-container">
         <div className="icon-container">
            <div className="icon play" />
            <p className="icon-text">Play</p>
         </div>
         <div className="icon-container">
            <div className="icon deals" />
            <p className="icon-text">Deals</p>
         </div>
         <div className="icon-container">
            <div
               className="icon points"
               onClick={() => {
                  history.push('/profile');
               }}
            />
            <p className="icon-text">Points</p>
         </div>
         {/* <div className="icon-container">
            <div className="icon win" />
            <p className="icon-text">Win</p>
         </div> */}
         <div className="icon-container">
            <div className="icon why" />
            <p className="icon-text">
               <a href="https://wa.me/385997828570">Ask Cuanto</a>
            </p>
         </div>
      </div>
   );
};

export default BottomNavigation;
