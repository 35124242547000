import React from 'react';
import { Routes } from '../../../utils/constants';
import classes from './ErrorFallback.module.scss';

const ErrorFallback = () => {
   return (
      <div className={classes.errorMessageWrap}>
         <h1 className={classes.errorMessageTitle}>Something went wrong.</h1>
         <p className={classes.errorDescription}>
            Go back to our <a href={Routes.HOME_ROUTE}>homepage</a>
         </p>
      </div>
   );
};

export default ErrorFallback;
