import React from 'react';
import { useSelector } from 'react-redux';
import { selectAppLayout } from '../../../store/selectors';
import Intro from './Intro';
import IntroCarousel from './IntroCarousel';

const IntroSection = () => {
   const {
      introSectionDescription,
      introSectionTitle,
      introSectionTitleMobile,
      heroBanner,
   } = useSelector(selectAppLayout);

   const { type, imageUrls, slideSpeed } = heroBanner || {};
   const isStatic = type === 'STATIC' || !heroBanner || !imageUrls?.length;
   const showStaticIntroSection = introSectionDescription || introSectionTitle;

   return (
      <>
         {isStatic ? (
            <>
               {showStaticIntroSection && (
                  <Intro
                     title={introSectionTitle}
                     mobileTitle={introSectionTitleMobile}
                     description={introSectionDescription}
                  />
               )}
            </>
         ) : (
            <IntroCarousel slides={imageUrls} slideSpeed={slideSpeed} />
         )}
      </>
   );
};

export default IntroSection;
