import Loader from 'react-loader-spinner';
import classes from './Loaders.module.scss';

export const RedLoader = () => {
   return <Loader type="Oval" color="red" height={80} width={80} />;
};
export const DefaultLoader = () => {
   return <Loader type="Bars" color="green" height={80} width={80} />;
};
export const BoxLoader = () => {
   return (
      <div className={classes.loaderWrap}>
         <div className={classes.loaderBlack} />
         <div className={classes.loaderRed} />
      </div>
   );
};

export default { RedLoader, DefaultLoader, BoxLoader };
