import { motion } from 'framer-motion';
import cx from 'classnames';
import classes from './ServiceDescription.module.scss';
import ServiceDescriptionText from './ServiceDescriptionText';
import ServiceDescriptionTitle from './ServiceDescriptionTitle';
import TOS from './TOS';

interface ServiceFormImageProps {
   imageSrc: string;
   description?: string;
   serviceTitle?: string;
   helpLinkLabel?: string;
   helpLinkUrl?: null | string;
   tosLabel?: string;
   tosUrlLabel?: string;
   tosUrl?: null | string;
   className?: string;
}

const ServiceDescription = ({
   imageSrc,
   description,
   serviceTitle,
   helpLinkLabel,
   helpLinkUrl,
   tosLabel,
   tosUrlLabel,
   tosUrl,
   className,
}: ServiceFormImageProps) => {
   return (
      <motion.div
         initial={{ y: -30, opacity: 0 }}
         animate={{ y: 0, opacity: 1 }}
         transition={{ delay: 0.2 }}
         className={cx(classes.serviceImage, className)}
      >
         <img className="single-service-img" src={imageSrc} alt="" />
         <div className={classes.serviceDescriptionWrap}>
            <div className={classes.serviceDescription}>
               <ServiceDescriptionTitle serviceTitle={serviceTitle} />
               <ServiceDescriptionText description={description} />
               <TOS
                  helpLinkLabel={helpLinkLabel}
                  helpLinkUrl={helpLinkUrl}
                  tosLabel={tosLabel}
                  tosUrlLabel={tosUrlLabel}
                  tosUrl={tosUrl}
               />
            </div>
         </div>
      </motion.div>
   );
};

export default ServiceDescription;
