/* eslint-disable no-param-reassign */
import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { AppState, AppActionTypes, AppDispatchTypes } from './appTypes';
import { APP_INITIAL_STATE } from '../../utils/constants';

const appState: AppState = {
   ...APP_INITIAL_STATE,
};

const authReducer: Reducer<AppState> = produce(
   (draft: Draft<AppState>, action: AppDispatchTypes) => {
      switch (action.type) {
         case AppActionTypes.SET_DARK_MODE:
            draft.states.isDarkModeActive = action.payload;
            break;
         case AppActionTypes.DARK_MODE:
            draft.states.isDarkModeActive = !draft.states.isDarkModeActive;
            break;

         case AppActionTypes.RESET_APP_STATE:
            draft = { ...APP_INITIAL_STATE };
            break;
         case AppActionTypes.GET_INITIAL_LAYOUT_START:
            draft.states.isCSSReady = false;
            draft.states.isAppReady = false;
            draft.states.isError = false;
            break;
         case AppActionTypes.GET_INITIAL_LAYOUT_SUCCESS:
            if (action.payload.queryParams?.data) {
               draft.auth.data = action.payload.queryParams.data;
            }
            draft.layout = action.payload.layout;
            draft.states.isCSSReady = true;
            draft.states.isAppReady = true;
            break;

         case AppActionTypes.GET_INITIAL_LAYOUT_ERROR:
            draft.states.isAppReady = true;
            draft.states.isError = true;
            break;

         case AppActionTypes.APP_IS_READY:
            draft.states.isAppReady = true;
            break;
         case AppActionTypes.APP_ERROR:
            draft.states.isAppReady = true;
            draft.states.isError = true;
            break;

         default:
            return;
      }

      return draft;
   },
   appState,
);

export default authReducer;
