import cx from 'classnames';
import { useEffect, useRef } from 'react';
import classes from './ErrorLabel.module.scss';

export interface ErrorLabelProps {
   text: string | React.ReactNode;
   size: 'small' | 'medium' | 'large';
   position?: 'left' | 'center' | 'right'; // * possible options [left, center, right]
   isVisible: boolean;
}

const ErrorLabel = ({ text, position, size, isVisible }: ErrorLabelProps) => {
   const errorLabelRef = useRef<HTMLDivElement | null>(null);

   useEffect(() => {
      if (errorLabelRef && errorLabelRef.current) {
         errorLabelRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
         });
      }
   }, [text, isVisible]);

   return (
      <>
         {isVisible && (
            <div
               ref={errorLabelRef}
               className={cx(classes.errorLabelContainer, classes[size])}
            >
               <p className={cx(classes[position || 'left'])}>{text}</p>
            </div>
         )}
      </>
   );
};

export default ErrorLabel;
