import React, { useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import Button from '../../ui/button/Button';
import ActiveServicesModal from '../../modal/activeServicesModal/ActiveServicesModal';
import { selectAppLayout } from '../../../store/selectors';

const ActiveServicesSection = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const {
      buttonActiveServicesLabel,
      activeServiceModalTitle,
      activeServiceModalDescription,
   } = useSelector(selectAppLayout, shallowEqual);

   return (
      <section className="active-service-section">
         <div className="active-service-description">
            <h1 className={cx('main-title')}>
               {activeServiceModalTitle ||
                  '#### No localization for param activeServiceModalTitle ####'}
            </h1>
            <p>
               {activeServiceModalDescription ||
                  '#### No localization for param activeServiceModalDescription ####'}
            </p>
            <Button
               title={
                  buttonActiveServicesLabel ||
                  '#### No localization for param buttonActiveServicesLabel ####'
               }
               className="active-service-button"
               // onClick={() => history.push(`/service/${serviceKey}`)}
               onClick={() => setIsModalOpen(true)}
            />
         </div>

         <ActiveServicesModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
         />
      </section>
   );
};

export default ActiveServicesSection;
