import cx from 'classnames';

interface ServiceTitleProps {
   serviceTitle?: string;
   className?: string;
}
const ServiceDescriptionTitle = ({ serviceTitle, className }: ServiceTitleProps) => {
   return <h1 className={cx('service-desc-title', className)}>{serviceTitle}</h1>;
};

export default ServiceDescriptionTitle;
