import React from 'react';
import { useParams } from 'react-router';
import Services from '../../components/services/Services';
import { RouteServiceDetailsParams } from '../../types/globals';

const CategoryBanner = () => {
   const { category } = useParams<RouteServiceDetailsParams>();
   return (
      <Services
         fetchByCategory={category?.toUpperCase()}
         showCategoryFilterFlag={false}
         fullWidth
      />
   );
};

export default CategoryBanner;
