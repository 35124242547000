import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import cx from 'classnames';
import Col from '../../../ui/layouts/col/Col';
import PricingCard, {
   PricingCardProps,
   SelectedCardProps,
} from '../../../form/pricingCard/PricingCard';
import {
   selectServicesState,
   selectAppLayout,
   selectSingleServiceState,
} from '../../../../store/selectors';

interface PricingFormSectionProps extends Pick<PricingCardProps, 'clickable'> {
   pricingState?: SelectedCardProps | null;
   setPricingState?: (e: any) => void;
   resetErrorAction?: () => void;
   errorMsgLabel?: any;
   descriptionLabel?: string;
}

const PricingFormSection = ({
   pricingState,
   setPricingState,
   resetErrorAction,
   errorMsgLabel,
   descriptionLabel,
   ...props
}: PricingFormSectionProps) => {
   const pricingRef = useRef<HTMLDivElement | null>(null);

   const [isRTL, setIsRTL] = useState(false);
   const { domain, packageNameLabelOneService, priceLabelOneService } =
      useSelector(selectAppLayout);
   const isAlmadarDomain =
      domain === 'stage-almadar.centili.com' || domain === 'almadar-store.ly';

   const {
      packages,
      emptyPackagesErrorMessage,
      packagesHeaderLabel,
      paymentMethods,
      hidePackagesAmount,
   } = useSelector(selectSingleServiceState, shallowEqual);

   const { choosePackagesOneService, emptyPackagesOneService, packageNoteLabel } =
      useSelector(selectAppLayout, shallowEqual);
   const { selectedPaymentMethodId } = useSelector(
      selectServicesState,
      shallowEqual,
   );

   const hasMultiplePaymentMethods = paymentMethods?.length > 1;

   const checkDirection = () => {
      const htmlElement = document?.documentElement;
      const { direction } = htmlElement?.style;

      if (direction === 'rtl') {
         setIsRTL(true);
      } else {
         setIsRTL(false);
      }
   };

   useEffect(() => {
      checkDirection();
      if (pricingRef && pricingRef.current && errorMsgLabel?.package) {
         pricingRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
         });
      }
   }, [errorMsgLabel, pricingRef]);

   const pricingClickHandler = (pricingParam: SelectedCardProps) => {
      if (pricingParam.id === pricingState?.id) {
         setPricingState?.(null);
         return;
      }
      setPricingState?.(pricingParam);
      resetErrorAction?.();
   };
   return (
      <Col
         className="pricing-col"
         ref={pricingRef}
         validationError={errorMsgLabel?.packages}
         heading={
            packages && packages.length === 0
               ? emptyPackagesErrorMessage ||
                 emptyPackagesOneService ||
                 '#### No localization for param emptyPackagesErrorMessage ####'
               : packagesHeaderLabel ||
                 choosePackagesOneService ||
                 '#### No localization for param choosePackagesOneService ####'
         }
         stepCount="2"
         completed={!!pricingState}
      >
         <h3 className="pricing-bottom-label">
            {hasMultiplePaymentMethods && descriptionLabel}
         </h3>
         {/* TODO - localization for Almadar */}
         {isAlmadarDomain && (
            <div className={cx('package-title-wrap', isRTL && 'rtl')}>
               <h4 className=" package-title">
                  {packageNameLabelOneService ||
                     '#### No localization for param packageNameLabelOneService ####'}
               </h4>
               <div className="price-title-col">
                  <h4 className="price-title">
                     {priceLabelOneService ||
                        '#### No localization for param priceLabelOneService ####'}
                  </h4>
               </div>
            </div>
         )}
         {packages &&
            packages.length !== 0 &&
            packages.map((singlePackage, index) => {
               /*
                *Multiple payment methods
                *Find the selected method id, and show pricing explicitly for selected payment method(MOBILE,MBON...)
                */
               const selectedPaymentMethod = singlePackage.paymentMethods.find(
                  (method) => method.id === selectedPaymentMethodId,
               );

               return (
                  <PricingCard
                     {...props}
                     index={index}
                     key={singlePackage.uniqueReference}
                     amount={singlePackage.amount}
                     unit={singlePackage.unit}
                     packageOption={
                        singlePackage.amount !== null
                           ? `${!hidePackagesAmount ? singlePackage.amount : ''} ${
                                singlePackage.unit
                             }`
                           : singlePackage.interval
                     }
                     price={`${
                        (hasMultiplePaymentMethods &&
                           selectedPaymentMethod?.price) ||
                        (!hasMultiplePaymentMethods &&
                           singlePackage?.paymentMethods?.[0].price) ||
                        ''
                     }`}
                     oldPrice={`${
                        (hasMultiplePaymentMethods &&
                           selectedPaymentMethod?.oldPrice) ||
                        (!hasMultiplePaymentMethods &&
                           singlePackage?.paymentMethods?.[0]?.oldPrice) ||
                        ''
                     }`}
                     currency={
                        (hasMultiplePaymentMethods &&
                           selectedPaymentMethod?.currency) ||
                        (!hasMultiplePaymentMethods &&
                           singlePackage?.paymentMethods?.[0].currency) ||
                        ''
                     }
                     checked={pricingState?.id || ''}
                     id={singlePackage.uniqueReference.toString() || ''}
                     onClick={pricingClickHandler}
                     merchantToken={selectedPaymentMethod?.merchantToken || ''}
                     merchantReference={selectedPaymentMethod?.merchantReference}
                     voucherPackageReference={selectedPaymentMethod?.reference}
                     currencyCode={selectedPaymentMethod?.currencyCode}
                     paymentPackageId={selectedPaymentMethod?.id}
                     disabled={singlePackage.disabled}
                  />
               );
            })}
         {errorMsgLabel?.packages && (
            <p className="error-label packages">{errorMsgLabel.packages}</p>
         )}
         {packageNoteLabel && (
            <p className="package-note-label">{packageNoteLabel}</p>
         )}
      </Col>
   );
};

export default PricingFormSection;
