import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';

type BurgerProps = {
   children: React.ReactNode;
};
const Hamburger = ({ children }: BurgerProps) => {
   const [isOpen, setIsOpen] = useState(false);

   return (
      <div style={{ display: 'none' }} id="outer-burger-container">
         <Menu
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            isOpen={isOpen}
         >
            {children}
         </Menu>
      </div>
   );
};

export default Hamburger;
