import { forwardRef, useEffect, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import classes from './PricingCard.module.scss';
import RadioButton from '../../ui/button/radioButton/RadioButton';
import { selectAppLayout } from '../../../store/selectors';

export interface SelectedCardProps {
   price: string;
   merchantReference?: string | null;
   reference?: string | null;
   merchantToken: string;
   id?: string;
   paymentPackageId?: string;
   amount: number;
   unit: string;
   currencyCode?: string | null;
}
export interface PricingCardProps {
   clickable?: boolean;
   checked: string;
   packageOption: string;
   price: string;
   oldPrice: string | null;
   currency: string;
   onClick: (selectedParams: SelectedCardProps) => void;
   merchantReference?: string | null;
   merchantToken: string;
   voucherPackageReference?: string | null;
   id?: string;
   paymentPackageId?: string;
   amount: number;
   unit: string;
   currencyCode?: string | null;
   index: number;
   disabled?: boolean;
}

const PricingCard = forwardRef<any, PricingCardProps>(
   (
      {
         clickable = true,
         checked,
         packageOption,
         oldPrice,
         price,
         currency,
         onClick,
         merchantReference,
         voucherPackageReference,
         id,
         merchantToken,
         paymentPackageId,
         amount,
         unit,
         currencyCode,
         index,
         disabled = false,
      },
      ref,
   ) => {
      const selectedParams = {
         oldPrice,
         price,
         merchantReference,
         voucherPackageReference,
         merchantToken,
         id,
         paymentPackageId,
         amount,
         unit,
         currencyCode,
      };
      const {
         priceLocale,
         priceMaximumFractionDigits,
         priceMinimumFractionDigits,
         usersLogin,
         disabledPackageLabel,
         disableInvalidPackages,
      } = useSelector(selectAppLayout, shallowEqual);
      const { keycloak } = useKeycloak();

      const [isRTL, setIsRTL] = useState(false);

      const isUserNeededAndNotLogged = usersLogin && !keycloak.authenticated;

      const priceString = Number(price).toLocaleString(
         priceLocale != null ? priceLocale : 'en-US',
         {
            maximumFractionDigits: priceMaximumFractionDigits,
            minimumFractionDigits: priceMinimumFractionDigits,
         },
      );

      const checkDirection = () => {
         const htmlElement = document?.documentElement;
         const { direction } = htmlElement.style;

         if (direction === 'rtl') {
            setIsRTL(true);
         } else {
            setIsRTL(false);
         }
      };

      useEffect(() => {
         checkDirection();
      }, []);

      return (
         <div
            id={index.toString()}
            ref={ref}
            tabIndex={0}
            role="button"
            aria-labelledby="button-label"
            onClick={() => onClick(selectedParams)}
            className={cx(
               'pricing-card',
               isRTL && 'rtl-pricing-card',
               checked === id && 'active-pricing-card',
               isUserNeededAndNotLogged && 'preview-card',
               !price && 'disabled',
               classes.pricingCard,
               disableInvalidPackages && disabled && 'disabled-package',
               {
                  [classes.pricingDataUncheckable]: !clickable,
               },
               'pricing-card-custom',
            )}
         >
            {clickable && (
               <div className={cx('radio-btn-wrap')}>
                  <RadioButton checked={checked === id} />
               </div>
            )}

            <div
               className={cx(classes.pricingDataWrap, 'pricing-data-wrap', {
                  [classes.pricingDataUncheckable]: !clickable,
               })}
            >
               <p
                  className={cx(
                     'pricing-package-title',
                     classes.pricingPackage,
                     {
                        [classes.pricingDataUncheckable]: !clickable,
                     },
                     'pricing-package-title-custom',
                  )}
               >
                  {packageOption}
                  {disableInvalidPackages && disabled && disabledPackageLabel && (
                     <p className="disabled-package-label">
                        <span>{disabledPackageLabel}</span>
                     </p>
                  )}
               </p>

               {oldPrice && (
                  <p className={cx('price-value', 'old-price-value')}>
                     {oldPrice}{' '}
                     <span className={cx('currency-value', classes.priceValue)}>
                        {currency}
                     </span>
                  </p>
               )}

               <p className={cx('price-value', classes.priceValue)}>
                  {priceString}{' '}
                  <span className={cx('currency-value', classes.priceValue)}>
                     {currency}
                  </span>
               </p>
            </div>
         </div>
      );
   },
);
PricingCard.displayName = 'PricingCard';

export default PricingCard;
