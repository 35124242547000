import React, { forwardRef } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import StepProgress, { StepProgressProps } from '../../stepProgress/StepProgress';
import classes from './Col.module.scss';
import { selectAppLayout } from '../../../../store/selectors';

export interface ColProps extends StepProgressProps {
   className?: string;
   children: React.ReactNode;
   heading?: any;
   validationError?: boolean;
}

const Col = forwardRef<HTMLDivElement, ColProps>(
   (
      {
         className,
         children,
         heading,
         stepCount = '',
         completed,
         validationError = false,
      },
      ref,
   ) => {
      const { includeStepCount } = useSelector(selectAppLayout);
      return (
         <div
            ref={ref}
            className={cx(
               'col',
               classes.col,
               { [classes.error]: validationError },
               className,
            )}
         >
            {includeStepCount && (
               <StepProgress stepCount={stepCount} completed={completed} />
            )}
            {heading && <h3 className="col-heading">{heading}</h3>}
            {children}
         </div>
      );
   },
);
Col.displayName = 'Col';
export default Col;
