import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Routes } from '../utils/constants';
import CategoryBanner from '../pages/categoryBannerPage/CategoryBanner';

const BannerRoutes = () => {
   return (
      <Switch>
         <Route
            exact
            path={Routes.SERVICE_CATEGORY_BANNER_ROUTE}
            component={CategoryBanner}
         />
      </Switch>
   );
};

export default BannerRoutes;
