/* eslint-disable import/no-cycle */
import GlobalErrorIcon from '../assets/img/something-wrong.png';
import ForbiddenIcon from '../assets/img/forbidden.png';
import NotFoundIcon from '../assets/img/not-found.png';
import SF1Loader from '../assets/img/loader.gif';
import OTPLoader from '../assets/img/otp-loader.gif';
import MegastoreLoader from '../assets/img/megastore-loader.gif';
import PluxeeLoader from '../assets/img/spinner.svg';
import AlmadarLoader from '../assets/img/almadar-loader.gif';
import { ErrorPagesContentProps } from '../types/globals';
import { BoxLoader } from '../components/ui/loaders/loaders/Loaders';
import { ServicesState } from '../store/services/servicesTypes';
import { UserState } from '../store/user/userTypes';

// REDUX INITIAL STATES
export const APP_INITIAL_STATE = {
   auth: {
      data: '',
   },
   states: {
      isDarkModeActive: false,
      isAppReady: false,
      isCSSReady: false,
      isError: false,
   },
   layout: {},
};
export const USER_INITIAL_STATE: UserState = {
   balance: {
      keycloakId: '',
      amount: 0,
      currency: '',
      storefrontCode: '',
   },
   balanceHistory: [],
   isLoading: false,
   activeServices: {
      isLoading: false,
      services: [],
      consent: null,
      errorMessage: '',
   },
};

export const SERVICES_INITIAL_STATE: ServicesState = {
   states: {
      isLoading: false,
      isServicesReady: false,
      selectedPaymentMethodId: 111,
   },
   search: '',
   validation: {
      input: {
         isLoading: false,
         isInputValidated: null,
         errorMessage: '',
      },
      paymentMethodInput: {
         isLoading: false,
         isInputValidated: null,
         errorMessage: '',
      },
      phone: {
         isLoading: false,
         isInputValidated: null,
         errorMessage: '',
      },
      form: {
         isLoading: false,
         onSubmitValidated: false,
         errorMessage: '',
      },
      pin: {
         isPinLoading: false,
         pinTimerActive: false,
         isPinSent: false,
      },
      error: {
         message: '',
      },
   },

   all: [],

   displayedServices: {
      selectedCategory: 'all',
      services: [],
   },
   active: {
      activeServices: {
         pinId: '',
         errorMessage: '',
         all: [],
         phoneNo: '',
         email: '',
      },
      activeServicesConsent: null,
      consentStatus: {
         isConsentDisableLoading: false,
         isConsentDisabled: false,
      },
   },
   single: {},
   transaction: {},
};

export const PRELOADER = [
   {
      type: 'mts',
      loader: SF1Loader,
      class: 'red',
   },
   {
      type: 'a1',
      loader: BoxLoader,
      class: 'black',
   },
   {
      type: 'otp',
      loader: OTPLoader,
      class: 'black',
   },
   {
      type: 'megastore',
      loader: MegastoreLoader,
      class: 'black',
   },
   {
      type: 'pluxee',
      loader: PluxeeLoader,
      class: 'black',
   },
   {
      type: 'almadar',
      loader: AlmadarLoader,
      class: 'black',
   },
];

// MEDIA QUERY SLIDER BREAKPOINTS
export const BIG_SLIDER_BREAKPOINTS = {
   '320': {
      slidesPerView: 1,
      spaceBetween: 20,
   },
   '640': {
      slidesPerView: 2,
      spaceBetween: 20,
   },
   '900': {
      slidesPerView: 2,
      spaceBetween: 40,
   },
   '1200': {
      slidesPerView: 3,
      spaceBetween: 50,
   },
   '1700': {
      slidesPerView: 4,
      spaceBetween: 50,
   },
};

export const BIG_SLIDER_BREAKPOINTS_THUMBNAILS = {
   '320': {
      slidesPerView: 3,
      spaceBetween: 20,
   },
   '640': {
      slidesPerView: 4,
      spaceBetween: 20,
   },
   '900': {
      slidesPerView: 4,
      spaceBetween: 40,
   },
   '1200': {
      slidesPerView: 4,
      spaceBetween: 50,
   },
   '1700': {
      slidesPerView: 4,
      spaceBetween: 50,
   },
};

export const SMALL_SLIDER_BREAKPOINTS = {
   '290': {
      slidesPerView: 2,
      spaceBetween: 20,
   },
   '640': {
      slidesPerView: 3,
      spaceBetween: 20,
   },
   '900': {
      slidesPerView: 4,
      spaceBetween: 40,
   },
   '1400': {
      slidesPerView: 5,
      spaceBetween: 50,
   },
   '1700': {
      slidesPerView: 6,
      spaceBetween: 50,
   },
};

// Routes path
export enum Routes {
   HOME_ROUTE = '/',
   SERVICE_ROUTE = '/service/:key',
   SERVICE_DETAILS_ROUTE = '/:serviceName',
   SERVICE_CATEGORY_BANNER_ROUTE = '/banner/:category',
   ERROR_ROUTE = '/error/:status',
   GLOBAL_ERROR_ROUTE = '/error/401',
   ACTIVE_SERVICES_ROUTE = '/active-services',
   SEP_ROUTE = '/sep',
   USER_PROFILE_ROUTE = '/profile',
}

export const PAYMENT_METHODS = ['cc', 'crypto', 'dcb', 'wallet'];

export const ERROR_PAGES_CONTENT: ErrorPagesContentProps = {
   '400': {
      errorIcon: GlobalErrorIcon,
      errorTitle: 'Bad Request',
   },
   '401': {
      errorIcon: ForbiddenIcon,
      errorTitle: 'Access Denied/Forbidden',
   },
   '403': {
      errorIcon: ForbiddenIcon,
      errorTitle: 'Access Denied/Forbidden',
   },
   '404': {
      errorIcon: NotFoundIcon,
      errorTitle: 'Page not found',
   },

   '408': {
      errorIcon: ForbiddenIcon,
      errorTitle: 'Request Timeout',
   },

   '500': {
      errorIcon: GlobalErrorIcon,
      errorTitle: 'Internal Server Error 500',
   },
   '502': {
      errorIcon: GlobalErrorIcon,
      errorTitle: 'Internal Server Error',
   },
   '503': {
      errorIcon: GlobalErrorIcon,
      errorTitle: 'Internal Server Error',
   },
};

// REGEX
export const EMAIL_REGEX = /\S+@\S+\.\S+/;
export const PHONE_REGEX = /3816\d{7,8}$/;

export const INPUT_LABEL_TYPE = {
   movingLabel: 'MOVING_LABEL',
   topLabel: 'TOP_LABEL',
};

export const CATEGORY_NAME_TYPE = {
   tag: 'TAG',
   title: 'TITLE',
   none: 'NONE',
};
export const TABS_NAME = {
   MSISDN: 'MSISDN',
   EMAIL: 'EMAIL',
};

export const ANALYTICS_INPUT_PARAMS = {
   msisdn: 'msisdn',
   email: 'email',
};

const USER_ID = 'User-Id';
export const SESSION_ID = 'Session-Id';
export const TOKEN_ID = 'Token-Id';
export const LOGIN_ATTEMPT = 'Login-attempt';
export const SEP_REDIRECTION_LINK = 'Sep-Redirection-Link';
export const REGISTRATION_STARTED = 'Registration-Started';
export const LOGIN_SUCCESSFUL = 'Login-Successful';
export const DEFAULT_LANGUAGE = 'default-language';

export const BROWSER_STORAGE_KEYS = {
   localStorage: {
      userId: USER_ID,
      selectedLanguage: '',
      token: TOKEN_ID,
      sepRedirectionLink: SEP_REDIRECTION_LINK,
      registrationStarted: REGISTRATION_STARTED,
      loginSuccessfull: LOGIN_SUCCESSFUL,
      defaultLanguage: DEFAULT_LANGUAGE,
   },
   cookies: {
      sessionId: SESSION_ID,
      loginAttempt: LOGIN_ATTEMPT,
   },
};

export const MEDIA_QUERY_BREAKPOINTS = {
   phone: '648px',
   laptop: '1200px',
   desktop: '1500px',
} as const;

export const MODAL_QUERY_KEY = 'modal_key';
export const CATEGORY_QUERY_KEY = 'category';

export const TRANSACTION_STATUS = {
   FINISHED: 'FINISHED',
   PENDING: 'PENDING',
   FAILED: 'FAILED',
} as const;

export const DOMAINS = {
   sodexo: 'play.pluxee.uy',
   marketspace: 'marketspace.centili.com',
} as const;
