import parse from 'html-react-parser';

type ConsentProps = {
   label: string;
   checked: boolean;
   onCheck: (checked: boolean) => void;
} & React.HTMLProps<HTMLDivElement>;

const Consent = ({ label, checked, onCheck, ...rest }: ConsentProps) => {
   return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className="consent-container">
         <div className="checkbox-wrap">
            <input
               id={rest.id}
               className="checkbox-consent"
               type="checkbox"
               checked={checked}
               onChange={() => {
                  onCheck(!checked);
               }}
            />
            {parse(label)}
         </div>
      </label>
   );
};

export default Consent;
