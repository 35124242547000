import { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import {
   getSingleServiceAction,
   getAllServicesAction,
} from '../../../store/services/servicesAction';
import { useLocalStorage, useSingleServiceQuery } from '../../../utils/hooks';
import Button from '../../../components/ui/button/Button';
import ServiceDescription from '../../../components/services/serviceDescription/ServiceDescription';
import {
   selectSingleServiceState,
   selectAppLayout,
   selectServicesState,
   selectAllServices,
} from '../../../store/selectors';
import classes from '../ServicePage.module.scss';
import GoBackButton from '../../../components/ui/button/goBackButton/GoBackButton';
import Animation from '../../../components/ui/animation/Animation';
import Preloader from '../../../components/ui/loaders/preloader/Preloader';
import PricingFormSection from '../../../components/services/singleService/pricingFormSection/PricingFormSection';
import {
   DynamicObjValuesProp,
   RedirectToSepUrlProps,
   RouteServiceDetailsParams,
   SepAnalytics,
} from '../../../types/globals';
import { callAAnalyticsService } from '../../../api/service';
import {
   BROWSER_STORAGE_KEYS,
   CATEGORY_QUERY_KEY,
   SESSION_ID,
} from '../../../utils/constants';
import ServiceDescriptionText from '../../../components/services/serviceDescription/ServiceDescriptionText';

type Cookie = Record<typeof SESSION_ID, any>;

export interface ServiceDetailsState {
   serviceKey: string;
   categoryCode: string;
}

const ServiceDetailPage = () => {
   // Route param
   const { state } = useLocation<ServiceDetailsState>();
   const [cookie] = useCookies([BROWSER_STORAGE_KEYS.cookies.sessionId]);
   const [localStorageValue] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.userId,
      '',
   );
   const [localStorageSelectedLanguage] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.selectedLanguage,
      '',
   );
   const { serviceName } = useParams<RouteServiceDetailsParams>();
   const category = new URLSearchParams(useLocation().search).get(
      CATEGORY_QUERY_KEY,
   );

   const dispatch = useDispatch();
   const history = useHistory();
   const allServices = useSelector(selectAllServices);
   const serviceQueryParams = useSingleServiceQuery(
      state?.serviceKey,
      category || '',
   );
   const {
      image,
      name,
      description,
      helpURL,
      termsAndConditionsURL,
      activateURL,
      serviceKey,
      disabled,
   } = useSelector(selectSingleServiceState, shallowEqual);
   const {
      domain,
      defaultLanguage,
      buttonActivateLabel,
      serviceHelpLabel,
      serviceTermsAndConditionsLabel,
      serviceTermsAndConditionsURLLabel,
      stickyHeader,
      code,
      defaultCountry,
      disableBuyButton,
   } = useSelector(selectAppLayout, shallowEqual);
   const { isLoading } = useSelector(selectServicesState, shallowEqual);

   useEffect(() => {
      if (allServices.length !== 0) return;
      const servicesQueryParams = {
         language: defaultLanguage,
         domain,
      };
      dispatch(getAllServicesAction(servicesQueryParams));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (serviceName === name?.replaceAll(' ', '') && disabled) {
         history.push('/');
      }
   }, [disabled, name]);

   // EFFECTS
   useEffect(() => {
      if (allServices.length === 0) return;
      if (serviceQueryParams.serviceKey) {
         dispatch(getSingleServiceAction(serviceQueryParams));
         return;
      }

      let serviceKey = '';
      allServices?.forEach((service) => {
         return service.services.forEach((name) => {
            const isServicePresent = name.name.replaceAll(' ', '') === serviceName;
            if (isServicePresent) {
               serviceKey = name.serviceKey;
            }
         });
      });

      const serviceParams = {
         ...serviceQueryParams,
         serviceKey,
         language:
            localStorageSelectedLanguage !== ''
               ? localStorageSelectedLanguage
               : defaultLanguage,
      };

      dispatch(getSingleServiceAction(serviceParams));

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [allServices]);

   const createSepQueryParams = () => {
      const analyticParam: SepAnalytics = {
         sessionId: (cookie as Cookie)['Session-Id'],
         userId: localStorageValue,
      };

      const sepPropsValues: RedirectToSepUrlProps | DynamicObjValuesProp = {
         domain: window.location.origin.replace(/https?:\/\//i, ''),
         // domain: 'stage-oodi.centili.com', FOR TESTING
         apikey: serviceKey,
         activateUrl: activateURL,
         storefrontCode: code,
         country: defaultCountry,
         language: localStorageSelectedLanguage || defaultLanguage || 'en',
         ...analyticParam,
      };
      return queryString.stringify(sepPropsValues);
   };

   const activateServiceDetailsHandler = () => {
      // *SEP*
      const queryForSep = createSepQueryParams();
      //* WHEN VALIDATION IS **NOT** PRESENT ON BACKEND */
      // If validation requirements are missing from backend, redirect directly to the SEP page, without validation methods
      callAAnalyticsService(queryForSep);
      window.location.href = activateURL != null ? activateURL : '';
   };

   if (isLoading) {
      return <Preloader />;
   }
   return (
      <section>
         {!stickyHeader && <GoBackButton />}
         <Animation animationType="page" className={classes.servicePageWrap}>
            <ServiceDescription
               imageSrc={image}
               description={description}
               serviceTitle={name}
               helpLinkLabel={serviceHelpLabel}
               helpLinkUrl={helpURL}
               tosLabel={serviceTermsAndConditionsLabel}
               tosUrlLabel={serviceTermsAndConditionsURLLabel}
               tosUrl={termsAndConditionsURL}
            />
            <div className={cx('service-form', classes.serviceForm)}>
               <PricingFormSection clickable={false} />
               <Button
                  title={
                     buttonActivateLabel ||
                     '#### No localization for param buttonActivateLabel ####'
                  }
                  onClick={activateServiceDetailsHandler}
                  className="activate-service-button"
                  disabled={!!disableBuyButton}
               />
               <ServiceDescriptionText
                  className="service-desc-text-mobile"
                  description={description}
               />
            </div>
         </Animation>
      </section>
   );
};

export default ServiceDetailPage;
