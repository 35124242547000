import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { selectAuthState, selectAppLayout } from '../store/selectors';
import { setDarkMode } from '../store/app/appAction';
import {
   BROWSER_STORAGE_KEYS,
   ERROR_PAGES_CONTENT,
   MEDIA_QUERY_BREAKPOINTS,
   MODAL_QUERY_KEY,
   CATEGORY_QUERY_KEY,
} from './constants';
import { ErrorRouteServiceParams } from '../types/globals';

export const useThemeDetector = () => {
   const dispatch = useDispatch();
   const isColorSchemeDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
   ).matches;
   const [isDarkTheme, setIsDarkTheme] = useState(isColorSchemeDark);
   useEffect(() => {
      dispatch(setDarkMode(isColorSchemeDark));
      const theme = isColorSchemeDark ? 'dark' : 'light';
      document.documentElement.setAttribute('data-theme', theme);
      setIsDarkTheme(isColorSchemeDark);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isColorSchemeDark]);

   return { isDarkTheme };
};

export const useErrorStatus = () => {
   const { status } = useParams<ErrorRouteServiceParams>();
   return ERROR_PAGES_CONTENT[status];
};
export const useSingleServiceQuery = (
   serviceKey: string | undefined,
   categoryCode: string | undefined,
) => {
   const selectedLanguage = localStorage.getItem(
      BROWSER_STORAGE_KEYS.localStorage.selectedLanguage,
   );

   const { domain, defaultCountry, defaultLanguage } = useSelector(
      selectAppLayout,
      shallowEqual,
   );

   const { data } = useSelector(selectAuthState, shallowEqual);
   return {
      country: defaultCountry,
      domain,
      data,
      serviceKey,
      categoryCode,
      language: selectedLanguage
         ? selectedLanguage?.replaceAll('"', '')
         : defaultLanguage,
   };
};

export const useSticky = (stickyRef: any) => {
   const [isMoved, setIsMoved] = useState(false);

   useEffect(() => {
      if (!stickyRef) return;

      const onScroll = () => {
         if (window.scrollY > 10) {
            setIsMoved(true);
         } else {
            setIsMoved(false);
         }
      };
      window.addEventListener('scroll', onScroll, true);

      return () => {
         window.removeEventListener('scroll', onScroll, true);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return {
      isMoved,
   };
};
// Hook
export const useLocalStorage = <T>(key: string, initialValue: T) => {
   const [localStorageValue, setValue] = useState<T>(() => {
      if (typeof window === 'undefined') {
         return initialValue;
      }
      try {
         const item = window.localStorage.getItem(key);
         return item ? JSON.parse(item) : initialValue;
      } catch (error) {
         console.error(error);
         return initialValue;
      }
   });
   const setLocalStorageValue = (value: T | ((val: T) => T)) => {
      try {
         const valueToStore =
            value instanceof Function ? value(localStorageValue) : value;
         setValue(valueToStore);
         if (typeof window !== 'undefined') {
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
         }
      } catch (error) {
         console.error(error);
      }
   };
   return [localStorageValue, setLocalStorageValue] as const;
};

type BreakpointKeys = keyof typeof MEDIA_QUERY_BREAKPOINTS;
type BreakpointValues = typeof MEDIA_QUERY_BREAKPOINTS[BreakpointKeys];
type BreakpointsType = `(max-width: ${BreakpointValues})`;

export const useMediaQuery = (breakpoint: BreakpointsType) => {
   const getMatches = (query: string): boolean => {
      // Prevents SSR issues
      if (typeof window !== 'undefined') {
         return window.matchMedia(query).matches;
      }
      return false;
   };
   const [matches, setMatches] = useState(getMatches(breakpoint));
   useEffect(() => {
      const matchQueryList = window.matchMedia(breakpoint);
      function handleChange(e: any) {
         setMatches(e.matches);
      }
      matchQueryList.addEventListener('change', handleChange);
      return () => {
         matchQueryList.removeEventListener('change', handleChange);
      };
   }, [breakpoint]);
   return matches;
};

export const useModalQueryParam = () => {
   const history = useHistory();
   const { search, pathname } = useLocation();

   const addQueryParamOnModalClick = useCallback(
      (serviceKey: string, categoryCode: string) => {
         const queryParams = new URLSearchParams(search);
         queryParams.set(MODAL_QUERY_KEY, serviceKey);
         queryParams.set(CATEGORY_QUERY_KEY, categoryCode);
         history.replace({
            pathname,
            search: queryParams.toString(),
         });
      },
      [history, pathname, search],
   );

   return { addQueryParamOnModalClick } as const;
};

export const useCheckDomain = (domainForMatch: string) => {
   const { domain } = useSelector(selectAppLayout, shallowEqual);

   return domain === domainForMatch;
};
