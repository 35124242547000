/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import { useCookies } from 'react-cookie';
import { useLocalStorage, useSticky } from '../../../utils/hooks';
import classes from './StickyNavbar.module.scss';
import { selectAppLayout, selectSingleServiceState } from '../../../store/selectors';
import { BROWSER_STORAGE_KEYS, Routes } from '../../../utils/constants';
import Hamburger from './Hamburger';
import {
   sendChatbotClickedAnalyticActions,
   sendRegisterAnalyticActions,
   sendSignInAnalyticActions,
} from '../../../store/app/appAction';
import UserHeaderProfile from '../userHeaderProfile/UserHeaderProfile';
import ActiveServicesModal from '../../modal/activeServicesModal/ActiveServicesModal';
import Button from '../../ui/button/Button';

const StickyNavbar = () => {
   const location = useLocation();
   const singleService = useSelector(selectSingleServiceState);
   const helpUrlfromService = singleService.helpURL;
   const navRef = useRef<any>(null);
   const { isMoved } = useSticky(navRef);
   const {
      logoUrl,
      helpUrl,
      backButtonLabel,
      tosLabel,
      tosUrl,
      helpLabel,
      contactUrl,
      contactLabel,
      languageCodes,
      usersLogin,
      domain,
      activeServicesHeaderButtonLabel,
      buttonSignInLabel,
   } = useSelector(selectAppLayout);
   const dispatch = useDispatch();

   const [, setLoginAttempt] = useCookies([
      BROWSER_STORAGE_KEYS.cookies.loginAttempt,
   ]);

   const [localStorageValue, setLocalStorageValue] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.selectedLanguage,
      '',
   );
   const [localStorageLoginSuccessful, setLocalStorageLoginSuccessful] =
      useLocalStorage(BROWSER_STORAGE_KEYS.localStorage.loginSuccessfull, false);
   const [localStorageRegistrationStarted, setLocalStorageRegistrationStarted] =
      useLocalStorage(BROWSER_STORAGE_KEYS.localStorage.registrationStarted, false);
   const { defaultLanguage } = useSelector(selectAppLayout, shallowEqual);
   const direction = defaultLanguage === 'ar' ? 'rtl' : 'ltr';
   const html = document.querySelector('html');
   if (localStorageValue !== '') {
      html!.style.direction = localStorageValue === 'ar' ? 'rtl' : 'ltr';
   } else {
      html!.style.direction = direction;
   }
   const allanguages = languageCodes?.length > 1 ? languageCodes?.split(',') : [];
   const [isRTL, setIsRTL] = useState('');
   const { keycloak } = useKeycloak();

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const setLanguage = (language: string) => () => {
      setIsRTL(language);
      setLocalStorageValue(language);
      /* const serviceParams = {
         ...queryParams,
         language,
      };
      dispatch(getInitialLayoutConfigAction(serviceParams));

      dispatch(getAllServicesAction(serviceParams)); */
      window.location.reload();
   };

   const keycloakRegister = () => {
      dispatch(sendRegisterAnalyticActions(domain));
      setLocalStorageRegistrationStarted(true);
      keycloak.register();
   };

   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      const html = document.querySelector('html');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      html!.style.direction = isRTL === 'ar' ? 'rtl' : 'ltr';
   }, [isRTL]);

   useEffect(() => {
      if (keycloak?.authenticated) {
         setLoginAttempt(BROWSER_STORAGE_KEYS.cookies.loginAttempt, true);
      }
   }, [keycloak.authenticated, setLoginAttempt]);

   useEffect(() => {
      if (keycloak.authenticated) {
         if (!localStorageLoginSuccessful) {
            setLocalStorageLoginSuccessful(true);
            const keycloakId = keycloak.subject || '';
            dispatch(sendSignInAnalyticActions({ domain, keycloakId }));
         }

         if (localStorageRegistrationStarted) {
            setLocalStorageRegistrationStarted(false);
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const element = document.getElementById('ai-button');
      const keycloakId = keycloak.subject || '';

      if (element) {
         const handleClick = () => {
            dispatch(sendChatbotClickedAnalyticActions({ domain, keycloakId }));
         };

         element.addEventListener('click', handleClick);

         return () => {
            element.removeEventListener('click', handleClick);
         };
      }
   }, [dispatch, domain, keycloak.subject]);

   return (
      <>
         {allanguages.length > 1 && domain === 'stage-oodi.centili.com' && (
            <div className="languages-tab">
               {allanguages.map((language, index) => {
                  // eslint-disable-next-line react/jsx-key
                  return (
                     // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                     <span
                        role="button"
                        key={`${language}_${index}`}
                        className="language-item"
                        onClick={setLanguage(language)}
                     >
                        {language.toUpperCase()}
                     </span>
                  );
               })}
            </div>
         )}
         <nav
            className={cx(classes.sticky, 'sticky-navbar', {
               [classes.scrolled]: isMoved,
            })}
            ref={navRef}
         >
            <Hamburger>
               {contactUrl && (
                  <a className="menu-link tos" href={contactUrl}>
                     {contactLabel}
                  </a>
               )}
               {helpUrl && (
                  <a className="menu-link help" href={helpUrl}>
                     {helpLabel || 'Help'}
                  </a>
               )}
               {tosUrl && (
                  <a className="menu-link contact" href={tosUrl}>
                     {tosLabel || 'T'}
                  </a>
               )}
            </Hamburger>

            <div className={cx(classes.content)}>
               {/* Redirects to external link */}
               {helpUrlfromService && (
                  <a href={helpUrlfromService}>
                     <img alt="redirect" src={logoUrl} />
                  </a>
               )}

               {/* Redirects to homepage of app - SPA flow */}
               {!helpUrlfromService && (
                  <Link to={Routes.HOME_ROUTE}>
                     <img className="link-to-home" alt="home" src={logoUrl} />
                  </Link>
               )}
               {allanguages.length > 1 &&
                  (domain === 'stage-almadar.centili.com' ||
                     domain === 'almadar-store.ly') &&
                  location.pathname !== Routes.SEP_ROUTE && (
                     <div className="languages-tab">
                        <select onChange={(e) => setLanguage(e.target.value)()}>
                           {allanguages.map((language, index) => (
                              <option
                                 key={`${language}_${index}`}
                                 value={language}
                                 selected={
                                    localStorageValue
                                       ? language === localStorageValue
                                       : language === defaultLanguage
                                 }
                              >
                                 {language.toUpperCase()}
                              </option>
                           ))}
                        </select>
                     </div>
                  )}

               <div className="menu-links-wrap">
                  {contactUrl && (
                     <a className="menu-link tos" href={contactUrl}>
                        {contactLabel}
                     </a>
                  )}
                  {helpUrl && (
                     <a className="menu-link help" href={helpUrl}>
                        {helpLabel || 'Help'}
                     </a>
                  )}
                  {tosUrl && (
                     <a className="menu-link contact" href={tosUrl}>
                        {tosLabel || 'T'}
                     </a>
                  )}
                  {usersLogin && !keycloak.authenticated && (
                     <button
                        type="button"
                        className="register-button"
                        onClick={() => keycloakRegister()}
                     >
                        Register
                     </button>
                  )}

                  {usersLogin && !keycloak.authenticated && (
                     <button
                        type="button"
                        className="login-button"
                        onClick={() => keycloak.login()}
                     >
                        {buttonSignInLabel}
                     </button>
                  )}
                  {usersLogin && keycloak.authenticated && <UserHeaderProfile />}
                  {Routes.HOME_ROUTE !== location.pathname && (
                     <Link to={Routes.HOME_ROUTE} className="back-btn">
                        <button
                           name="back"
                           type="button"
                           className={cx(classes.goBackHeaderLink)}
                        >
                           {backButtonLabel}
                        </button>
                     </Link>
                  )}
                  {(domain === 'stage-almadar.centili.com' ||
                     domain === 'almadar-store.ly') && (
                     <Button
                        title={
                           activeServicesHeaderButtonLabel ||
                           '### No localization found for activeServicesHeaderButtonLabel ###'
                        }
                        className="active-service-button-header"
                        // onClick={() => history.push(`/service/${serviceKey}`)}
                        onClick={() => setIsModalOpen(true)}
                     />
                  )}
               </div>
            </div>
         </nav>
         <ActiveServicesModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            setIsOpen={() => {
               setIsModalOpen(false);
            }}
         />
      </>
   );
};

export default StickyNavbar;
