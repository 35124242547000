/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import queryString from 'query-string';
import { useDebouncedCallback } from 'use-debounce';
import { useCookies } from 'react-cookie';
/* The above code is importing the `useKeycloak` hook from the `@react-keycloak/web` library. This hook
is used to integrate Keycloak authentication and authorization into a React application. */
import { useKeycloak } from '@react-keycloak/web';
import { SelectedCardProps } from '../../components/form/pricingCard/PricingCard';
import classes from './ServicePage.module.scss';
import Col from '../../components/ui/layouts/col/Col';
import ServiceDescription from '../../components/services/serviceDescription/ServiceDescription';
import Button from '../../components/ui/button/Button';
import Animation from '../../components/ui/animation/Animation';
import Preloader from '../../components/ui/loaders/preloader/Preloader';
import GoBackButton from '../../components/ui/button/goBackButton/GoBackButton';
import {
   EmailValues,
   RedirectToSepUrlProps,
   RouteServiceParams,
   DynamicObjValuesProp,
   SepAnalytics,
} from '../../types/globals';
import { redirectToSepPageUrl } from '../../api/service';
import {
   getSingleServiceAction,
   validateInputAction,
   validateAndSubmitFormAction,
   resetValidationAction,
   resetValidationErrorMessageAction,
   validatePaymentMethodInputAction,
   resetPaymentMethodValidation,
   selectPaymentMethodForPackagesAction,
} from '../../store/services/servicesAction';
import {
   selectServicesState,
   selectAppLayout,
   selectSingleServiceState,
   selectObjectFromParams,
   selectValidationState,
   selectPaymentObjectFromParams,
   selectFormLoader,
} from '../../store/selectors';
import {
   ifFirstColValidated,
   hasInputsPopulated,
   getDomainFromUrl,
   getDataFromUrl,
   findSelectedPaymentMethodParams,
} from '../../utils/helpers';
import PricingFormSection from '../../components/services/singleService/pricingFormSection/PricingFormSection';
import EmailSection from '../../components/services/singleService/emailFormSection/emailSection/EmailSection';
import DynamicInputSection from '../../components/services/singleService/emailFormSection/dynamicInputSection/DynamicInputSection';
import ErrorLabel from '../../components/ui/errorLabel/ErrorLabel';
import PaymentMethodsFormSection, {
   PaymentInputErrorLabels,
} from '../../components/services/singleService/paymentMethodsFormSection/PaymentMethodsFormSection';

import {
   PaymentMethod,
   PaymentMethodParam,
} from '../../store/services/servicesTypes';
import { useSingleServiceQuery, useLocalStorage } from '../../utils/hooks';
import {
   BROWSER_STORAGE_KEYS,
   CATEGORY_QUERY_KEY,
   SESSION_ID,
} from '../../utils/constants';
import ServiceDescriptionText from '../../components/services/serviceDescription/ServiceDescriptionText';
import Consent from '../../components/ui/consent/Consent';

type Cookie = Record<typeof SESSION_ID, any>;
type ConsentsState = {
   paymentConsent: boolean;
   contactConsent: boolean;
};
const ServicePage = () => {
   const [cookie] = useCookies([BROWSER_STORAGE_KEYS.cookies.sessionId]);
   const [localStorageValue] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.userId,
      '',
   );

   const { keycloak } = useKeycloak();

   const { key } = useParams<RouteServiceParams>();
   const category = new URLSearchParams(useLocation().search).get(
      CATEGORY_QUERY_KEY,
   );
   const dispatch = useDispatch();
   const history = useHistory();
   const serviceQueryParams = useSingleServiceQuery(key, category || '');
   const ref = useRef<HTMLElement | null>(null);

   // APP STATE
   const paramsObjectValues = useSelector(selectObjectFromParams);
   const paymentParamsObjectValues = useSelector(selectPaymentObjectFromParams);
   const formSubmitted = useSelector(selectFormLoader);

   const {
      code,
      defaultLanguage,
      serviceHelpLabel,
      serviceTermsAndConditionsLabel,
      serviceTermsAndConditionsURLLabel,
      stickyHeader,
      buttonActivateLabel,
      mandatoryData,
      activeMomentsApi,
      momentsApiKey,
      contactConsent,
      contactConsentLabel,
      paymentConsent,
      paymentConsentLabel,
      contactConsentDefaultValue,
      paymentConsentDefaultValue,
      importMerchantDetailsHeader,
      usersLogin,
      virtualCurrencyCode,
      domain,
      disableBuyButton,
   } = useSelector(selectAppLayout, shallowEqual);
   const { isLoading } = useSelector(selectServicesState, shallowEqual);
   const { input, phone, form, paymentMethodInput } = useSelector(
      selectValidationState,
      shallowEqual,
   );
   const {
      image,
      name,
      description,
      packages,
      validators,
      helpURL,
      serviceKey,
      termsAndConditionsURL,
      emailLabel,
      activateURL,
      disabled,
   } = useSelector(selectSingleServiceState, shallowEqual);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [localStorageSelectedLanguage] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.selectedLanguage,
      '',
   );
   const [, setLoginAttempt] = useCookies([
      BROWSER_STORAGE_KEYS.cookies.loginAttempt,
   ]);
   // LOCAL STATE
   const [consents, setConsents] = useState<ConsentsState>({
      contactConsent: !!contactConsentDefaultValue,
      paymentConsent: !!paymentConsentDefaultValue,
   });
   const [isUserTyping, setIsUserTyping] = useState(false);
   const [hasSelectedFromDropdown, setHasSelectedFromDropdown] = useState(false);
   // Dynamic fields
   const [dynamicVal, setDynamicVal] = useState<DynamicObjValuesProp>({});
   // Input values
   const [pricingValue, setPricingValue] = useState<SelectedCardProps | null>(null);
   const [emailValue, setEmailValue] = useState<EmailValues>({
      selectedEmail: null,
      // newEmail: keycloak.authenticated ? keycloak.tokenParsed?.email : '',
      newEmail: '',
   });

   // Validation
   const [isEmailValidated, setIsEmailValidated] = useState<boolean | null>(null);
   const [errorMessages, setErrorMessages] = useState<DynamicObjValuesProp>({});
   const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
      PaymentMethod | Record<string, any>
   >({});

   const isUserNeededAndNotLogged = usersLogin && !keycloak.authenticated;
   const isAlmadarDomain =
      domain === 'almadar-store.ly' || domain === 'stage-almadar.centili.com';

   useEffect(() => {
      if (key === serviceKey && disabled) {
         history.push('/');
      }
   }, [disabled, serviceKey]);

   // EFFECTS
   useEffect(() => {
      if (keycloak.authenticated) {
         setLoginAttempt(BROWSER_STORAGE_KEYS.cookies.loginAttempt, true);
         setEmailValue({ ...emailValue, newEmail: keycloak.tokenParsed?.email });
      }
      dispatch(getSingleServiceAction(serviceQueryParams));
      dispatch(resetValidationErrorMessageAction());
      if (momentsApiKey && activeMomentsApi) {
         (window as any)?.pe?.track('serviceopen', { servicekey: key });
      }
      return () => {
         dispatch(resetValidationAction());
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [keycloak.authenticated]);

   useEffect(() => {
      /*
         Loops through selected payment method(default payment method is set in PaymentMethodsFormSection component).
         Main selected package has payment methods array with all the inputs, and inputs name are same as our local obj key.
         It iterate through that array, and find the inputs name that are selected, and add them to the local DYNAMIC STATE.
         In that way, it won't send all the payment methods input to error validation, it will only have state with inputs from selected payment method.
      */
      if (selectedPaymentMethod && selectedPaymentMethod?.paymentMethodParams) {
         selectedPaymentMethod.paymentMethodParams.forEach(
            (method: PaymentMethodParam) => {
               if (
                  paymentParamsObjectValues &&
                  method.name in paymentParamsObjectValues
               ) {
                  Object.keys(paymentParamsObjectValues)?.forEach((el: any) => {
                     if (el in dynamicVal) {
                        delete dynamicVal[el];
                     }
                  });

                  setDynamicVal(() => {
                     return {
                        ...paramsObjectValues,
                        [method.name]: paymentParamsObjectValues[method.name],
                     };
                  });
               }
               return null;
            },
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [paramsObjectValues, paymentParamsObjectValues, selectedPaymentMethod]);

   useEffect(() => {
      // Allow to click on activate when the input of payment method is not present - blank payment method
      if (selectedPaymentMethod.paymentMethodParams === null) {
         setDynamicVal({});
      }

      // Keep and update price for selected pricing option when changing payment method.
      if (!pricingValue) return;

      const selectedPackage = packages
         .filter((packageId) => packageId.uniqueReference === pricingValue?.id)
         .reduce((_, packages) => {
            const selectedPayment = packages.paymentMethods.find(
               (paymentMethodPrice) =>
                  paymentMethodPrice.id === selectedPaymentMethod?.id,
            );

            return {
               ...pricingValue,
               price: selectedPayment?.price,
               merchantReference: selectedPayment?.merchantReference,
               voucherPackageReference: selectedPayment?.reference,
               currencyCode: selectedPayment?.currencyCode,
               merchantToken: selectedPayment?.merchantToken,
               paymentPackageId: selectedPayment?.id,
            };
         }, {}) as SelectedCardProps;

      // Deselect already selected price package on payment method change, if that payment method doesn't contain price package
      if (!selectedPackage.price) {
         setPricingValue(null);
         return;
      }
      setPricingValue(selectedPackage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedPaymentMethod]);

   // LOGIC
   const resetErrorMessagesOnFocusHandler = () => {
      dispatch(resetValidationErrorMessageAction());
      const errors = {
         email: '',
         packages: '',
      };
      setErrorMessages(errors);
   };

   const debouncedRegexValidation = useDebouncedCallback(
      (paramName, regex?: string, prefix?: string | undefined) => {
         if (!regex) return;

         const valueWithPrefix = prefix + dynamicVal[paramName];
         const inputValue = prefix ? valueWithPrefix : dynamicVal[paramName];

         const isRegexValid = new RegExp(regex).test(inputValue);
         if (!isRegexValid) {
            const selectedPaymentMethod = findSelectedPaymentMethodParams(paramName);
            setErrorMessages({
               ...errorMessages,
               [`dynamic${paramName}`]:
                  selectedPaymentMethod?.warnInvalidInputFormat ??
                  '### REGEX NOT PASSED - PAYMENT METHODS REGEX LABEL MISSING###',
            });
         } else {
            /*
               If regex validation, passed, return empty value prop, with key inside
               It's important for overriding errors obj in Service Page(if we return just a {}, it wont update key in obj when destructing)
             */
            setErrorMessages({
               ...errorMessages,
               [`dynamic${paramName}`]: '',
            });
         }
         setIsUserTyping(false);
      },

      1000,
   );

   const debouncedValidationInputHandler = useDebouncedCallback(
      (paramName: string) => {
         if (!validators) return;

         validators.some((validationParams) => {
            if (
               validationParams?.params.length === 1 &&
               validationParams?.params[0] === paramName
            ) {
               const validationUrl = `${validationParams.url}?language=${defaultLanguage}&storefrontCode=${code}`;
               dispatch(
                  validateInputAction(validationUrl, {
                     [paramName]: dynamicVal[paramName],
                  }),
               );
            }

            return null;
         });
         setIsUserTyping(false);
      },
      1000,
   );

   // PAYMENT METHOD VALIDATION
   const debouncedValidationPaymentInputHandler = useDebouncedCallback(
      (
         paramName: string,
         validationUrl: string,
         errorLabels: PaymentInputErrorLabels,
         prefix?: string | undefined,
      ) => {
         // break if validation URL is empty
         if (!validationUrl) return;

         const valueWithPrefix = prefix + dynamicVal[paramName];
         const inputValue = prefix ? valueWithPrefix : dynamicVal[paramName];
         const validationUrlWithQuery = `${validationUrl}?${paramName}=${inputValue}`;
         dispatch(
            validatePaymentMethodInputAction(validationUrlWithQuery, errorLabels),
         );
         setIsUserTyping(false);
      },
      1000,
   );

   // Queries
   const emailSepQueryParams = () => {
      return (selectedPaymentMethod as PaymentMethod).emailSepParams?.reduce(
         (acc: Record<string, string>, emailQueryParam) => {
            acc[emailQueryParam] =
               emailValue.selectedEmail?.value ?? emailValue.newEmail;
            return acc;
         },
         {},
      );
   };

   const paymentSepQueryParams = () => {
      if ((selectedPaymentMethod as PaymentMethod).paymentMethodParams) {
         return (selectedPaymentMethod as PaymentMethod).paymentMethodParams.reduce(
            (acc: Record<string, string>, paymentMethodParams) => {
               paymentMethodParams.sepParams.forEach((paramQueryParam) => {
                  if (paymentMethodParams.name in dynamicVal) {
                     acc[paramQueryParam] = dynamicVal[paymentMethodParams.name];
                  }
               });
               return acc;
            },
            {},
         );
      }
   };

   const createSepQueryParams = () => {
      let dynamicValWithPrefix: Record<string, string> = dynamicVal;
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!selectedPaymentMethod.paymentMethodParams) {
         // eslint-disable-next-line no-restricted-syntax
         for (const method of (selectedPaymentMethod as PaymentMethod)
            .paymentMethodParams) {
            if (method.name in dynamicVal && method.prefix) {
               dynamicValWithPrefix = {
                  ...dynamicVal,
                  [method.name]: method.prefix + dynamicVal[method.name],
               };
            }
         }
      }
      const failedReturnUrl = `${getDomainFromUrl()}/?data=${getDataFromUrl()}`;
      // Payment method reference - Select dynamic  payment input value  and copy value to the packageMethodReference param
      const selectedPaymentName = (selectedPaymentMethod as PaymentMethod)
         .paymentMethodParams?.[0].name;
      const paymentMethodReference = dynamicVal[selectedPaymentName];

      const paymentMethodType = (selectedPaymentMethod as PaymentMethod).type;

      const analyticParam: SepAnalytics = {
         sessionId: (cookie as Cookie)['Session-Id'],
         userId: localStorageValue,
         packageAmount: pricingValue?.amount || 0,
         packageUnit: pricingValue?.unit || '',
         paymentMethodType,
         paymentMethodReference,
      };

      const sepPropsValues: RedirectToSepUrlProps | DynamicObjValuesProp = {
         domain: window.location.origin.replace(/https?:\/\//i, ''),
         apikey: serviceKey,
         activateUrl: activateURL,
         price: virtualCurrencyCode ? 0 : pricingValue?.price,
         ...(pricingValue?.merchantReference && {
            packageReference: pricingValue?.merchantReference,
         }),
         ...(pricingValue?.reference && {
            voucherPackageReference: pricingValue?.reference,
         }),
         merchantToken: pricingValue?.merchantToken,
         storefrontCode: code,
         country: selectedPaymentMethod.countryCode,
         ...(selectedPaymentMethod.operatorCode && {
            operator: selectedPaymentMethod.operatorCode,
         }),
         ...(selectedPaymentMethod.providerCode && {
            providerCode: selectedPaymentMethod.providerCode,
         }),
         language: localStorageSelectedLanguage || defaultLanguage || 'en',
         ...(hasSelectedFromDropdown && { forceWap: true }),
         ...(mandatoryData && { failedreturnurl: failedReturnUrl }),
         dynamicParams: queryString.stringify({
            ...emailSepQueryParams(),
            ...paymentSepQueryParams(),
            ...dynamicValWithPrefix,
         }),
         ...analyticParam,
         ...(selectedPaymentMethod.paymentAppContext && {
            paymentAppContext: selectedPaymentMethod.paymentAppContext,
            currency: pricingValue?.currencyCode,
         }),
         ...(consents.paymentConsent && { activeConsent: consents.paymentConsent }),
         ...(consents.contactConsent && { contactConsent: consents.contactConsent }),
         ...(virtualCurrencyCode && { virtualCurrency: virtualCurrencyCode }),
         ...(virtualCurrencyCode && { virtualPrice: pricingValue?.price }),
      };

      return queryString.stringify(sepPropsValues);
   };

   const activateServiceHandler = async () => {
      const queryForSep = createSepQueryParams();

      if (isUserNeededAndNotLogged) {
         keycloak.login();
         return;
      }

      let dynamicValWithPrefix: Record<string, string> = dynamicVal;
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!selectedPaymentMethod.paymentMethodParams) {
         // eslint-disable-next-line no-restricted-syntax
         for (const method of (selectedPaymentMethod as PaymentMethod)
            .paymentMethodParams) {
            if (method.name in dynamicVal && method.prefix) {
               dynamicValWithPrefix = {
                  ...dynamicVal,
                  [method.name]: method.prefix + dynamicVal[method.name],
               };
            }
         }
      }

      // * Validation before submit - empty fields
      const errorInputMessages = hasInputsPopulated({
         dynamicInput: dynamicValWithPrefix,
         email: emailValue,
         packages: pricingValue?.price || '',
      });
      setErrorMessages(errorInputMessages);

      // ! If inputs not validated, forbid the redirect to the SEP
      const ifInputsHasBeenValidated =
         input.errorMessage.length > 0 ||
         phone.errorMessage.length > 0 ||
         paymentMethodInput.errorMessage.length > 0;
      if (
         Object.values(errorInputMessages).filter(Boolean).length !== 0 ||
         ifInputsHasBeenValidated
      ) {
         // Displays error labels on each input which didn't pass validation
         return;
      }
      const validatorsProps = validators.filter(
         (validationType) => validationType.trigger === 'onsubmit',
      );
      const hasSubmitHandler = !!validatorsProps.length;

      // Validation endpoint for POST request
      const validationUrl = `${validatorsProps[0]?.url}?language=${defaultLanguage}&storefrontCode=${code}`;

      // Create obj for POST SUBMIT validation method
      const validationParams = validatorsProps[0]?.params.reduce((acc, item) => {
         acc[item] =
            // eslint-disable-next-line no-nested-ternary
            item === 'email'
               ? dynamicVal.email
               : item === 'package'
               ? pricingValue?.merchantReference
               : item;

         return acc;
      }, {});

      // *SEP*
      if (!isUserNeededAndNotLogged && virtualCurrencyCode) {
         await redirectToSepPageUrl(queryForSep, false, keycloak.token);
         return;
      }

      //* WHEN VALIDATION IS **NOT** PRESENT ON BACKEND */
      // If validation requirements are missing from backend, redirect directly to the SEP page, without validation methods
      if (!validators || validators.length === 0) {
         if (momentsApiKey && activeMomentsApi) {
            const selectedPaymentName = (selectedPaymentMethod as PaymentMethod)
               .paymentMethodParams?.[0].name;
            const paymentMethodReference = dynamicVal[selectedPaymentName];

            const paymentMethodType = (selectedPaymentMethod as PaymentMethod).type;
            try {
               (window as any)?.pe.setPerson(
                  {
                     ...(paymentMethodType === 'MOBILE' && {
                        phone: paymentMethodReference,
                     }),
                     email: emailValue.selectedEmail?.value ?? emailValue.newEmail,
                  },
                  31536000,
               );
            } catch (error: any) {
               (window as any)?.pe.updatePerson({
                  ...(paymentMethodType === 'MOBILE' && {
                     phone: paymentMethodReference,
                  }),
                  email: emailValue.selectedEmail?.value ?? emailValue.newEmail,
               });
            }
         }

         await redirectToSepPageUrl(queryForSep, false, keycloak.token);
         return;
      }

      dispatch(
         validateAndSubmitFormAction(
            hasSubmitHandler,
            validationUrl,
            validationParams,
            queryForSep,
            false,
            keycloak.token,
         ),
      );
   };

   if (isLoading) {
      return <Preloader />;
   }

   return (
      <section id="service-page-wrap" ref={ref}>
         <Toaster position="bottom-center" reverseOrder={false} />
         {(!stickyHeader || isAlmadarDomain) && <GoBackButton />}
         <Animation animationType="page" className={classes.servicePageWrap}>
            <ServiceDescription
               imageSrc={image}
               description={description}
               serviceTitle={name}
               helpLinkLabel={serviceHelpLabel}
               helpLinkUrl={helpURL}
               tosLabel={serviceTermsAndConditionsLabel}
               tosUrlLabel={serviceTermsAndConditionsURLLabel}
               tosUrl={termsAndConditionsURL}
            />
            <div className={cx('service-form', classes.serviceForm)}>
               {!isUserNeededAndNotLogged &&
                  window.location.hostname !== 'almadar-store.ly' && (
                     <Col
                        heading={importMerchantDetailsHeader}
                        stepCount="1"
                        completed={
                           ifFirstColValidated(
                              dynamicVal,
                              input.isInputValidated,
                              emailValue,
                           ) && isEmailValidated
                        }
                     >
                        <DynamicInputSection
                           dynamicState={dynamicVal}
                           setDynamicState={setDynamicVal}
                           errorMsgLabel={errorMessages}
                           debouncedValidationInputHandler={
                              debouncedValidationInputHandler
                           }
                           debouncedValidationRegex={debouncedRegexValidation}
                        />
                        <EmailSection
                           emailState={emailValue}
                           setEmailState={setEmailValue}
                           resetErrorAction={resetErrorMessagesOnFocusHandler}
                           setEmailValidatedState={setIsEmailValidated}
                           errorMsgLabel={errorMessages}
                           emailSingleServiceLabel={emailLabel}
                        />
                     </Col>
                  )}
               <PricingFormSection
                  errorMsgLabel={errorMessages}
                  pricingState={pricingValue}
                  setPricingState={setPricingValue}
                  resetErrorAction={resetErrorMessagesOnFocusHandler}
                  descriptionLabel={selectedPaymentMethod.descriptionLabel}
               />

               <PaymentMethodsFormSection
                  hidden={!!virtualCurrencyCode}
                  isUserTyping={setIsUserTyping}
                  dynamicPaymentMethodState={dynamicVal}
                  setDynamicPaymentMethodState={setDynamicVal}
                  selectedPaymentMethod={selectedPaymentMethod as PaymentMethod}
                  onSelectPaymentClick={(method) => {
                     setSelectedPaymentMethod(method);
                     dispatch(selectPaymentMethodForPackagesAction(method.id));
                  }}
                  debouncedValidationPaymentInputHandler={
                     debouncedValidationPaymentInputHandler
                  }
                  debouncedValidationRegex={debouncedRegexValidation}
                  clearInputsCallback={() => {
                     /*
                     When changing payment method, resets dynamic payment method inputs to empty string
                     paymentParamsObjectValues is obj with input name params, so desctruturing them in the obj reset that params to ''
                       */
                     setDynamicVal({
                        ...dynamicVal,
                        ...paymentParamsObjectValues,
                     });
                     resetErrorMessagesOnFocusHandler();
                     dispatch(resetPaymentMethodValidation());
                  }}
                  // Validation props
                  resetErrorAction={resetErrorMessagesOnFocusHandler}
                  errorMsgLabel={errorMessages}
                  setSelectedFromDropdownState={setHasSelectedFromDropdown}
               />

               {paymentConsent && (
                  <Consent
                     id="consent-1"
                     label={paymentConsentLabel || '###Payment consent missing'}
                     checked={consents.paymentConsent}
                     onCheck={() => {
                        setConsents((prevState) => ({
                           ...prevState,
                           paymentConsent: !prevState.paymentConsent,
                        }));
                     }}
                  />
               )}
               {contactConsent && (
                  <Consent
                     id="consent-2"
                     label={contactConsentLabel || '###Content consent missing'}
                     checked={consents.contactConsent}
                     onCheck={() => {
                        setConsents((prevState) => ({
                           ...prevState,
                           contactConsent: !prevState.contactConsent,
                        }));
                     }}
                  />
               )}
               {/* {paymentConsent && domain === 'almadar-store.ly' && (
                  <div>
                     <p>
                        <a
                           href={consentTermsAndConditionsUrl}
                           target="_blank"
                           rel="noreferrer"
                        >
                           {consentTermsAndConditionsLabel}
                        </a>
                     </p>
                  </div>
               )} */}

               <Button
                  disabled={
                     disableBuyButton ||
                     (paymentConsent && !consents.paymentConsent) ||
                     isUserTyping ||
                     (packages && packages.length === 0) ||
                     formSubmitted
                  }
                  title={
                     isUserNeededAndNotLogged
                        ? 'Sign in to continue'
                        : buttonActivateLabel ||
                          '#### No localization for param buttonActivateLabel ####'
                  }
                  loading={formSubmitted}
                  onClick={activateServiceHandler}
                  className="activate-service-button"
                  name="activate"
               />
               <ErrorLabel
                  isVisible={!!form.errorMessage}
                  text={form.errorMessage}
                  size="medium"
               />
               <ServiceDescriptionText
                  className="service-desc-text-mobile"
                  description={description}
               />
            </div>
         </Animation>
      </section>
   );
};

export default ServicePage;
