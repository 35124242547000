import Modal, { Props } from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useDebouncedCallback } from 'use-debounce/lib';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router';
import classes from './ActiveServicesModal.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/img/icon-close.svg';
import Input from '../../form/input/Input';
import ErrorLabel from '../../ui/errorLabel/ErrorLabel';
import {
   isPhoneInCorrectFormat,
   validateInputForRegex,
} from '../../../utils/helpers';
import InputPin from '../../form/input/pinInput/InputPin';
import SideMenu from '../../ui/sideMenu/SideMenu';
import {
   selectAppLayout,
   selectServicesState,
   selectValidationState,
   selectActiveServicesState,
   selectAuthState,
} from '../../../store/selectors';
import {
   getPinCodeAction,
   getActivatedServicesAction,
   validatePhoneAction,
   resetPhoneValidation,
   resetErrorsOnActivateServicesModal,
   resetPinValidationAction,
   resetPinTimer,
} from '../../../store/services/servicesAction';
import Preloader from '../../ui/loaders/preloader/Preloader';
import { BROWSER_STORAGE_KEYS, TABS_NAME } from '../../../utils/constants';
import { iFrameResizer } from '../../ui/scrollToTop/ScrollToTop';
import { useLocalStorage } from '../../../utils/hooks';

type ModalProps = {
   setIsOpen?: () => void;
} & Props;

const ActiveServicesModal = ({
   isOpen,
   setIsOpen,
   onRequestClose,
   ...rest
}: ModalProps) => {
   const dispatch = useDispatch();
   const location = useLocation();
   const pathSegments = location.pathname.split('/');
   const activeService = pathSegments[pathSegments.length - 1];

   // ? Use userRef because it doesn't cause re-render of components when content changes
   const counterRef = useRef<any>(null);
   const clear = () => {
      window.clearInterval(counterRef.current);
   };
   const modalRef = useRef<any>(null);

   // ? Used for storing current value of counter for showing in View
   const [clearPin, setClearPin] = useState(false);
   const [phoneValue, setPhoneValue] = useState('');
   const [mailValue, setMailValue] = useState('');
   const [isPhoneFormatValidated, setIsPhoneFormatValidated] = useState('');

   // App state
   const { data } = useSelector(selectAuthState, shallowEqual);
   const { isLoading } = useSelector(selectServicesState, shallowEqual);
   const {
      domain,
      defaultLanguage,
      phoneNumberPlaceHolderLabel,
      phoneNumberLabel,
      importPhoneNumberLabel,
      importEmailLabel,
      hlrMsisdnValidator,
      pinModalPhoneNumberMessageLabel,
      pinModalEmailMessageLabel,
      resendPinLabel,
      enableResendPinSeconds,
      useActiveServicesComponent,
      activeServiceModalOptions,
      phoneNumberModalTabHeader,
      activeServicesEmailInputLabel,
      activeServiceEmailLabel,
      seconds,
   } = useSelector(selectAppLayout, shallowEqual);
   const { pin, phone } = useSelector(selectValidationState, shallowEqual);
   const { pinId, errorMessage } = useSelector(
      selectActiveServicesState,
      shallowEqual,
   );
   const [counterValue, setCounterValue] = useState<number>(
      enableResendPinSeconds || 60,
   );

   const { isPinLoading, isPinSent, pinTimerActive } = pin;

   //* Validation for input fields (email / phone number)
   const isEmailValid = validateInputForRegex(false, mailValue);
   const isPhoneRangeValid = validateInputForRegex(true, phoneValue);
   // EFFECTS
   // Reset pin action on back btn.Remove focus from PIN input to phone input
   useEffect(() => {
      dispatch(resetPinValidationAction());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      setClearPin(false);
      if (!pinTimerActive) {
         return;
      }
      counterRef.current = window.setInterval(() => {
         setCounterValue((prevCounterValue) => prevCounterValue - 1);
      }, 1000);
   }, [pinTimerActive]);

   useEffect(() => {
      if (activeService === 'active-services') {
         setIsOpen?.();
      }
   }, [activeService, location]);

   useEffect(() => {
      if (counterValue === 0) {
         dispatch(resetPinTimer());
         setCounterValue(enableResendPinSeconds || 60);
         clear();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [counterValue, dispatch]);

   const debouncedValidationPhoneHandler = useDebouncedCallback(
      (phoneNumber: string) => {
         const phoneHasInvalidFormat = isPhoneInCorrectFormat(phoneNumber);
         if (phoneHasInvalidFormat) {
            setIsPhoneFormatValidated(phoneHasInvalidFormat);
            return;
         }

         setIsPhoneFormatValidated('');

         if (
            phoneNumber.length > 8 &&
            !phoneHasInvalidFormat &&
            hlrMsisdnValidator
         ) {
            dispatch(validatePhoneAction(hlrMsisdnValidator, phoneNumber));
            setIsPhoneFormatValidated(phoneHasInvalidFormat);
         }
      },
      600,
   );
   // LOGIC
   const onPhoneNumberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPhoneValue(e.target.value);

      // Dispatch validation only if phone number has correct range
      if (e.target.value.length > 8) {
         debouncedValidationPhoneHandler(e.target.value);
      }

      // Reset error message when clears input
      if (e.target.value.length === 0) {
         dispatch(resetPhoneValidation());
         setIsPhoneFormatValidated('');
      }
   };

   const onAfterCloseHandler = () => {
      dispatch(resetErrorsOnActivateServicesModal());
   };

   const onCloseHandler = (
      event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent,
   ) => {
      // * Clear counter on modal Close
      dispatch(resetPhoneValidation());
      dispatch(resetErrorsOnActivateServicesModal());
      dispatch(resetPinValidationAction());
      setPhoneValue('');
      setMailValue('');
      setIsPhoneFormatValidated('');
      onRequestClose?.(event);
   };

   const [localStorageSelectedLanguage] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.selectedLanguage,
      '',
   );

   const submitPinHandler = () => {
      dispatch(resetErrorsOnActivateServicesModal());

      const pinPostObj = {
         msisdn: phoneValue,
         language: localStorageSelectedLanguage || defaultLanguage,
         email: mailValue,
      };
      dispatch(getPinCodeAction(domain, pinPostObj));
      console.log('Klik setIs');
   };

   const onPinCompleteHandler = (pin: string) => {
      const queryForActiveServices = queryString.stringify({
         pinId,
         pin,
         msisdn: phoneValue,
         domain,
         data,
         email: mailValue,
         language: localStorageSelectedLanguage || defaultLanguage,
      });
      dispatch(getActivatedServicesAction(queryForActiveServices));
   };

   const isSendPinCodeDisabled = () => {
      return phone.isInputValidated === false || !isPhoneRangeValid;
   };

   const switchTabsHandler = () => {
      dispatch(resetErrorsOnActivateServicesModal());
      setPhoneValue('');
      setMailValue('');
      setClearPin(true);
   };

   // * Awesome way for conditional rendering of components in react
   const ModalType =
      useActiveServicesComponent === 'RIGHT_SLIDER' ? SideMenu : Modal;

   // TABS COMPONENT
   // Tabs
   const EmailTab = (
      <Tab key={uuidv4()}>
         {activeServiceEmailLabel ||
            '#### No localization for param activeServiceEmailLabel ####'}
      </Tab>
   );
   const PhoneTab = (
      <Tab key={uuidv4()}>
         {phoneNumberModalTabHeader ||
            '#### No localization for param phoneNumberModalTabHeader ####'}
      </Tab>
   );

   const inputChanged = (val: string) => {
      setMailValue(val);
      dispatch(resetPinValidationAction());
   };

   // Panels
   const EmailTabPanel = (
      <TabPanel key={uuidv4()}>
         <h2>
            {importEmailLabel ||
               '#### No localization for param importEmailLabel ####'}
         </h2>
         <div className={classes.tabsInputWrap}>
            <Input
               className="email-pin"
               disabled={pinTimerActive || counterValue === 0}
               isSendCodeButtonVisible={!isPinLoading}
               isLoading={isPinLoading}
               autoFocus={!isPinSent}
               labelText={
                  activeServicesEmailInputLabel ||
                  '#### No localization for param activeServicesEmailInputLabel ####'
               }
               name="mail"
               type="email"
               placeholder="example@email.com"
               isSendCodeButtonDisabled={!isEmailValid}
               value={mailValue}
               onChange={(e) => inputChanged(e.target.value)}
               submitSendCodeButton={submitPinHandler}
            />

            {counterValue && counterValue > 0 && pinTimerActive && (
               <strong>
                  {resendPinLabel ||
                     '#### No localization for param resendPinLabel ####'}{' '}
                  {`${counterValue} ${seconds}`}
               </strong>
            )}
            <p>
               {parse(
                  pinModalEmailMessageLabel ||
                     '#### No localization for param pinModalEmailMessageLabel ####',
               )}
            </p>
         </div>

         <ErrorLabel
            position="center"
            isVisible={!!errorMessage}
            size="small"
            text={errorMessage}
         />
      </TabPanel>
   );

   const PhoneTabPanel = (
      <TabPanel key={uuidv4()}>
         <h2>
            {importPhoneNumberLabel ||
               '#### No localization for param importPhoneNumberLabel ####'}
         </h2>
         <div className={classes.tabsInputWrap}>
            <Input
               className="phone-pin"
               disabled={pinTimerActive || counterValue === 0}
               validationError={!!isPhoneFormatValidated || !!phone.errorMessage}
               validationText={isPhoneFormatValidated || phone.errorMessage}
               // isSendCodeButtonVisible={!phone.isLoading}
               isSendCodeButtonVisible={!phone.isLoading && !isPinLoading}
               isSendCodeButtonDisabled={isSendPinCodeDisabled()}
               isLoading={phone.isLoading || isPinLoading}
               autoFocus
               labelText={
                  phoneNumberLabel ||
                  '#### No localization for param phoneNumberLabel ####'
               }
               name="phone"
               type="number"
               placeholder={
                  phoneNumberPlaceHolderLabel ||
                  '#### No localization for param phoneNumberPlaceHolderLabel ####'
               }
               value={phoneValue}
               onChange={onPhoneNumberChangeHandler}
               submitSendCodeButton={submitPinHandler}
            />
            {counterValue && counterValue > 0 && pinTimerActive && (
               <strong>
                  {resendPinLabel ||
                     '#### No localization for param resendPinLabel ####'}{' '}
                  {`${counterValue} ${seconds}`}
               </strong>
            )}

            <p>
               {parse(
                  pinModalPhoneNumberMessageLabel ||
                     '#### No localization for param pinModalPhoneNumberMessageLabel ####',
               )}
            </p>
         </div>

         <ErrorLabel
            position="center"
            isVisible={!!errorMessage}
            size="small"
            text={errorMessage}
         />
      </TabPanel>
   );

   const renderTabsOrder = () => {
      const tabsTitle: React.ReactElement[] = [];
      const tabsPanel: React.ReactElement[] = [];

      if (activeServiceModalOptions && activeServiceModalOptions.length > 0) {
         activeServiceModalOptions.forEach((_, i) => {
            if (activeServiceModalOptions[i] === TABS_NAME.EMAIL) {
               tabsTitle.push(EmailTab);
               tabsPanel.push(EmailTabPanel);
            }

            if (activeServiceModalOptions[i] === TABS_NAME.MSISDN) {
               tabsTitle.push(PhoneTab);
               tabsPanel.push(PhoneTabPanel);
            }
         });
      }

      return { tabsTitle, tabsPanel };
   };

   return (
      <>
         <ModalType
            contentRef={(node) => {
               modalRef.current = node;

               if (modalRef && modalRef.current) {
                  modalRef?.current.scrollIntoView({
                     behavior: 'smooth',
                     block: 'end',
                  });
                  const rect = modalRef?.current.getBoundingClientRect();
                  if ('parentIFrame' in window) {
                     (window as unknown as iFrameResizer).parentIFrame.scrollTo(
                        rect.x,
                        rect.y,
                     );
                  }
               }
            }}
            onOverlayClick={onCloseHandler}
            {...rest}
            ariaHideApp={false}
            closeTimeoutMS={100}
            className={
               useActiveServicesComponent === 'RIGHT_SLIDER'
                  ? ''
                  : 'modal view-active-services-modal'
            }
            style={{
               overlay: {
                  backgroundColor: '#00000080',
                  zIndex: 1000,
               },
            }}
            isOpen={isOpen}
            shouldCloseOnOverlayClick
            onRequestClose={onCloseHandler}
            onAfterClose={onAfterCloseHandler}
            contentLabel="Add/Edit Modal"
         >
            <CloseIcon
               className={classes.closeModalIcon}
               onClick={onCloseHandler}
               fill="black"
            />

            <div className={classes.modalContentWrap}>
               <Tabs onSelect={switchTabsHandler}>
                  <TabList className={classes.tabsHeader}>
                     {renderTabsOrder().tabsTitle}
                  </TabList>

                  <div className={classes.tabsContent}>
                     {renderTabsOrder().tabsPanel}
                  </div>
               </Tabs>
            </div>
            <div className="clear-pin-container">
               <InputPin
                  clearPin={clearPin}
                  onComplete={onPinCompleteHandler}
                  focus={isPinSent}
                  disabled={!isPinSent}
               />
            </div>
         </ModalType>

         {/* Loader durning PIN verification */}
         {isLoading && (
            <span className="active-services-modal-loader">
               {isOpen && <Preloader />}
            </span>
         )}
      </>
   );
};

export default ActiveServicesModal;
