import React from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import classes from './GoBackButton.module.scss';
import { Routes } from '../../../../utils/constants';
import { ReactComponent as ArrowIcon } from '../../../../assets/img/arrow.svg';

const GoBackButton = ({ className }: { className?: string }) => {
   const history = useHistory();
   return (
      <div
         role="button"
         tabIndex={0}
         className={cx(classes.goBackBtn, className, 'back-btn')}
         onClick={() => history.push(Routes.HOME_ROUTE)}
         aria-label="Back button"
      >
         <ArrowIcon />
      </div>
   );
};

export default GoBackButton;
