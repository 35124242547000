/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PinInput from 'react-pin-input';
import Loader from 'react-loader-spinner';
import classes from './InputPin.module.scss';
import { selectAppLayout } from '../../../../store/selectors';

interface InputPinProps {
   length?: number;
   focus?: boolean;
   loading?: boolean;
   clearPin?: boolean;
   disabled?: boolean;
   onComplete: (value: string) => void;
}

const InputPin = ({
   length = 4,
   focus,
   loading = false,
   disabled,
   onComplete,
   clearPin,
}: InputPinProps) => {
   const pinInput = useRef<any>(null);

   const { importPinLabel } = useSelector(selectAppLayout, shallowEqual);

   useEffect(() => {
      if (clearPin) {
         pinInput?.current?.clear();
      }
      if (focus) {
         pinInput?.current?.focus();
      }
   }, [focus, clearPin]);

   return (
      <>
         <div id="input-pin-container" className={classes.pinInputWrap}>
            <label className="input-label">
               {importPinLabel ||
                  '#### No localization for param importPinLabel ####'}
            </label>
            <PinInput
               ref={pinInput}
               focus={focus}
               length={length}
               initialValue=""
               disabled={disabled}
               onChange={() => {
                  return null;
               }}
               type="numeric"
               inputMode="number"
               inputStyle={{ borderColor: 'red' }}
               inputFocusStyle={{ borderColor: 'blue' }}
               onComplete={(value) => {
                  pinInput.current.clear();
                  onComplete(value);
               }}
               autoSelect={false}
               regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {loading && (
               <span className={classes.pinLoader}>
                  <Loader type="Oval" color="red" height={30} width={30} />
               </span>
            )}
         </div>
      </>
   );
};

export default InputPin;
