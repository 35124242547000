// eslint-disable-next-line import/no-cycle
import { TRANSACTION_STATUS } from '../../utils/constants';
// 1.ACTION TYPES
// Actions constants
export enum ServicesActionTypes {
   // All services actions types
   GET_SERVICES_START = '@@services/GET_SERVICES_START',
   GET_SERVICES_SUCCESS = '@@services/GET_SERVICES_SUCCESS',
   GET_SERVICES_ERROR = '@@services/GET_SERVICES_ERROR',
   // Single service actions types
   GET_SINGLE_SERVICE_START = '@@services/GET_SINGLE_SERVICE_START',
   GET_SINGLE_SERVICE_SUCCESS = '@@services/GET_SINGLE_SERVICE_SUCCESS',
   GET_SINGLE_SERVICE_ERROR = '@@services/GET_SINGLE_SERVICE_ERROR',
   SELECT_PAYMENT_METHOD_FOR_PACKAGE = '@@services/SELECT_PAYMENT_METHOD_FOR_PACKAGE',
   // Category action types
   CHANGE_SERVICE_CATEGORY = '@@services/CHANGE_SERVICE_CATEGORY',
   // ACTIVE SERVICES
   // Active services consent disable
   DEACTIVATE_CONSENT_START = '@@services/DEACTIVATE_CONSENT_START',
   DEACTIVATE_CONSENT_SUCCESS = '@@services/DEACTIVATE_CONSENT_SUCCESS',
   DEACTIVATE_CONSENT_ERROR = '@@services/DEACTIVATE_CONSENT_ERROR',
   RESET_CONSENT = '@@services/RESET_CONSENT',
   // Active services PIN validation
   GET_PIN_START = '@@services/GET_PIN_START',
   GET_PIN_SUCCESS = '@@services/GET_PIN_SUCCESS',
   GET_PIN_ERROR = '@@services/GET_PIN_ERROR',
   RESET_PIN_VALIDATION = '@@services/RESET_PIN_VALIDATION',
   RESET_PIN_TIMER = '@@services/RESET_PIN_TIMER',
   // Active service actions types
   GET_ACTIVE_SERVICE_START = '@@services/GET_ACTIVE_SERVICE_START',
   GET_ACTIVE_SERVICE_SUCCESS = '@@services/GET_ACTIVE_SERVICE_SUCCESS',
   GET_ACTIVE_SERVICE_ERROR = '@@services/GET_ACTIVE_SERVICE_ERROR',
   // Deactivate service action types
   REMOVE_ACTIVE_SERVICE_START = '@@services/REMOVE_ACTIVE_SERVICE_START',
   REMOVE_ACTIVE_SERVICE_SUCCESS = '@@services/REMOVE_ACTIVE_SERVICE_SUCCESS',
   REMOVE_ACTIVE_SERVICE_ERROR = '@@services/REMOVE_ACTIVE_SERVICE_ERROR',
   // VALIDATION
   // Input validation
   VALIDATE_INPUT_START = '@@services/VALIDATE_INPUT_START',
   VALIDATE_INPUT_SUCCESS = '@@services/VALIDATE_INPUT_SUCCESS',
   VALIDATE_INPUT_ERROR = '@@services/VALIDATE_INPUT_ERROR',
   INPUT_NOT_VALIDATED = '@@services/VALIDATE_INPUT_ERROR',
   // Payment input validation
   VALIDATE_PAYMENT_METHOD_INPUT_START = '@@services/VALIDATE_PAYMENT_METHOD_INPUT_START',
   VALIDATE_PAYMENT_METHOD_INPUT_SUCCESS = '@@services/VALIDATE_PAYMENT_METHOD_INPUT_SUCCESS',
   VALIDATE_PAYMENT_METHOD_INPUT_ERROR = '@@services/VALIDATE_PAYMENT_METHOD_INPUT_ERROR',
   VALIDATE_PAYMENT_METHOD_INPUT_RESET = '@@services/VALIDATE_PAYMENT_METHOD_INPUT_RESET',
   // Form validation
   VALIDATE_FORM_SUBMIT_START = '@@services/VALIDATE_FORM_SUBMIT_START',
   VALIDATE_FORM_SUBMIT_SUCCESS = '@@services/VALIDATE_FORM_SUBMIT_SUCCESS',
   VALIDATE_FORM_SUBMIT_ERROR = '@@services/VALIDATE_FORM_SUBMIT_ERROR',
   // Form validation
   VALIDATE_PHONE_START = '@@services/VALIDATE_PHONE_START',
   VALIDATE_PHONE_SUCCESS = '@@services/VALIDATE_PHONE_SUCCESS',
   VALIDATE_PHONE_ERROR = '@@services/VALIDATE_PHONE_ERROR',
   VALIDATE_PHONE_RESET = '@@services/VALIDATE_PHONE_RESET',
   // Modal Errors Handling
   MODAL_ERRORS_RESET = '@@services/MODAL_ERRORS_RESET',
   VALIDATION_RESET = '@@services/VALIDATION_RESET',
   // VALIDATION ERROR MESSAGE
   VALIDATION_ERROR_MESSAGE = '@@services/VALIDATION_ERROR_MESSAGE',
   VALIDATION_ERROR_MESSAGE_RESET = '@@services/VALIDATION_ERROR_MESSAGE_RESET',
   VALIDATION_ERROR_PAYMENT_METHOD_RESET = '@@services/VALIDATION_ERROR_PAYMENT_METHOD_RESET',
   // SUBMIT FORM WITHOUT VALIDATION
   SUBMIT_FORM = '@@services/SUBMIT_FORM',
   // SEARCH SERVICES
   SEARCH_SERVICES = '@@services/SEARCH_SERVICES',
   // CLEAR SEARCH SERVICES
   CLEAR_SEARCH_SERVICES = '@@services/CLEAR_SEARCH_SERVICES',

   // New transaction
   NEW_TRANSACTION_START = '@@services/NEW_TRANSACTION_START',
   NEW_TRANSACTION_SUCCESS = '@@services/NEW_TRANSACTION_SUCCESS',
   NEW_TRANSACTION_ERROR = '@@services/NEW_TRANSACTION_ERROR',
   NEW_TRANSACTION_RESET = '@@services/NEW_TRANSACTION_RESET',

   EMAIL_CHANGE_START = '@@services/EMAIL_CHANGE_START',
   EMAIL_CHANGE_SUCCESS = '@@services/EMAIL_CHANGE_SUCCESS',
   EMAIL_CHANGE_FAILED = '@@services/EMAIL_CHANGE_FAILED',
}
type GetServicesStart = {
   type: ServicesActionTypes.GET_SERVICES_START;
};
type GetServicesSuccess = {
   type: ServicesActionTypes.GET_SERVICES_SUCCESS;
   payload: AllServicesResponse[];
};
type GetServicesError = {
   type: ServicesActionTypes.GET_SERVICES_ERROR;
};
type ChangeServiceCategory = {
   type: ServicesActionTypes.CHANGE_SERVICE_CATEGORY;
   payload: string;
};
// SINGLE SERVICE
type GetSingleServiceStart = {
   type: ServicesActionTypes.GET_SINGLE_SERVICE_START;
};
type GetSingleServiceSuccess = {
   type: ServicesActionTypes.GET_SINGLE_SERVICE_SUCCESS;
   payload: SingleServiceResponse;
};
type GetSingleServiceError = {
   type: ServicesActionTypes.GET_SINGLE_SERVICE_ERROR;
};
type ResetPinTimer = {
   type: ServicesActionTypes.RESET_PIN_TIMER;
};
type SelectPaymentMethodForPackages = {
   type: ServicesActionTypes.SELECT_PAYMENT_METHOD_FOR_PACKAGE;
   payload: string;
};
// PIN ACTIONS
type GetPinStart = {
   type: ServicesActionTypes.GET_PIN_START;
};
type GetPinSuccess = {
   type: ServicesActionTypes.GET_PIN_SUCCESS;
   payload: GetPinResponse;
   phoneNo: string;
   email: string;
};
type GetPinError = {
   type: ServicesActionTypes.GET_PIN_ERROR;
   payload: string;
};
type ResetPinValidation = {
   type: ServicesActionTypes.RESET_PIN_VALIDATION;
};
// ACTIVE SERVICE
type GetActiveServicesStart = {
   type: ServicesActionTypes.GET_ACTIVE_SERVICE_START;
};
type GetActiveServicesSuccess = {
   type: ServicesActionTypes.GET_ACTIVE_SERVICE_SUCCESS;
   // payload: ActivatedServicesResponse[];
   payload: ActiveServicesResponse;
};
type GetActiveServicesError = {
   type: ServicesActionTypes.GET_ACTIVE_SERVICE_ERROR;
   payload: string;
};
type RemoveActiveServicesStart = {
   type: ServicesActionTypes.REMOVE_ACTIVE_SERVICE_START;
};
type RemoveActiveServicesSuccess = {
   type: ServicesActionTypes.REMOVE_ACTIVE_SERVICE_SUCCESS;
   payload: ActiveServicesResponse;
};
type RemoveActiveServicesError = {
   type: ServicesActionTypes.REMOVE_ACTIVE_SERVICE_ERROR;
};
type ValidateInputStart = {
   type: ServicesActionTypes.VALIDATE_INPUT_START;
};
type ValidateInputSuccess = {
   type: ServicesActionTypes.VALIDATE_INPUT_SUCCESS;
};
type ValidateInputError = {
   type: ServicesActionTypes.VALIDATE_INPUT_ERROR;
   payload: string;
};
type ValidatePaymentMethodInputStart = {
   type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_START;
};
type ValidatePaymentMethodInputSuccess = {
   type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_SUCCESS;
};
type ValidatePaymentMethodInputError = {
   type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_ERROR;
   payload: string;
};
type ValidatePaymentMethodInputReset = {
   type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_RESET;
};
type ValidateFormSubmitStart = {
   type: ServicesActionTypes.VALIDATE_FORM_SUBMIT_START;
};
type ValidateFormSubmitSuccess = {
   type: ServicesActionTypes.VALIDATE_FORM_SUBMIT_SUCCESS;
};
type ValidateFormSubmitError = {
   type: ServicesActionTypes.VALIDATE_FORM_SUBMIT_ERROR;
   payload: string;
};
type ValidationReset = {
   type: ServicesActionTypes.VALIDATION_RESET;
};
type ValidatePhoneStart = {
   type: ServicesActionTypes.VALIDATE_PHONE_START;
};
type ValidatePhoneSuccess = {
   type: ServicesActionTypes.VALIDATE_PHONE_SUCCESS;
};
type ValidatePhoneError = {
   type: ServicesActionTypes.VALIDATE_PHONE_ERROR;
   payload: string;
};
type ValidatePhoneReset = {
   type: ServicesActionTypes.VALIDATE_PHONE_RESET;
};
type ValidationErrorMessage = {
   type: ServicesActionTypes.VALIDATION_ERROR_MESSAGE;
   payload: string;
};
type ValidationErrorMessageReset = {
   type: ServicesActionTypes.VALIDATION_ERROR_MESSAGE_RESET;
};
type ValidationErrorPaymentMethodReset = {
   type: ServicesActionTypes.VALIDATION_ERROR_PAYMENT_METHOD_RESET;
};
type SubmitForm = {
   type: ServicesActionTypes.SUBMIT_FORM;
};
type ModalErrorsReset = {
   type: ServicesActionTypes.MODAL_ERRORS_RESET;
};
type SearchServices = {
   type: ServicesActionTypes.SEARCH_SERVICES;
   payload: string;
};
type ClearSearchServices = {
   type: ServicesActionTypes.CLEAR_SEARCH_SERVICES;
};
type DeactivateConsentStart = {
   type: ServicesActionTypes.DEACTIVATE_CONSENT_START;
};
type DeactivateConsentSuccess = {
   type: ServicesActionTypes.DEACTIVATE_CONSENT_SUCCESS;
};
type DeactivateConsentError = {
   type: ServicesActionTypes.DEACTIVATE_CONSENT_ERROR;
};
type ResetConsent = {
   type: ServicesActionTypes.RESET_CONSENT;
};
type NewTransactionStart = {
   type: ServicesActionTypes.NEW_TRANSACTION_START;
};
type NewTransactionSuccess = {
   type: ServicesActionTypes.NEW_TRANSACTION_SUCCESS;
   payload: NewTransactionResponse;
};
type NewTransactionError = {
   type: ServicesActionTypes.NEW_TRANSACTION_ERROR;
};
type NewTransactionReset = {
   type: ServicesActionTypes.NEW_TRANSACTION_RESET;
};

type EmailChangeStart = {
   type: ServicesActionTypes.EMAIL_CHANGE_START;
};
type EmailChangeSuccess = {
   type: ServicesActionTypes.EMAIL_CHANGE_SUCCESS;
};
type EmailChangeFailed = {
   type: ServicesActionTypes.EMAIL_CHANGE_FAILED;
};
// Actions dispatch types
export type ServicesDispatchTypes =
   | GetServicesStart
   | GetServicesSuccess
   | GetServicesError
   | GetSingleServiceStart
   | GetSingleServiceSuccess
   | SelectPaymentMethodForPackages
   | ChangeServiceCategory
   | GetSingleServiceError
   | ValidateInputStart
   | ValidateInputSuccess
   | ValidateInputError
   | ValidatePaymentMethodInputStart
   | ValidatePaymentMethodInputSuccess
   | ValidatePaymentMethodInputError
   | ValidatePaymentMethodInputReset
   | ValidateFormSubmitStart
   | ValidateFormSubmitSuccess
   | ValidateFormSubmitError
   | ValidationReset
   | ValidatePhoneStart
   | ValidatePhoneSuccess
   | ValidatePhoneError
   | ValidatePhoneReset
   | GetActiveServicesStart
   | GetActiveServicesError
   | GetActiveServicesSuccess
   | RemoveActiveServicesStart
   | RemoveActiveServicesSuccess
   | RemoveActiveServicesError
   | GetPinStart
   | GetPinSuccess
   | GetPinError
   | ModalErrorsReset
   | ResetPinValidation
   | ResetPinTimer
   | ValidationErrorMessage
   | ValidationErrorMessageReset
   | SubmitForm
   | SearchServices
   | ClearSearchServices
   | ValidationErrorPaymentMethodReset
   | DeactivateConsentStart
   | DeactivateConsentSuccess
   | DeactivateConsentError
   | ResetConsent
   | NewTransactionStart
   | NewTransactionSuccess
   | NewTransactionError
   | NewTransactionReset
   | EmailChangeStart
   | EmailChangeSuccess
   | EmailChangeFailed;
// 2.API REQUEST TYPES
// All services response
export interface AllServicesResponse {
   packages: any;
   gridProperties: ServiceGridProperties;
   sliderProperties: SliderProperties;
   serviceBoxDecoratorProperties: ServiceBoxDecoratorProperties;
   gridType: string | null;
   categoryName: string;
   categoryCode: string;
   services: Service[];
   imageUrls: string[];
}
export interface ServiceGridProperties {
   rowSize: any;
}
export interface SliderProperties {
   showPagination: boolean;
   enableAutoplay: boolean;
   autoplaySpeed: number;
   direction: 'ltr' | 'rtl';
}
export interface ServiceBoxDecoratorProperties {
   serviceBoxDecoratorType: string | null;
   serviceBoxDecoratorText: string | null;
   serviceBoxDecoratorOrientation: string | null;
   serviceBoxDecoratorColor: string | null;
}
export interface Service {
   image: string;
   validators: null;
   name: string;
   merchant: string;
   description: null | string;
   serviceKey: any;
   subscriptionId: null;
   params: null;
   packages: null;
   contentType: null | string;
   serviceDetailsURL: null | string;
   activateURL: null | string;
   emptyPackagesErrorMessage: null;
   packagesHeaderLabel: null;
   warnEmptyPackagesMessage: string | null;
   previewGif: string;
   bannerTitle: string | null;
   shortDescription: string | null;
   disabled: boolean;
   disabledServiceLabel: string;
}
export interface PaymentMethod {
   id: string;
   countryCode: string;
   name: string;
   iconUrl: string;
   tosUrl: string;
   helpUrl: string;
   operatorCode: null;
   type: string;
   code: string;
   emailSepParams: string[];
   order: number;
   price: null;
   currency: null;
   label: null | string;
   visible: boolean;
   tenant: null;
   paymentMethodParams: PaymentMethodParam[];
   descriptionLabel: string;
   providerCode: null | string;
   paymentAppContext: null | string;
}
// Single service response

export interface NewTransactionRequest {
   country: string;
   serviceKey: any;
   initiationToken: string;
   operator: string;
   token: string;
   packageAmount: number;
   packageUnit: string;
   paymentMethodType: string;
   merchantPackageReference?: any;
}

export type TransactionStatus =
   typeof TRANSACTION_STATUS[keyof typeof TRANSACTION_STATUS];
export interface NewTransactionResponse {
   transactionId: number;
   status: TransactionStatus;
}
export interface SingleServiceResponse {
   image: string;
   emptyPackagesErrorMessage: null | string;
   validators: Validator[];
   name: string;
   merchant: string;
   description: string;
   serviceKey: string | null | undefined;
   subscriptionId: null;
   params: Param[];
   packages: Package[];
   contentType: null;
   termsAndConditionsURL: null | string;
   helpURL: null | string;
   packagesHeaderLabel: null | string;
   warnEmptyPackagesMessage: string | null;
   emailLabel: null | string;
   paymentMethods: PaymentMethod[];
   activateURL: string | null;
   hidePackagesAmount: boolean;
   disabled: boolean;
   disabledServiceLabel: string;
}
export interface ActivatedServicesResponse {
   msisdn: string;
   email: string;
   name: string;
   imageUrl: string;
   serviceKey: string;
   timeSubscribedLocal: string | null;
   timeUnsubscribedLocal: string | null;
   timeChargedLocal: string | null;
   expirationTimeLocal: string | null;
   price: number;
   merchant: string;
   description: string;
   subscriptionPeriod: string | null;
   currency: string;
   unsubscribeToken: string;
   packageName: string | null;
}
export interface GetPinResponse {
   pinId: string;
}
export interface Package {
   amount: number;
   unit: string;
   // price: number;
   // currency: string;
   currencyLabel: string;
   interval: string;
   uniqueReference: string;
   merchantReference: string;
   reference: string;
   paymentMethods: PackagePaymentMethod[];
   disabled: boolean;
}
export interface PackagePaymentMethod {
   id: string;
   code: string;
   price: number;
   oldPrice: number | null;
   currency: string;
   currencyCode: string;
   merchantReference: string;
   merchantToken: string;
   reference: string;
}
export interface Param {
   enumMultipleChoice: null;
   minLength: null;
   imageUrl: null | string;
   name: string;
   stringFormatRegex: null;
   description: string;
   id: string;
   position: number;
   type: string;
   required: boolean;
   maxLength: null;
   enumValues: null | string[];
   label: string;
   warnEmptyParamLabel: string;
   warnInvalidFormatLabel: string | null;
   helpURL: string | null;
   additionalInfoLabel: null | string;
}
export interface PaymentMethodParam {
   id: string;
   validatorUrl: string;
   name: string;
   dropdownValues: null | string[];
   overrideDropdown: boolean;
   sepParams: string[];
   required: boolean;
   regex: string | null;
   label: null | string;
   placeholder: null | string;
   dropdownPlaceholder: null | string;
   description: null | string;
   addNewLabel: null | string;
   columnType: string;
   paymentMethod: null;
   warnEmptyInputField: string | null;
   warnInvalidInputFormat: string | null;
   validatorWarn: string | null;
   validatorError: string | null;
   validatorForbiddenProfile: string | null;
   prefix: string;
   prefixLabel: string;
}
export type ActiveServicesResponse = {
   servicesInfo: ActivatedServicesResponse[];
   contactConsent: ActiveServicesConsent;
   notificationMessage: string | null;
};
export interface Validator {
   trigger: string;
   params: string[];
   url: string;
}
export interface ValidatePhoneNumberResponseProps {
   status: 'SUCCESS' | 'UNAVAILABLE' | 'INVALID' | 'ERROR' | 'FORBIDDEN_PROFILE';
   message: string;
}

export interface BalanceByCurrency {
   amount: number;
   currency: string;
   storefrontCode: string;
}

// 3.STATE TYPES
interface StatesProp {
   isLoading: boolean;
   isServicesReady: boolean;
   // selectedPaymentMethodId: Param | null;
   selectedPaymentMethodId: any;
}
export interface ValidationState {
   input: {
      isLoading: boolean;
      isInputValidated: boolean | null;
      errorMessage: string;
   };
   paymentMethodInput: {
      isLoading: boolean;
      isInputValidated: boolean | null;
      errorMessage: string;
   };
   phone: {
      isLoading: boolean;
      isInputValidated: boolean | null;
      errorMessage: string;
   };
   form: {
      isLoading: boolean;
      onSubmitValidated: boolean;
      errorMessage: string;
   };
   pin: {
      isPinLoading: boolean;
      pinTimerActive: boolean;
      isPinSent: boolean;
   };
   error: {
      message: string;
   };
}
export interface ActiveServicesConsent {
   id: string;
   reference: string;
   active: boolean;
   timeCreatedString: string;
   disableContactLabel: string | null;
   disableContactDescription: string | null;
   disableContactDialogDescription: string | null;
   disableContactDialogConfirmLabel: string | null;
   disableContactDialogCancelLabel: string | null;
   disableContactTimeLabel: string | null;
   successfullyDisabledLabel: string | null;
}
interface ActiveState {
   activeServices: {
      phoneNo: string;
      email: string;
      pinId: string;
      all: ActivatedServicesResponse[];
      errorMessage: string;
   };
   activeServicesConsent: ActiveServicesConsent | null;
   consentStatus: {
      isConsentDisableLoading: boolean;
      isConsentDisabled: boolean;
   };
}
interface TransactionState {
   transactionStatus: TransactionStatus | null;
   isLoading: boolean;
   showTransactionStatus: boolean;
}

export interface ServicesState {
   states: StatesProp;
   search: string;
   validation: ValidationState;
   all: AllServicesResponse[];
   displayedServices: {
      selectedCategory: string;
      services: AllServicesResponse[];
   };
   single: SingleServiceResponse | Record<string, never>;
   active: ActiveState;
   transaction: TransactionState | Record<string, never>;
}
