import { useState } from 'react';
import cx from 'classnames';
import parse from 'html-react-parser';

interface AccordionProp {
   title: string | null;
   text: string | null;
}

const Accordion = ({ title, text }: AccordionProp) => {
   const [isAccordionOpen, setIsAccordionOpen] = useState(false);

   const onAccordionClickHandler = () => {
      setIsAccordionOpen(!isAccordionOpen);
   };
   return (
      <div
         tabIndex={0}
         role="button"
         onClick={onAccordionClickHandler}
         className={cx('accordion', isAccordionOpen && 'open')}
      >
         <div className="accordion-header-wrap">
            <h3 className="accordion-title">{title ?? ''}</h3>
            <span className="accordion-icon" />
         </div>

         <div className={cx('accordion-content')}>{parse(text ?? '')}</div>
      </div>
   );
};

export default Accordion;
