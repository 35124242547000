import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import Image from '../../ui/image/Image';
import Button from '../../ui/button/Button';
import { selectAppLayout, selectAuthState } from '../../../store/selectors';
import classes from './BigBoxServiceCard.module.scss';
import boxDecoratorClasses from '../../ui/serviceBoxDecorator/ServiceBoxDecorator.module.scss';
import { ServiceDetailsState } from '../../../pages/servicePage/serviceDetailsPage/ServiceDetailsPage';
import ServiceBoxDecorator from '../../ui/serviceBoxDecorator/ServiceBoxDecorator';
import { ServiceBoxDecoratorProperties } from '../../../store/services/servicesTypes';
import { useModalQueryParam } from '../../../utils/hooks';

interface ServiceCardProps {
   services: any;
   categoryCode: string;
   serviceDecoratorProps?: ServiceBoxDecoratorProperties | null;
   onClick: () => void;
}

const BigBoxServiceCard = ({
   services,
   categoryCode,
   serviceDecoratorProps,
   onClick,
}: ServiceCardProps) => {
   const history = useHistory();
   const { data } = useSelector(selectAuthState);

   const { addQueryParamOnModalClick } = useModalQueryParam();

   const {
      buttonActivateAllServicesLabel,
      buttonActivateAllServicesExternalLabel,
      domain,
   } = useSelector(selectAppLayout, shallowEqual);

   const onActivateClickHandler = (
      activateURL: string,
      title: string,
      serviceKey: string,
   ) => {
      // Block for modal
      if (domain === 'marketspace.centili.com' || domain === 'sodexo.centili.com') {
         onClick();
         addQueryParamOnModalClick(serviceKey, categoryCode);
         return;
      }

      if (activateURL) {
         history.push({
            pathname: `/${title?.replaceAll(' ', '')}`,
            search: `category=${categoryCode}`,
            state: { serviceKey } as ServiceDetailsState,
         });
         return;
      }

      history.push(`/service/${serviceKey}?data=${data}&category=${categoryCode}`);
   };
   const activateLabel = (activateURL: string) => {
      const label = activateURL
         ? buttonActivateAllServicesExternalLabel
         : buttonActivateAllServicesLabel;
      return label;
   };
   return (
      <>
         {/* <div
         tabIndex={0}
         role="button"
         {...(enableClickableServiceCard && {
            onClick: () => onActivateClickHandler(),
         })}
         className={cx('service-card', 'error-field', 'ribbon-box', className, {
            clickable: enableClickableServiceCard,
         })}
      >
         <div style={{ display: 'none' }} className="ribbon ribbon-top-left">
            <span>{categoryName}</span>
         </div>
         <div className={cx('service-card-image-wrap')}>
            <Image image={image} className={cx('service-card-image')} />
         </div>

         <div className="service-info-wrap">
            <div className="service-title-wrap">
               {CATEGORY_NAME_TYPE.tag === categoryNameType && (
                  <h6 className="service-category-title">{categoryName}</h6>
               )}
               <h5 className={cx('service-title')}>{title}</h5>
               <p className={cx('service-description')}>
                  {parse(description || '')}
               </p>
            </div>

            <div className="service-btn-wrap">
               {!hideViewServiceButtonOnServiceCard && (
                  <Button
                     name={serviceKey}
                     title={
                        activateLabel ||
                        '#### No localization for param buttonActivateLabel ####'
                     }
                     onClick={onActivateClickHandler}
                  />
               )}

               {serviceDetailsURL && (
                  <a className="service-details-btn" href={serviceDetailsURL}>
                     {serviceDetailsURLLabel}
                  </a>
               )}
            </div>
         </div>
      </div> */}

         <div
            className={cx('big-box-main-container', classes.container)}
            role="button"
            tabIndex={0}
         >
            <div
               className={cx(
                  'service-card-image-wrap',
                  'big-box',
                  classes.bigBox,
                  boxDecoratorClasses.bigBox,
               )}
            >
               <ServiceBoxDecorator
                  type={serviceDecoratorProps?.serviceBoxDecoratorType || null}
                  color={serviceDecoratorProps?.serviceBoxDecoratorColor || ''}
                  text={serviceDecoratorProps?.serviceBoxDecoratorText || ''}
                  orientation="left"
               />
               <div className={cx('box-image-container', classes.imageContainer)}>
                  <Image
                     image={services[0].image}
                     className={cx('service-card-image', classes.image)}
                  />
                  <Image
                     image={services[0].previewGif}
                     className={cx(
                        'service-card-image',
                        'gif-image',
                        classes.hoverGifBigBox,
                     )}
                  />
               </div>
               <div
                  className={cx(
                     'big-box-content-wrapper',
                     classes.contentWrapperBigBox,
                  )}
               >
                  <div className={cx('service-title')}>{services[0].name}</div>
                  <Button
                     name={services[0].name}
                     title={
                        services[0].disabled
                           ? services[0].disabledServiceLabel ||
                             '#### No localization for param disabledServiceLabel ####'
                           : activateLabel(services[0].activateURL) ||
                             '#### No localization for param buttonActivateLabel ####'
                     }
                     disabled={services[0].disabled}
                     onClick={() => {
                        onActivateClickHandler(
                           services[0].activateURL,
                           services[0].title,
                           services[0].serviceKey,
                        );
                     }}
                  />
               </div>
            </div>
            <div className={classes.smallBoxes}>
               <div className={classes.row}>
                  {services.slice(1, 3).map((service: any, index: any) => (
                     <div
                        key={`${index}_small-box`}
                        className={cx(
                           'service-card-image-wrap',
                           'small-box',
                           classes.smallBox,
                           boxDecoratorClasses.smallBox,
                        )}
                     >
                        <ServiceBoxDecorator
                           type={
                              serviceDecoratorProps?.serviceBoxDecoratorType || null
                           }
                           color={
                              serviceDecoratorProps?.serviceBoxDecoratorColor || ''
                           }
                           text={
                              serviceDecoratorProps?.serviceBoxDecoratorText || ''
                           }
                           orientation="right"
                        />
                        <div
                           className={cx(
                              'box-image-container',
                              classes.imageContainer,
                           )}
                        >
                           <Image
                              image={service.image}
                              className={cx(
                                 'service-card-image',
                                 classes.smallImage,
                              )}
                           />
                           <Image
                              image={service.previewGif}
                              className={cx(
                                 'service-card-image',
                                 'gif-image',
                                 classes.hoverGif,
                              )}
                           />
                        </div>

                        {/* <Image
                           image={service.image}
                           className={cx('service-card-image', classes.smallImage)}
                        /> */}
                        <div
                           className={cx(
                              'content-wrapper-small-box',
                              classes.contentWrapper,
                           )}
                        >
                           <div className={cx('service-title')}>{service.name}</div>
                           <Button
                              name={service.name}
                              title={
                                 service.disabled
                                    ? service.disabledServiceLabel ||
                                      '#### No localization for param disabledServiceLabel ####'
                                    : activateLabel(service.activateURL) ||
                                      '#### No localization for param buttonActivateLabel ####'
                              }
                              disabled={service.disabled}
                              onClick={() => {
                                 onActivateClickHandler(
                                    service.activateURL,
                                    service.title,
                                    service.serviceKey,
                                 );
                              }}
                           />
                        </div>
                     </div>
                  ))}
               </div>
               <div className={classes.row}>
                  {services.length > 3 &&
                     services.slice(3, 5).map((service: any, index: any) => (
                        <div
                           key={`${index}_small-box`}
                           className={cx(
                              'service-card-image-wrap',
                              'small-box',
                              classes.smallBox,
                              boxDecoratorClasses.smallBox,
                           )}
                        >
                           <ServiceBoxDecorator
                              type={
                                 serviceDecoratorProps?.serviceBoxDecoratorType ||
                                 null
                              }
                              color={
                                 serviceDecoratorProps?.serviceBoxDecoratorColor ||
                                 ''
                              }
                              text={
                                 serviceDecoratorProps?.serviceBoxDecoratorText || ''
                              }
                              orientation="right"
                           />{' '}
                           <div
                              className={cx(
                                 'box-image-container',
                                 classes.imageContainer,
                              )}
                           >
                              <Image
                                 image={service.image}
                                 className={cx(
                                    'service-card-image',
                                    classes.smallImage,
                                 )}
                              />
                              <Image
                                 image={service.previewGif}
                                 className={cx(
                                    'service-card-image',
                                    'gif-image',
                                    classes.hoverGif,
                                 )}
                              />
                           </div>
                           <div
                              className={cx(
                                 'content-wrapper-small-box',
                                 classes.contentWrapper,
                              )}
                           >
                              <div className={cx('service-title')}>
                                 {service.name}
                              </div>
                              <Button
                                 name={service.name}
                                 title={
                                    service.disabled
                                       ? service.disabledServiceLabel ||
                                         '#### No localization for param disabledServiceLabel ####'
                                       : activateLabel(service.activateURL) ||
                                         '#### No localization for param buttonActivateLabel ####'
                                 }
                                 disabled={service.disabled}
                                 onClick={() => {
                                    onActivateClickHandler(
                                       service.activateURL,
                                       service.title,
                                       service.serviceKey,
                                    );
                                 }}
                              />
                           </div>
                        </div>
                     ))}
               </div>
            </div>
         </div>
         {/* {services.length > 5 && (
            <div className={classes.container} role="button" tabIndex={0}>
               <div className={classes.smallBoxes}>
                  <div className={cx(classes.row, classes.bottomRow)}>
                     {services.slice(5, 9).map((service: any, index: any) => (
                        <div
                           key={`${index}_small-box`}
                           className={cx(
                              'service-card-image-wrap',
                              classes.smallBox,
                           )}
                        >
                           <Image
                              image={service.image}
                              className={cx(
                                 'service-card-image',
                                 classes.smallImage,
                              )}
                           />
                           <div className={classes.contentWrapper}>
                              <div className={cx('service-title')}>
                                 {service.name}
                              </div>
                              <Button
                                 name={service.name}
                                 title={
                                    activateLabel(service.activateURL) ||
                                    '#### No localization for param buttonActivateLabel ####'
                                 }
                                 onClick={() =>
                                    onActivateClickHandler(
                                       service.activateURL,
                                       service.title,
                                       service.serviceKey,
                                    )
                                 }
                              />
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         )} */}
         {/*  */}
      </>
   );
};

export default BigBoxServiceCard;
