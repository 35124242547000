import React from 'react';
import cx from 'classnames';
import classes from './ServiceBoxDecorator.module.scss';

interface ServiceBoxDecoratorProps {
   type: string | null;
   text: string;
   orientation: string;
   color: string;
}

const ServiceBoxDecorator = ({
   text,
   type,
   orientation,
   color,
}: ServiceBoxDecoratorProps) => {
   const DEFAULT_COLOR = '#da291c';
   const DEFAULT_ORIENTATION = 'left';

   const showDecorator = !!type && text !== '';
   const typeClassName = (type && type.toLowerCase()) || '';

   orientation = orientation?.toLowerCase() || DEFAULT_ORIENTATION;
   color = color || DEFAULT_COLOR;

   return (
      <>
         {showDecorator && (
            <div
               className={cx(
                  `${typeClassName} ${typeClassName}-top-${orientation}`,
                  classes[typeClassName],
                  classes[orientation],
               )}
               style={{ '--color': `${color}` } as React.CSSProperties}
            >
               <span>{text}</span>
            </div>
         )}
      </>
   );
};

export default ServiceBoxDecorator;
