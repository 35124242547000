import axios from 'axios';
import { BalanceEntryResponse, BalanceResponseProps } from '../store/user/userTypes';
import {
   AnalyticHeaderProps,
   PinPostProps,
   ValidationPostObjectProps,
   GetInitialLayoutProps,
   GetAllServicesProps,
   GetSingleServiceProps,
} from '../types/globals';
import { getDomainFromUrl, getDomainWithoutProtocol } from '../utils/helpers';
import {
   GetPinResponse,
   ValidatePhoneNumberResponseProps,
   AllServicesResponse,
   SingleServiceResponse,
   ActiveServicesResponse,
   NewTransactionResponse,
   NewTransactionRequest,
} from '../store/services/servicesTypes';

import { axiosInstance, axiosAnalyticsInstance } from './config';
import { StorefrontConfigResponse } from '../store/app/appTypes';
import history from '../utils/history';
import store from '../store/rootStore';

import { BROWSER_STORAGE_KEYS } from '../utils/constants';

// STORE
export const getInitialLayoutConfig = ({
   data,
   domain,
   language,
}: GetInitialLayoutProps) => {
   return axiosAnalyticsInstance.get<StorefrontConfigResponse>(
      `/storefront/v1/?data=${data}&domain=${domain}&language=${language}`,
      // `/storefront/v1/?data=${data}&domain=${domain}&language=es`,
   );
};

export const getAllServices = ({ language, domain }: GetAllServicesProps) => {
   const url = `/storefront/v1/services?language=${language}&domain=${domain}`;
   return axiosInstance.get<AllServicesResponse[]>(url);
};

export const getBalance = (token: string) => {
   // TODO-Remove after testing
   // return axios.get<BalanceResponseProps>(
   //    'https://sodexo.centili.com/marketspace-balance/v1/',
   //    { headers: { Authorization: `Bearer ${token}` } },
   // );
   return axios.get<BalanceResponseProps>(
      `${getDomainFromUrl()}/marketspace-balance/v1/`,
      { headers: { Authorization: `Bearer ${token}` } },
   );
};

export const getBalanceHistory = (token: string) => {
   // TODO-Remove after testing
   // return axios.get<BalanceEntryResponse[]>(
   //    `https://sodexo.centili.com/marketspace-balance/v1/all`,
   //    {
   //       headers: { Authorization: `Bearer ${token}` },
   //    },
   // );
   return axios.get<BalanceEntryResponse[]>(
      `${getDomainFromUrl()}/marketspace-balance/v1/all`,
      {
         headers: { Authorization: `Bearer ${token}` },
      },
   );
};

export const getServiceByCategory = ({
   language,
   domain,
   category,
}: GetAllServicesProps) => {
   return axiosInstance.get<AllServicesResponse[]>(
      `/storefront/v1/services?language=${language}&domain=${domain}&category=${category}`,
   );
};

export const getSingleService = ({
   country,
   domain,
   data,
   serviceKey,
   categoryCode,
   language,
}: GetSingleServiceProps) => {
   return axiosAnalyticsInstance.get<SingleServiceResponse>(
      `/storefront/v1/services/${serviceKey}?country=${country}&domain=${domain}&data=${data}&language=${language}&category=${categoryCode}`,
   );
};
export const redirectToSepPageUrl = async (
   query: string,
   externalUrl: boolean,
   token: string | undefined,
) => {
   const { standalone } = store.getState().app.layout;

   try {
      const config: { headers?: { Authorization: string } } = {};

      if (token) {
         config.headers = {
            Authorization: `Bearer ${token}`,
         };
      }

      const results = await axios.get(
         `/storefront/v1/services/sep_url?${query}`,
         config,
      );

      if (standalone && !externalUrl) {
         const iframeLink = results.data.sepUrl;
         history.push(`/sep`, { iframeLink });
         window?.localStorage.setItem(
            BROWSER_STORAGE_KEYS.localStorage.sepRedirectionLink,
            iframeLink || '',
         );
         return;
      }
      window.location.href = results.data.sepUrl || '';
   } catch (error) {
      // Handle any errors that may occur during the request
      console.error('Error:', error);
   }
};

export const callAAnalyticsService = (query: string) => {
   return axiosAnalyticsInstance.get<SingleServiceResponse>(
      `/storefront/v1/services/validate?${query}`,
   );
};

export const sendPinCodeRequest = (domain: string, postObj: PinPostProps) => {
   const pinPath = `/storefront/v1/pin?domain=${domain}`;
   return axiosInstance.post<GetPinResponse>(pinPath, postObj);
};

export const getActiveServices = (activeServiceQuery: string) => {
   return axiosInstance.get<ActiveServicesResponse>(
      `/storefront/v1/services/user?${activeServiceQuery}`,
   );
};

export const getActiveServicesWithToken = (token: string, domain: string) => {
   return axiosInstance.get<ActiveServicesResponse>(
      `/storefront/v1/services/user/authenticated?domain=${domain}`,
      {
         headers: {
            Authorization: `Bearer ${token}`,
         },
      },
   );
};

export const deactivateService = (deactivateServiceQuery: string) => {
   const pinPath = `/storefront/v1/services/unsubscribe?${deactivateServiceQuery}`;
   return axiosInstance.post(pinPath);
};

export const validateInput = (
   path: string,
   postObj: ValidationPostObjectProps<string>,
) => {
   return axiosInstance.post<any>(path, postObj);
};

export const validateInputWithQuery = (path: string) => {
   return axiosInstance.get<any>(path);
};

export const validateFormOnSubmit = (
   path: string,
   postObj: ValidationPostObjectProps<string>,
) => {
   return axiosInstance.post<any>(path, postObj);
};

export const validatePhoneNumber = (validationPath: string, msisdn: string) => {
   const validationFullPath = `${validationPath}?msisdn=${msisdn}`;
   return axiosInstance.get<ValidatePhoneNumberResponseProps>(validationFullPath);
};

export const sendAnalytics = (category: string, domain: string) => {
   const analyticsPath = `/storefront/v1/analytics/click/category?domain=${domain}`;
   return axiosAnalyticsInstance.post(analyticsPath, {
      category,
   });
};

export const sendRegisterAnalytics = (domain: string) => {
   const analyticsPath = `/storefront/v1/analytics/user/register?domain=${domain}`;
   return axiosAnalyticsInstance.post(analyticsPath);
};

export const sendSignInAnalytics = (domain: string, keycloakId: string) => {
   const analyticsPath = `/storefront/v1/analytics/user/sign_in?domain=${domain}`;
   return axiosAnalyticsInstance.post(analyticsPath, {
      keycloakId,
   });
};

export const sendViewProfileAnalytics = (domain: string, keycloakId: string) => {
   const analyticsPath = `/storefront/v1/analytics/user/view_profile?domain=${domain}`;
   return axiosAnalyticsInstance.post(analyticsPath, {
      keycloakId,
   });
};

export const sendChatbotClickedAnalytics = (domain: string, keycloakId: string) => {
   const analyticsPath = `/storefront/v1/analytics/user/chatbot?domain=${domain}`;
   return axiosAnalyticsInstance.post(analyticsPath, {
      keycloakId,
   });
};

export const deactivateConsent = (reference: string, domain: string) => {
   const cancelConsentPath = `/storefront/v1/contact_consents/deactivate?reference=${reference}&domain=${domain}`;
   return axiosAnalyticsInstance.post(cancelConsentPath);
};

export const createWalletTransaction = (
   transactionRequest: NewTransactionRequest,
   analyticsHeaders: AnalyticHeaderProps,
) => {
   const {
      country,
      serviceKey,
      initiationToken,
      operator,
      token,
      packageAmount,
      packageUnit,
      paymentMethodType,
      merchantPackageReference,
   } = transactionRequest;
   // TODO-uncomment after testing

   const startWalletTransactionPath = `/storefront/v1/wallet/transaction?domain=${getDomainWithoutProtocol()}`;
   // const startWalletTransactionPath = `/storefront/v1/wallet/transaction?domain=marketspace.centili.com`;
   return axiosInstance.post<NewTransactionResponse>(
      startWalletTransactionPath,
      {
         country,
         serviceKey,
         initiationToken,
         operator,
         packageAmount,
         packageUnit,
         paymentMethodType,
         merchantPackageReference,
      },
      {
         headers: { Authorization: `Bearer ${token}`, ...analyticsHeaders },
      },
   );
};

export const getWalletTransactionStatus = (transactionId: number, token: string) => {
   return axiosInstance.get<NewTransactionResponse>(
      // TODO-uncomment after testing
      `/storefront/v1/wallet/transaction/${transactionId}?domain=${getDomainWithoutProtocol()}`,
      // `/storefront/v1/wallet/transaction/${transactionId}?domain=marketspace.centili.com`,

      {
         headers: { Authorization: `Bearer ${token}` },
      },
   );
};

export const changeEmail = (
   username: string,
   email: string,
   language: string,
   token: string,
) => {
   const changeEmailPath = `/storefront-balance/v1/user/update`;
   const centiliRedirectHeader = `${window.location.protocol}//${window.location.host}`;

   return axiosInstance.put(
      changeEmailPath,
      {
         username,
         email,
      },
      {
         headers: {
            Authorization: `Bearer ${token}`,
            'Storefront-Language': language,
            'Centili-Redirect-Url': centiliRedirectHeader,
         },
      },
   );
};
