import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { USER_INITIAL_STATE } from '../../utils/constants';
import { UserState, UserDispatchTypes, UserActionTypes } from './userTypes';

const userState: UserState = {
   ...USER_INITIAL_STATE,
};

const userReducer: Reducer<UserState, UserDispatchTypes> = produce(
   (draft: Draft<UserState>, action: UserDispatchTypes) => {
      switch (action.type) {
         case UserActionTypes.GET_USER_BALANCE:
            draft.balance = action.payload;
            break;
         case UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_START:
            draft.activeServices.isLoading = true;
            break;
         case UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_SUCCESS:
            draft.activeServices.isLoading = false;
            draft.activeServices.services = action.payload.servicesInfo;
            draft.activeServices.consent = action.payload.contactConsent;
            break;
         case UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_ERROR:
            draft.activeServices.isLoading = false;
            draft.activeServices.errorMessage = action.payload;
            break;
         case UserActionTypes.GET_USER_BALANCE_HISTORY_START:
            draft.isLoading = true;
            break;
         case UserActionTypes.GET_USER_BALANCE_HISTORY_SUCCESS:
            draft.isLoading = false;
            draft.balanceHistory = action.payload;
            break;
         case UserActionTypes.GET_USER_BALANCE_HISTORY_ERROR:
            draft.isLoading = false;
            break;
         case UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_START:
            draft.activeServices.isLoading = true;
            break;
         case UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_SUCCESS:
            draft.activeServices.isLoading = false;
            draft.activeServices.services = action.payload.servicesInfo;
            draft.activeServices.consent = action.payload.contactConsent;
            break;
         case UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_ERROR:
            draft.activeServices.isLoading = false;
            break;
         default:
            return;
      }

      return draft;
   },
   userState,
);

export default userReducer;
