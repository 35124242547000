import { useKeycloak } from '@react-keycloak/web';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserBalance, selectAppLayout } from '../../../store/selectors';
import { ReactComponent as UserIcon } from '../../../assets/img/user-icon.svg';
import BalanceIcon from '../../../assets/img/coin-icon.svg';
import { sendViewProfileAnalyticActions } from '../../../store/app/appAction';

const UserHeaderProfile = () => {
   const { keycloak } = useKeycloak();
   const { amount } = useSelector(selectUserBalance);
   const userBalance = amount;
   const { domain } = useSelector(selectAppLayout);
   const history = useHistory();
   const dispatch = useDispatch();

   const onProfileClick = () => {
      const keycloakId = keycloak.subject || '';
      dispatch(sendViewProfileAnalyticActions({ domain, keycloakId }));
      history.push('/profile');
   };

   return (
      <div
         className="user-profile-container"
         onClick={() => onProfileClick()}
         role="button"
         tabIndex={0}
      >
         <div className="user-profile-username">
            <span className="user-profile-icon">
               <UserIcon />
            </span>
            <span className="username">{keycloak.tokenParsed?.email}</span>
         </div>
         <div className="user-profile-balance">
            {userBalance}
            <span className="user-profile-balance-icon">
               <img alt="" src={BalanceIcon} />
            </span>
         </div>
      </div>
   );
};

export default UserHeaderProfile;
