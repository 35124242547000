import cx from 'classnames';
import { Children } from '../../../../types/globals';
import classes from './Layout.module.scss';

const Layout = ({ children }: Children) => {
   // const location = useLocation();
   // const isServiceRoute = useMemo(
   //    () => location.pathname.includes('/service'),
   //    [location],
   // );
   return (
      <div
         className={cx(
            classes.layout,
            'layout',
            // isServiceRoute && 'layout-services',
         )}
      >
         {children}
      </div>
   );
};

export default Layout;
