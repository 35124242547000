import { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import Col from '../../../ui/layouts/col/Col';
import PaymentMethodBox, {
   PaymentMethodBoxProps,
} from '../../../ui/paymentMethodBox/PaymentMethodBox';
import {
   selectAppLayout,
   selectSingleServiceState,
   selectValidationState,
} from '../../../../store/selectors';
import Input from '../../../form/input/Input';
import Dropdown from '../../../form/input/dropdown/Dropdown';
import { PaymentMethod } from '../../../../store/services/servicesTypes';
import {
   selectPaymentMethodForPackagesAction,
   resetPaymentMethodValidationErrorAction,
} from '../../../../store/services/servicesAction';
import AddInput from '../../../ui/addInput/AddInput';

export type PaymentInputErrorLabels = {
   invalidError: string | null;
   forbiddenError: string | null;
   globalError: string | null;
};

interface DynamicPaymentMethodState {
   [key: string]: string;
}
interface PaymentMethodsFormProps
   extends Pick<
      PaymentMethodBoxProps,
      'selectedPaymentMethod' | 'clearInputsCallback'
   > {
   dynamicPaymentMethodState: DynamicPaymentMethodState;
   setDynamicPaymentMethodState: (e: DynamicPaymentMethodState) => void;
   resetErrorAction: () => void;
   debouncedValidationPaymentInputHandler?: (
      paramName: string,
      validationUrl: string,
      errorLabels: PaymentInputErrorLabels,
      prefix?: string,
   ) => void;
   debouncedValidationRegex: (
      paramName: string,
      regex: string,
      prefix?: string,
   ) => void;
   errorMsgLabel?: any;
   onSelectPaymentClick: (methods: PaymentMethod) => void;
   setSelectedFromDropdownState: (isSelected: boolean) => void;
   isUserTyping: (typingState: boolean) => void;
   hidden?: boolean;
}

const PaymentMethodsFormSection = ({
   dynamicPaymentMethodState,
   setDynamicPaymentMethodState,
   resetErrorAction,
   debouncedValidationPaymentInputHandler,
   debouncedValidationRegex,
   errorMsgLabel,
   setSelectedFromDropdownState,
   isUserTyping,
   hidden = false,

   ...rest
}: PaymentMethodsFormProps) => {
   const { selectedPaymentMethod, onSelectPaymentClick } = rest;
   const dispatch = useDispatch();

   // Refs
   const paymentInputRef = useRef<any>(null);
   // App state
   const { paymentMethods } = useSelector(selectSingleServiceState, shallowEqual);
   const { paymentMethodInput } = useSelector(selectValidationState, shallowEqual);
   const { choosePaymentMethod, addNewPhoneLabel } = useSelector(
      selectAppLayout,
      shallowEqual,
   );
   // Local state
   const [showNewInput, setShowNewInput] = useState(false);

   const clearInputHandler = (paramName: string) => {
      setShowNewInput(false);
      resetErrorAction();
      dispatch(resetPaymentMethodValidationErrorAction());
      setDynamicPaymentMethodState({
         ...dynamicPaymentMethodState,
         [paramName]: '',
      });
   };

   const sortedPaymentMethods = useMemo(() => {
      if (!paymentMethods) return [];
      return [...paymentMethods].sort((a, b) => a.order - b.order);
   }, [paymentMethods]);

   useEffect(() => {
      if (
         paymentInputRef &&
         paymentInputRef.current &&
         errorMsgLabel[paymentInputRef?.current?.name]
      ) {
         paymentInputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errorMsgLabel, paymentMethodInput.errorMessage]);

   useEffect(() => {
      if (!sortedPaymentMethods) return;

      // Sets initial payment method to first payment type returned from backend(when you open the page, default clicked payment method is MOBILE)
      const initialSelectedPaymentMethod = sortedPaymentMethods?.[0];
      if (initialSelectedPaymentMethod) {
         onSelectPaymentClick(initialSelectedPaymentMethod);
         // Sets default ID for payment method, we need to loop through the paymentMethods array in package obj, and find payment method that has that ID.
         dispatch(
            selectPaymentMethodForPackagesAction(initialSelectedPaymentMethod.id),
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Col
         className={`payment-method-col ${hidden ? 'hidden' : ''}`}
         heading={
            choosePaymentMethod ||
            '#### No localization for param choosePaymentMethod ####'
         }
         stepCount="3"
      >
         {sortedPaymentMethods?.length > 1 && (
            <div className="payment-method-wrap">
               {sortedPaymentMethods.map((methods) => {
                  if (sortedPaymentMethods.length === 1) return;
                  return (
                     <PaymentMethodBox
                        {...rest}
                        key={methods.id}
                        id={methods.id}
                        label={methods.label || ''}
                        name={methods.name}
                        iconUrl={methods.iconUrl}
                        onClick={() => onSelectPaymentClick(methods)}
                     />
                  );
               })}
            </div>
         )}

         {/* Inputs render -methods params array */}
         {sortedPaymentMethods.map((methods) => {
            return methods?.paymentMethodParams?.map((param) => {
               // Render input field if type is string or number`
               if (
                  ['String', 'Integer', 'Number'].includes(param?.columnType) &&
                  !param.dropdownValues
               ) {
                  return (
                     selectedPaymentMethod.id === methods.id && (
                        <Input
                           className={param.prefix && 'has-prefix'}
                           prefixLabel={param.prefixLabel}
                           prefix={param.prefix}
                           ref={paymentInputRef}
                           isLoading={paymentMethodInput.isLoading}
                           placeholder={
                              param?.prefix ? '' : param?.placeholder ?? ''
                           }
                           validationError={
                              errorMsgLabel[`dynamic${param.name}`] ||
                              paymentMethodInput.isInputValidated === false
                           }
                           validationText={
                              errorMsgLabel[`dynamic${param.name}`] ||
                              parse(paymentMethodInput.errorMessage)
                           }
                           type={param.columnType}
                           key={param.id}
                           value={dynamicPaymentMethodState?.[param.name] || ''}
                           onFocus={resetErrorAction}
                           onChange={(e) => {
                              setDynamicPaymentMethodState({
                                 ...dynamicPaymentMethodState,
                                 [param.name]: e.target.value,
                              });

                              // If input has regex value,debounce validation func
                              if (param.regex) {
                                 isUserTyping(true);
                                 debouncedValidationRegex(
                                    param.name,
                                    param.regex,
                                    param.prefix,
                                 );
                              }
                              // If input has external API validation call, debounce validation func
                              if (param.validatorUrl) {
                                 isUserTyping(true);
                                 debouncedValidationPaymentInputHandler?.(
                                    param.name,
                                    param.validatorUrl,

                                    {
                                       invalidError: param.validatorWarn,
                                       forbiddenError:
                                          param.validatorForbiddenProfile,
                                       globalError: param.validatorError,
                                    },
                                    param.prefix,
                                 );
                              }
                              // isUserTyping(false);
                           }}
                           labelText={
                              param.label ||
                              `#### No localization for param [${param.columnType}]  ${param.name} ####`
                           }
                           name={param.name}
                        />
                     )
                  );
               }

               // Render dropdown if type is enum
               if (param.columnType === 'Enum' || param.dropdownValues) {
                  return (
                     <>
                        {/* Render dropdown input */}
                        {selectedPaymentMethod.id === methods.id && !showNewInput && (
                           <Dropdown
                              validationError={errorMsgLabel[`dynamic${param.name}`]}
                              validationText={
                                 errorMsgLabel[`dynamic${param.name}`] ||
                                 parse(paymentMethodInput.errorMessage)
                              }
                              key={param.id}
                              isSearchable={false}
                              classNamePrefix="phones-dropdown"
                              onChange={(value) => {
                                 setDynamicPaymentMethodState({
                                    ...dynamicPaymentMethodState,
                                    [param.name]:
                                       value?.value || 'Initial value was null.',
                                 });

                                 setSelectedFromDropdownState(true);
                              }}
                              options={param.dropdownValues?.map(
                                 (singleDropdownValue) => {
                                    return {
                                       label: singleDropdownValue,
                                       value: singleDropdownValue,
                                    };
                                 },
                              )}
                              labelText={
                                 param.label ||
                                 `#### No localization for param [${param.columnType}]  ${param.name} ####`
                              }
                              placeholder={param.dropdownPlaceholder}
                           />
                        )}

                        {/* Render input */}
                        {showNewInput && (
                           <Input
                              ref={paymentInputRef}
                              isLoading={paymentMethodInput.isLoading}
                              showCloseIcon={!paymentMethodInput.isLoading}
                              placeholder={param?.placeholder ?? ''}
                              validationError={
                                 errorMsgLabel[`dynamic${param.name}`] ||
                                 paymentMethodInput.isInputValidated === false
                              }
                              validationText={
                                 errorMsgLabel[`dynamic${param.name}`] ||
                                 parse(paymentMethodInput.errorMessage)
                              }
                              type={param.columnType}
                              key={param.id}
                              value={dynamicPaymentMethodState[param.name]}
                              onClose={() => clearInputHandler(param.name)}
                              onFocus={resetErrorAction}
                              onChange={(e) => {
                                 setDynamicPaymentMethodState({
                                    ...dynamicPaymentMethodState,
                                    [param.name]: e.target.value,
                                 });
                                 // If input has regex value,debounce validation func
                                 if (param.regex) {
                                    debouncedValidationRegex(
                                       param.name,
                                       param.regex,
                                    );
                                 }
                                 // If input has external API validation call, debounce validation func
                                 if (param.validatorUrl) {
                                    debouncedValidationPaymentInputHandler?.(
                                       param.name,
                                       param.validatorUrl,
                                       {
                                          invalidError: param.validatorWarn,
                                          forbiddenError:
                                             param.validatorForbiddenProfile,
                                          globalError: param.validatorError,
                                       },
                                    );
                                 }
                              }}
                              labelText={
                                 param.label ||
                                 `#### No localization for param [${param.columnType}]  ${param.name} ####`
                              }
                              name={param.name}
                           />
                        )}

                        {param.columnType !== 'Enum' &&
                           selectedPaymentMethod.type === 'MOBILE' && (
                              <AddInput
                                 text={
                                    addNewPhoneLabel ||
                                    '#### No localization for param addNewPhoneLabel ####'
                                 }
                                 disabled={showNewInput}
                                 onClick={() => {
                                    setSelectedFromDropdownState(false);
                                    resetErrorAction();
                                    setShowNewInput(true);
                                    setDynamicPaymentMethodState({
                                       ...dynamicPaymentMethodState,
                                       [param.name]: '',
                                    });
                                 }}
                              />
                           )}
                     </>
                  );
               }

               return null;
            });
         })}
      </Col>
   );
};

export default PaymentMethodsFormSection;
