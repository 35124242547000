import { Dispatch } from 'redux';
import { toast } from 'react-hot-toast';
import {
   AnalyticHeaderProps,
   GetAllServicesProps,
   GetSingleServiceProps,
   PinPostProps,
   ValidationPostObjectProps,
} from '../../types/globals';
import history from '../../utils/history';
import {
   NewTransactionRequest,
   ServicesActionTypes,
   ServicesDispatchTypes,
} from './servicesTypes';

import {
   createWalletTransaction,
   deactivateConsent,
   deactivateService,
   getActiveServices,
   getAllServices,
   getServiceByCategory,
   getSingleService,
   redirectToSepPageUrl,
   sendPinCodeRequest,
   validateFormOnSubmit,
   validateInput,
   validateInputWithQuery,
   validatePhoneNumber,
   getWalletTransactionStatus,
   changeEmail,
} from '../../api/service';
import { Routes, TRANSACTION_STATUS } from '../../utils/constants';
import store from '../rootStore';
import { getBalanceAction } from '../user/userAction';
import keycloak from '../../Keycloak';
import { getRootUrl } from '../../utils/helpers';

type PaymentInputErrorLabels = {
   invalidError: string | null;
   forbiddenError: string | null;
   globalError: string | null;
};

export const getAllServicesAction =
   (serviceQuery: GetAllServicesProps) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.GET_SERVICES_START,
      });
      try {
         const getService = serviceQuery?.category
            ? getServiceByCategory
            : getAllServices;

         const result = await getService(serviceQuery);

         dispatch({
            type: ServicesActionTypes.GET_SERVICES_SUCCESS,
            payload: result.data,
         });
      } catch (error) {
         dispatch({
            type: ServicesActionTypes.GET_SERVICES_ERROR,
         });
         history.push(`/error/400`);
      }
   };

export const changeServiceCategoryAction =
   (categoryName: string) => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.CHANGE_SERVICE_CATEGORY,
         payload: categoryName,
      });
   };

export const selectPaymentMethodForPackagesAction =
   (paymentMethodId: any) => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.SELECT_PAYMENT_METHOD_FOR_PACKAGE,
         payload: paymentMethodId,
      });
   };

export const getSingleServiceAction =
   (singleServiceQuery: GetSingleServiceProps) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.GET_SINGLE_SERVICE_START,
      });
      try {
         const result = await getSingleService(singleServiceQuery);

         dispatch({
            type: ServicesActionTypes.GET_SINGLE_SERVICE_SUCCESS,
            payload: result.data,
         });
      } catch (error) {
         dispatch({
            type: ServicesActionTypes.GET_SINGLE_SERVICE_ERROR,
         });
         history.push(`/error/400`);
      }
   };

export const getActivatedServicesAction =
   (activeServiceQuery: string) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.GET_ACTIVE_SERVICE_START,
      });
      try {
         const result = await getActiveServices(activeServiceQuery);
         dispatch({
            type: ServicesActionTypes.GET_ACTIVE_SERVICE_SUCCESS,
            payload: {
               servicesInfo: result.data.servicesInfo,
               contactConsent: result.data.contactConsent,
               notificationMessage: null,
            },
         });

         history.push(Routes.ACTIVE_SERVICES_ROUTE);
      } catch (error: any) {
         dispatch({
            type: ServicesActionTypes.GET_ACTIVE_SERVICE_ERROR,
            payload:
               error?.response?.data?.message ||
               '#### No localization for param getActiveServices ####',
         });
      }
   };

export const deactivateServiceAction =
   (deactivateServiceQuery: string, errorMessage: string | null) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.REMOVE_ACTIVE_SERVICE_START,
      });

      try {
         const result = await deactivateService(deactivateServiceQuery);

         if (result?.data?.notificationMessage) {
            toast.success(result.data.notificationMessage, {
               duration: 10000,
            });
         }

         dispatch({
            type: ServicesActionTypes.REMOVE_ACTIVE_SERVICE_SUCCESS,
            payload: result.data,
         });
      } catch (error: any) {
         if (errorMessage) {
            toast.error(errorMessage, {
               duration: 10000,
            });
         }
         dispatch({
            type: ServicesActionTypes.REMOVE_ACTIVE_SERVICE_ERROR,
            payload:
               error?.response?.data?.message ||
               'Validation failed. Please contact support.',
         });
      }
   };

export const validateInputAction =
   (path: string, postObj: ValidationPostObjectProps<string>) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATE_INPUT_START,
      });
      try {
         await validateInput(path, postObj);

         dispatch({
            type: ServicesActionTypes.VALIDATE_INPUT_SUCCESS,
         });
      } catch (error: any) {
         dispatch({
            type: ServicesActionTypes.VALIDATE_INPUT_ERROR,
            payload:
               error?.response?.data?.message ||
               'Validation failed. Please contact support.',
         });
      }
   };
export const validatePaymentMethodInputAction =
   (path: string, errorLabels: PaymentInputErrorLabels) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_START,
      });

      try {
         const result = await validateInputWithQuery(path);
         if (result.data.status === 'INVALID') {
            dispatch({
               type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_ERROR,
               payload:
                  errorLabels.invalidError ||
                  '#### No localization for param DYNAMIC PHONE ERROR ####',
            });
            return;
         }

         if (result.data.status === 'FORBIDDEN_PROFILE') {
            dispatch({
               type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_ERROR,
               payload:
                  // warnPhoneNumberHLRForbiddenProfile ||
                  // result.data?.message ||
                  errorLabels.forbiddenError ||
                  '#### No localization for param warnPhoneNumberHLRForbiddenProfile ####',
            });
            return;
         }

         if (result.data.status === 'ERROR') {
            dispatch({
               type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_ERROR,
               payload:
                  // error500Response ||
                  errorLabels.globalError ||
                  '#### No localization for param error500Response ####',
            });
            return;
         }

         dispatch({
            type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_SUCCESS,
         });
      } catch (error: any) {
         dispatch({
            type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_ERROR,
            payload:
               error?.response?.data?.message ||
               'Validation failed. Please contact support.',
         });
      }
   };

export const validatePhoneAction =
   (validationPath: string, msisdn: string) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATE_PHONE_START,
      });

      if (validationPath !== null) {
         const {
            warnPhoneNumberHLRError,
            warnPhoneNumberHLRForbiddenProfile,
            error500Response,
         } = store.getState().app.layout;
         try {
            const result = await validatePhoneNumber(validationPath, msisdn);

            if (result.data.status === 'INVALID') {
               dispatch({
                  type: ServicesActionTypes.VALIDATE_PHONE_ERROR,
                  payload:
                     warnPhoneNumberHLRError ||
                     '#### No localization for param warnPhoneNumberHLRError ####',
               });
               return;
            }
            if (result.data.status === 'FORBIDDEN_PROFILE') {
               dispatch({
                  type: ServicesActionTypes.VALIDATE_PHONE_ERROR,
                  payload:
                     warnPhoneNumberHLRForbiddenProfile ||
                     '#### No localization for param warnPhoneNumberHLRForbiddenProfile ####',
               });
               return;
            }
            if (result.data.status === 'ERROR') {
               dispatch({
                  type: ServicesActionTypes.VALIDATE_PHONE_ERROR,
                  payload:
                     error500Response ||
                     '#### No localization for param error500Response ####',
               });
               return;
            }
            dispatch({
               type: ServicesActionTypes.VALIDATE_PHONE_SUCCESS,
            });
         } catch (error: any) {
            dispatch({
               type: ServicesActionTypes.VALIDATE_PHONE_ERROR,
               payload:
                  error500Response ||
                  '#### No localization for param error500Response ####',
            });
         }

         return;
      }

      dispatch({
         type: ServicesActionTypes.VALIDATE_PHONE_SUCCESS,
      });
   };

export const validateAndSubmitFormAction =
   (
      hasSubmitHandler: boolean,
      path: string,
      validationParams: ValidationPostObjectProps<string>,
      redirectToSepQueryParams: string,
      externalUrl: boolean,
      token: string | undefined,
   ) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATE_FORM_SUBMIT_START,
      });

      try {
         if (hasSubmitHandler) {
            await validateFormOnSubmit(path, validationParams);
            dispatch({
               type: ServicesActionTypes.VALIDATE_FORM_SUBMIT_SUCCESS,
            });
         }
         dispatch({
            type: ServicesActionTypes.SUBMIT_FORM,
         });

         // Redirect action to the SEP page
         await redirectToSepPageUrl(redirectToSepQueryParams, externalUrl, token);
      } catch (error: any) {
         dispatch({
            type: ServicesActionTypes.VALIDATION_ERROR_MESSAGE,
            payload:
               error?.response?.data?.message ||
               'Validation failed. Please contact support.',
         });
         dispatch({
            type: ServicesActionTypes.VALIDATE_FORM_SUBMIT_ERROR,
            payload:
               error?.response?.data?.message ||
               'Validation failed. Please contact support.',
         });
      }
   };

export const resetPhoneValidation =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATE_PHONE_RESET,
      });
   };
export const resetPaymentMethodValidation =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATE_PAYMENT_METHOD_INPUT_RESET,
      });
   };
export const resetValidationAction =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATION_RESET,
      });
   };

export const resetErrorsOnActivateServicesModal =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.MODAL_ERRORS_RESET,
      });
   };

export const resetPinTimer = () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
   dispatch({
      type: ServicesActionTypes.RESET_PIN_TIMER,
   });
};
export const getPinCodeAction =
   (domain: string, getPinObj: PinPostProps) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.GET_PIN_START,
      });

      try {
         const result = await sendPinCodeRequest(domain, getPinObj);
         dispatch({
            type: ServicesActionTypes.GET_PIN_SUCCESS,
            payload: result.data,
            phoneNo: getPinObj.msisdn,
            email: getPinObj.email,
         });
      } catch (error: any) {
         dispatch({
            type: ServicesActionTypes.GET_PIN_ERROR,
            payload:
               error?.response?.data?.message ||
               'Error sending pin, please try again later',
         });
      }
   };

export const resetValidationErrorMessageAction =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATION_ERROR_MESSAGE_RESET,
      });
   };
export const resetPaymentMethodValidationErrorAction =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.VALIDATION_ERROR_PAYMENT_METHOD_RESET,
      });
   };
export const resetPinValidationAction =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.RESET_PIN_VALIDATION,
      });
   };
export const searchServicesAction =
   (searchedServiceTerm: string) => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.SEARCH_SERVICES,
         payload: searchedServiceTerm,
      });
   };
export const clearSearchServicesAction =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.CLEAR_SEARCH_SERVICES,
      });
   };

export const resetConsentAction =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.RESET_CONSENT,
      });
   };

export const deactivateConsentAction =
   (reference: string, domain: string) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.DEACTIVATE_CONSENT_START,
      });

      try {
         await deactivateConsent(reference, domain);
         dispatch({
            type: ServicesActionTypes.DEACTIVATE_CONSENT_SUCCESS,
         });
      } catch (error: any) {
         dispatch({
            type: ServicesActionTypes.DEACTIVATE_CONSENT_ERROR,
         });
      }
   };

export const createWalletTransactionAction =
   (
      {
         country,
         serviceKey,
         initiationToken,
         operator,
         token,
         packageAmount,
         packageUnit,
         paymentMethodType,
         merchantPackageReference,
      }: NewTransactionRequest,
      analyticsHeaders: AnalyticHeaderProps,
   ) =>
   async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.NEW_TRANSACTION_START,
      });

      // TODO - do we need max attempts for transaction?
      const MAX_ATTEMPTS = 50;
      const POLL_INTERVAL = 1000;
      let attempts = 0;

      try {
         const result = await createWalletTransaction(
            {
               country,
               serviceKey,
               initiationToken,
               operator,
               token,
               packageAmount,
               packageUnit,
               paymentMethodType,
               merchantPackageReference,
            },
            analyticsHeaders,
         );

         const pollTransaction = async () => {
            try {
               const statusResult = await getWalletTransactionStatus(
                  result.data.transactionId,
                  token,
               );

               if (statusResult.data.status === TRANSACTION_STATUS.FINISHED) {
                  dispatch({
                     type: ServicesActionTypes.NEW_TRANSACTION_SUCCESS,
                     payload: statusResult.data,
                  });
                  (dispatch as any)(getBalanceAction(token));
               } else if (attempts < MAX_ATTEMPTS) {
                  attempts += 1;
                  setTimeout(pollTransaction, POLL_INTERVAL);
               } else {
                  dispatch({
                     type: ServicesActionTypes.NEW_TRANSACTION_ERROR,
                  });
               }
            } catch (error: any) {
               dispatch({
                  type: ServicesActionTypes.NEW_TRANSACTION_ERROR,
               });
            }
         };
         await pollTransaction();
      } catch (error) {
         dispatch({
            type: ServicesActionTypes.NEW_TRANSACTION_ERROR,
         });
      }
   };

export const resetNewTransaction =
   () => (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.NEW_TRANSACTION_RESET,
      });
   };

export const emailChange = (
   username: string,
   email: string,
   language: string,
   token: string,
) => {
   return async (dispatch: Dispatch<ServicesDispatchTypes>) => {
      dispatch({
         type: ServicesActionTypes.EMAIL_CHANGE_START,
      });

      try {
         await changeEmail(username, email, language, token);
         dispatch({
            type: ServicesActionTypes.EMAIL_CHANGE_SUCCESS,
         });
         keycloak.logout({ redirectUri: getRootUrl() });
      } catch (error: any) {
         dispatch({
            type: ServicesActionTypes.EMAIL_CHANGE_FAILED,
            payload: error,
         });
         if (error.response?.data?.message) {
            return error.response.data.message;
         }
         return 'Error on updating email address!';
      }
   };
};
