import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { DropdownProps } from '../../../../../types/globals';

import {
   selectAppLayout,
   selectEmailsForDropdown,
} from '../../../../../store/selectors';
import Dropdown from '../../../../form/input/dropdown/Dropdown';
import Input from '../../../../form/input/Input';
import { isEmailEmpty } from '../../../../../utils/helpers';
import AddInput from '../../../../ui/addInput/AddInput';

interface EmailValues {
   selectedEmail: null | DropdownProps;
   newEmail: string;
}
interface EmailInputSectionProps {
   emailState: EmailValues;
   setEmailState: (e: any) => void;
   resetErrorAction?: () => void;
   // emailValidatedState: null | boolean | any;
   setEmailValidatedState: (e: any) => void;
   errorMsgLabel?: any;
   emailSingleServiceLabel?: any;
}

const EmailSection = ({
   emailState,
   setEmailState,
   resetErrorAction,
   // emailValidatedState,
   setEmailValidatedState,
   errorMsgLabel,
   emailSingleServiceLabel,
}: EmailInputSectionProps) => {
   const emailRef = useRef<any>(null);
   const emailDropdownRef = useRef<any>(null);

   const emailsDropdown = useSelector(selectEmailsForDropdown);
   const [showNewEmailInput, setShowNewEmailInput] = useState(!emailsDropdown);
   const [emailValidationError, setEmailValidationError] = useState(false);

   const containEmailDropdownValues = emailsDropdown && emailsDropdown.length > 0;

   useEffect(() => {
      // Scroll to dropdown element when we have an error on submit
      if (emailDropdownRef && emailDropdownRef.current && errorMsgLabel?.email) {
         emailDropdownRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
         });
      }

      // !Prevents the scroll to the null ref, when user clicks on close input field
      if (emailRef.current === null) return;

      // Scroll to input element when we have an error on submit
      if (
         (emailRef && emailRef.current && errorMsgLabel?.email) ||
         emailValidationError
      ) {
         emailRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
   }, [errorMsgLabel, emailValidationError, setEmailState, emailDropdownRef]);

   const debouncedValidationEmailHandler = useDebouncedCallback(() => {
      setEmailValidationError(isEmailEmpty(emailState));

      setEmailValidatedState(!isEmailEmpty(emailState));
   }, 1000);

   const newEmailInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmailState({
         ...emailState,
         newEmail: e.target.value,
      });

      // setEmailValidationError(isEmailEmpty(emailValue));
      debouncedValidationEmailHandler();
   };

   const clearEmailInputHandler = () => {
      setShowNewEmailInput(false);
      setEmailValidationError(false);
      setEmailState({
         selectedEmail: null,
         newEmail: '',
      });
   };

   const {
      warnWrongEmailFormat,
      checkEmailIsCorrectLabel,
      chooseEmailLabel,
      emailLabel,
      addNewEmailLabel,
      importEmailLabel,
   } = useSelector(selectAppLayout, shallowEqual);

   return (
      <>
         {containEmailDropdownValues && !showNewEmailInput && (
            <span
               ref={(el) => {
                  emailDropdownRef.current = el;
               }}
            >
               <Dropdown
                  validationError={errorMsgLabel?.email}
                  validationText={
                     errorMsgLabel?.email ||
                     '#### No localization for param errorMsgLabel?.email ####'
                  }
                  isSearchable={false}
                  classNamePrefix="phones-dropdown"
                  defaultValue={emailState.selectedEmail}
                  onChange={(emails) => {
                     setEmailValidatedState(true);
                     setEmailState({
                        ...emailState,
                        selectedEmail: emails,
                     });
                     if (emails) {
                        setEmailValidationError(false);
                     }
                     resetErrorAction?.();
                  }}
                  options={emailsDropdown}
                  labelText={
                     emailSingleServiceLabel ||
                     emailLabel ||
                     '#### No localization for param emailLabel ####'
                  }
                  placeholder={
                     chooseEmailLabel ||
                     '#### No localization for param chooseEmailLabel ####'
                  }
               />
            </span>
         )}

         {(!containEmailDropdownValues || showNewEmailInput) && (
            <Input
               ref={emailRef}
               showCloseIcon={containEmailDropdownValues}
               validationError={emailValidationError || errorMsgLabel?.email}
               validationText={
                  errorMsgLabel?.email ||
                  warnWrongEmailFormat ||
                  '#### No localization for param warnWrongEmailFormat ####'
               }
               // autoFocus
               labelText={
                  emailSingleServiceLabel ||
                  emailLabel ||
                  '#### No localization for param emailLabel ####'
               }
               name="email"
               type="email"
               placeholder={
                  importEmailLabel ||
                  '#### No localization for param importEmailLabel ####'
               }
               value={emailState.newEmail}
               onFocus={resetErrorAction}
               // onBlur={() => setEmailValidationError(false)}
               onChange={(e) => newEmailInputHandler(e)}
               onClose={clearEmailInputHandler}
               bottomLabel={checkEmailIsCorrectLabel}
            />
         )}

         {containEmailDropdownValues && (
            <AddInput
               text={
                  addNewEmailLabel ||
                  '#### No localization for param addNewEmailLabel ####'
               }
               disabled={showNewEmailInput}
               onClick={() => {
                  resetErrorAction?.();
                  setShowNewEmailInput(true);
                  setEmailState({ ...emailState, selectedEmail: null });
               }}
            />
         )}
      </>
   );
};

export default EmailSection;
