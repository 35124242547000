// InlineEmailEdit.tsx

import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ReactComponent as PenIcon } from '../../../assets/img/Icon material-edit.svg';
import ConfirmationModal from '../../modal/confirmationModal/ConfirmationModal';

interface InlineEmailEditProps {
   email: string;
   onUpdateEmail: (newEmail: string) => void;
   errorMessage: string;
}

const InlineEmailEdit: React.FC<InlineEmailEditProps> = ({
   email,
   onUpdateEmail,
   errorMessage,
}) => {
   const [isEditing, setIsEditing] = useState(false);
   const [isSaveDisabled, setIsSaveDisabled] = useState(false);
   const [editedEmail, setEditedEmail] = useState(email);
   const [error, setError] = useState(errorMessage);
   const [confirmModalOpen, setConfirmModalOpen] = useState(false);

   const regex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

   const debouncedEmailRegexValidation = useDebouncedCallback(() => {
      if (!regex.test(editedEmail)) {
         setError('Email address is not valid!');
      } else {
         setError('');
         setIsSaveDisabled(false);
      }
   }, 1000);

   useEffect(() => {
      setError(errorMessage);
      setEditedEmail(email);
   }, [errorMessage]);

   const handleEmailClick = () => {
      setIsEditing(true);
   };

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditedEmail(e.target.value);
      setIsSaveDisabled(true);
      debouncedEmailRegexValidation();
   };

   const handleSaveClick = () => {
      setConfirmModalOpen(true);
   };

   const handleCancelClick = () => {
      setEditedEmail(email);
      setError('');
      setIsEditing(false);
   };

   return (
      <div>
         <>
            {isEditing ? (
               <div className="inline-edit-container">
                  <input
                     type="text"
                     value={editedEmail}
                     className={`input-inline-email ${errorMessage && 'error'}`}
                     onChange={handleInputChange}
                     // eslint-disable-next-line jsx-a11y/no-autofocus
                     autoFocus
                  />
                  <div className="error-label">
                     <span>{error}</span>
                  </div>
                  <div className="save-cancel-buttons-container">
                     <button
                        type="button"
                        className={`inline-action-buttons green-button ${
                           isSaveDisabled && 'disabled'
                        }`}
                        onClick={handleSaveClick}
                        disabled={isSaveDisabled}
                     >
                        Save
                     </button>
                     <button
                        type="button"
                        className="inline-action-buttons white-button"
                        onClick={handleCancelClick}
                     >
                        Cancel
                     </button>
                  </div>
               </div>
            ) : (
               <>
                  <button type="button" className="email-button">
                     <span>{email}</span>
                  </button>
                  <PenIcon className="penIcon" onClick={handleEmailClick} />
                  <div className="error-label">
                     <span>{error}</span>
                  </div>
               </>
            )}
            <ConfirmationModal
               headerText="Change email address"
               text="Are you sure you want to change your email address?"
               isOpen={confirmModalOpen}
               onRequestClose={() => {
                  setConfirmModalOpen(false);
               }}
               onCancel={() => {
                  setConfirmModalOpen(false);
               }}
               onConfirm={() => {
                  onUpdateEmail(editedEmail);
                  setConfirmModalOpen(false);
                  setIsEditing(false);
               }}
            />
         </>
      </div>
   );
};

export default InlineEmailEdit;
