import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Image from '../../ui/image/Image';
import { selectAuthState, selectAppLayout } from '../../../store/selectors';
import { ServiceDetailsState } from '../../../pages/servicePage/serviceDetailsPage/ServiceDetailsPage';
import { useModalQueryParam } from '../../../utils/hooks';

interface ServiceCardProps {
   image?: any;
   title?: string;
   description?: string;
   serviceKey: string;
   categoryName?: string;
   categoryCode: string;
   serviceDetailsURL?: string | null;
   activateURL?: string | null;
   onClick?: () => void;
   disabled: boolean;
   disabledServiceLabel?: string;
}

const ThumbnailServiceCard = ({
   image,
   title,
   serviceKey,
   categoryCode,
   onClick,
   activateURL,
   disabled,
   disabledServiceLabel,
}: ServiceCardProps) => {
   const history = useHistory();
   const { data } = useSelector(selectAuthState);
   const enableClickableServiceCard = !disabled;
   const { addQueryParamOnModalClick } = useModalQueryParam();
   const { domain } = useSelector(selectAppLayout);

   const onActivateClickHandler = () => {
      // Block for modal
      if (
         domain === 'marketspace.centili.com' ||
         domain === 'play.pluxee.uy' ||
         domain === 'pluxee-stage.centili.cloud'
      ) {
         onClick?.();
         addQueryParamOnModalClick(serviceKey, categoryCode);
         return;
      }

      if (activateURL) {
         history.push({
            pathname: `/${title?.replaceAll(' ', '')}`,
            search: `category=${categoryCode}`,
            state: { serviceKey } as ServiceDetailsState,
         });
         return;
      }

      history.push(`/service/${serviceKey}?data=${data}&category=${categoryCode}`);
   };

   return (
      <div
         tabIndex={0}
         role="button"
         {...(enableClickableServiceCard && {
            onClick: () => onActivateClickHandler(),
         })}
         className={cx('service-card', 'error-field', 'service-card-thumbnail', {
            clickable: enableClickableServiceCard,
         })}
         title={disabled ? disabledServiceLabel : ''}
      >
         <div>
            <Image image={image} className="service-thumbnail-image" />
            <div className="service-title">{title}</div>
         </div>
      </div>
   );
};

export default ThumbnailServiceCard;
