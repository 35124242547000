import { Dispatch } from 'redux';
import {
   deactivateService,
   getActiveServicesWithToken,
   getBalance,
   getBalanceHistory,
} from '../../api/service';
import { UserDispatchTypes, UserActionTypes } from './userTypes';

export const getBalanceAction =
   (token: string) => async (dispatch: Dispatch<UserDispatchTypes>) => {
      try {
         const { data } = await getBalance(token);
         dispatch({
            type: UserActionTypes.GET_USER_BALANCE,
            payload: data,
         });
      } catch (error: any) {
         console.error({ error });
      }
   };

export const getBalanceHistoryAction =
   (token: string) => async (dispatch: Dispatch<UserDispatchTypes>) => {
      try {
         dispatch({
            type: UserActionTypes.GET_USER_BALANCE_HISTORY_START,
         });
         const { data } = await getBalanceHistory(token);
         dispatch({
            type: UserActionTypes.GET_USER_BALANCE_HISTORY_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: UserActionTypes.GET_USER_BALANCE_HISTORY_ERROR,
            payload:
               error?.response?.data?.message || 'Error fetching balance history',
         });
      }
   };

export const getActivatedServicesWithTokenAction =
   (token: string, domain: string) =>
   async (dispatch: Dispatch<UserDispatchTypes>) => {
      dispatch({
         type: UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_START,
      });
      try {
         const result = await getActiveServicesWithToken(token, domain);
         dispatch({
            type: UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_SUCCESS,
            payload: {
               servicesInfo: result.data.servicesInfo,
               contactConsent: result.data.contactConsent,
               notificationMessage: null,
            },
         });
      } catch (error: any) {
         dispatch({
            type: UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_ERROR,
            payload:
               error?.response?.data?.message || 'Failed to get activated services',
         });
      }
   };

export const deactivateUserServiceAction =
   (deactivateServiceQuery: string) =>
   async (dispatch: Dispatch<UserDispatchTypes>) => {
      dispatch({
         type: UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_START,
      });

      try {
         const result = await deactivateService(deactivateServiceQuery);

         dispatch({
            type: UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_SUCCESS,
            payload: result.data,
         });
      } catch (error: any) {
         dispatch({
            type: UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_ERROR,
            payload:
               error?.response?.data?.message ||
               'Validation failed. Please contact support.',
         });
      }
   };
