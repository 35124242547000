import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { selectAppLayout, selectAuthState } from '../../../store/selectors';
import { ServiceDetailsState } from '../../../pages/servicePage/serviceDetailsPage/ServiceDetailsPage';
import Button from '../../ui/button/Button';
import ServiceBoxDecorator from '../../ui/serviceBoxDecorator/ServiceBoxDecorator';
import { ServiceBoxDecoratorProperties } from '../../../store/services/servicesTypes';
import boxDecoratorClasses from '../../ui/serviceBoxDecorator/ServiceBoxDecorator.module.scss';

interface ServiceCardProps {
   image?: any;
   title?: string;
   description?: string;
   serviceKey: string;
   // categoryName?: string;
   categoryCode?: string;
   serviceDetailsURL?: string | null;
   activateURL?: string | null;
   serviceDecoratorProps?: ServiceBoxDecoratorProperties | null;
   disabled: boolean;
   disabledServiceLabel?: string;
}

const CarouselServiceCard = ({
   image,
   title,
   description,
   serviceKey,
   serviceDetailsURL,
   categoryCode,
   activateURL,
   serviceDecoratorProps,
   disabled,
   disabledServiceLabel,
}: ServiceCardProps) => {
   const history = useHistory();
   const { data } = useSelector(selectAuthState);
   const { domain } = useSelector(selectAppLayout, shallowEqual);

   const {
      buttonActivateAllServicesLabel,
      buttonActivateAllServicesExternalLabel,
      serviceDetailsURLLabel,
      hideViewServiceButtonOnServiceCard,
   } = useSelector(selectAppLayout, shallowEqual);

   const onActivateClickHandler = () => {
      if (activateURL) {
         history.push({
            pathname: `/${title?.replaceAll(' ', '')}`,
            search: `category=${categoryCode}`,
            state: { serviceKey } as ServiceDetailsState,
         });
         return;
      }

      history.push(`/service/${serviceKey}?data=${data}&category=${categoryCode}`);
   };
   const activateLabel = activateURL
      ? buttonActivateAllServicesExternalLabel
      : buttonActivateAllServicesLabel;

   return (
      <div
         tabIndex={0}
         role="button"
         className={cx('carousel-container', boxDecoratorClasses.carousel, {
            clickable: !disabled,
         })}
         title={disabled ? disabledServiceLabel : ''}
         onClick={() => {
            if (
               domain === 'marketspace.centili.com' ||
               domain === 'play.pluxee.uy' ||
               domain === 'pluxee-stage.centili.cloud'
            ) {
               // eslint-disable-next-line no-restricted-globals
               location.href =
                  'https://instantplay.centili.com/demo/pzdAauY7wukexSpz';
            } else {
               onActivateClickHandler();
            }
         }}
      >
         <ServiceBoxDecorator
            type={serviceDecoratorProps?.serviceBoxDecoratorType || null}
            color={serviceDecoratorProps?.serviceBoxDecoratorColor || ''}
            text={serviceDecoratorProps?.serviceBoxDecoratorText || ''}
            orientation={serviceDecoratorProps?.serviceBoxDecoratorOrientation || ''}
         />
         <div className="image">
            <img src={image} alt="" />
         </div>
         <div className="left-content">
            <div className="service-title-wrap">
               <h5 className="service-title">{title}</h5>
               <p className="service-description">{parse(description || '')}</p>
            </div>
            <div className="btn-wrap">
               {!hideViewServiceButtonOnServiceCard && (
                  <Button
                     name={serviceKey}
                     title={
                        activateLabel ||
                        '#### No localization for param buttonActivateLabel ####'
                     }
                     onClick={onActivateClickHandler}
                  />
               )}

               {serviceDetailsURL && (
                  <a className="service-details-btn" href={serviceDetailsURL}>
                     {serviceDetailsURLLabel}
                  </a>
               )}
            </div>
         </div>
      </div>
   );
};

export default CarouselServiceCard;
