/* eslint-disable no-else-return */
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation } from 'react-router-dom';

import { SwiperSlide } from 'swiper/react';
import ServiceRow from './serviceRow/ServiceRow';
import ServiceCard from './serviceCard/ServiceCard';
import {
   changeServiceCategoryAction,
   getAllServicesAction,
} from '../../store/services/servicesAction';
import {
   selectAppLayout,
   selectDisplayedServices,
   selectAllServices,
   selectSearchParam,
} from '../../store/selectors';

import CategoryFilter from '../categoryFilter/CategoryFilter';
import Animation from '../ui/animation/Animation';
import CarouselServiceCard from './carouselServiceCard/CarouselServiceCard';
import Button from '../ui/button/Button';
import { useLocalStorage } from '../../utils/hooks';
import {
   BROWSER_STORAGE_KEYS,
   CATEGORY_QUERY_KEY,
   MODAL_QUERY_KEY,
} from '../../utils/constants';
import BigBoxServiceCard from './bigBoxServiceCard/BigBoxServiceCard';
import BannerServiceCard from './bannerServiceCard/BannerServiceCard';
import ServiceDetailsModal from '../../pages/servicePage/serviceDetailsModal/ServiceDetailsModal';
import ThumbnailServiceCard from './thumbnailServiceCard/ThumbnailServiceCard';
import { Service } from '../../store/services/servicesTypes';

type ServicesProps = {
   showCategoryFilterFlag?: boolean;
   fullWidth?: boolean;
   fetchByCategory?: string;
};
const Services = ({
   showCategoryFilterFlag = true,
   fullWidth = false,
   fetchByCategory,
}: ServicesProps) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const location = useLocation();

   const queryParams = new URLSearchParams(location.search);
   const hasModalQueryKey = queryParams.has(MODAL_QUERY_KEY);

   const displayedServices = useSelector(selectDisplayedServices);
   const searchParam = useSelector(selectSearchParam);
   const allServices = useSelector(selectAllServices);
   const { defaultLanguage, domain, showCategoryFilter, searchNoResultLabel } =
      useSelector(selectAppLayout, shallowEqual);
   const [localStorageSelectedLanguage] = useLocalStorage(
      BROWSER_STORAGE_KEYS.localStorage.selectedLanguage,
      '',
   );
   const searchedServices = useMemo(() => {
      if (!searchParam) return displayedServices;

      const filteredServices = displayedServices.services.map((el) => {
         return {
            ...el,
            services: el.services.filter(
               (cat) =>
                  cat.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
                  el.gridType === 'BIGBOX' ||
                  el.gridType === 'BANNER',
            ),
         };
      });

      return {
         ...displayedServices,
         services: filteredServices,
      };
   }, [searchParam, displayedServices]);
   useEffect(() => {
      if (allServices.length !== 0) return;
      const servicesQueryParams = {
         language:
            localStorageSelectedLanguage !== ''
               ? localStorageSelectedLanguage
               : defaultLanguage,
         domain,
         ...(fetchByCategory && {
            category: fetchByCategory,
         }),
      };
      dispatch(getAllServicesAction(servicesQueryParams));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // Not showing animation during search
   const IntroNavigation = !searchParam ? Animation : React.Fragment;

   const ifCategoryHasServices =
      searchedServices?.services?.filter((service) => service?.services?.length > 0)
         .length > 0;

   const serviceStyle = {
      paddingBottom: '3rem',
      ...(fullWidth && { maxWidth: '100%' }),
   };
   const serviceVariants: string[] = [
      'SLIDING',
      'CAROUSEL',
      'DEFAULT',
      'THUMBNAILS',
      'EXPANDABLE_GRID',
      'BIGBOX',
      'BANNER',
      'MULTI_IMAGE',
   ];

   const selectedServiceVariant = {};

   // eslint-disable-next-line no-restricted-syntax
   for (const serviceVariant of serviceVariants) {
      selectedServiceVariant[serviceVariant] = true;
   }

   const servicesPerRow = 4;

   const [next, setNext] = useState(servicesPerRow);

   const handleMoreServices = () => {
      setNext(next + servicesPerRow);
   };

   const handleLessServices = () => {
      setNext(servicesPerRow);
   };

   const removeModalQueryKey = () => {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete(MODAL_QUERY_KEY);
      queryParams.delete(CATEGORY_QUERY_KEY);

      history.push({
         ...location,
         search: queryParams.toString(),
      });
   };

   useEffect(() => {
      if (searchedServices.services) {
         setTimeout(() => {
            window.scrollTo(0, 0);
         }, 1000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleModalClick = () => {
      // TODO- delete this after testing, not needed anymore. Modal is opened with query key
   };

   const services = searchedServices.services
      .filter((service) => service?.services.length > 0)
      .map((service, i) => {
         const serviceGridType = service.gridType || 'DEFAULT';
         const serviceDecoratorProps = service.serviceBoxDecoratorProperties;

         const components = {
            SLIDING: ServiceCard,
            CAROUSEL: CarouselServiceCard,
            THUMBNAILS: ThumbnailServiceCard,
            EXPANDABLE_GRID: IntroNavigation,
            DEFAULT: ServiceCard,
            BIGBOX: BigBoxServiceCard,
            BANNER: BannerServiceCard,
            MULTI_IMAGE: ThumbnailServiceCard,
         };

         const Component = components[serviceGridType];

         if (selectedServiceVariant[serviceGridType]) {
            if (serviceGridType.includes('EXPANDABLE_GRID')) {
               return (
                  <>
                     <ServiceRow
                        key={`${service.categoryName}_${i}`}
                        categoryTitle={service.categoryName}
                        gridProperties={service.gridProperties}
                        gridType={service.gridType}
                     >
                        {service.services.slice(0, next).map((singleService) => (
                           <>
                              <h1>{singleService.name}</h1>

                              <Component
                                 key={singleService.serviceKey}
                                 categoryName={service.categoryName}
                                 categoryCode={service.categoryCode}
                                 title={singleService.name}
                                 description={singleService?.description ?? ''}
                                 serviceKey={singleService.serviceKey}
                                 image={singleService.image}
                                 serviceDetailsURL={singleService.serviceDetailsURL}
                                 activateURL={singleService.activateURL}
                                 serviceDecoratorProps={serviceDecoratorProps}
                                 disabled={singleService.disabled}
                                 disabledServiceLabel={
                                    singleService.disabledServiceLabel
                                 }
                              />
                           </>
                        ))}
                     </ServiceRow>
                     <div className="show-more-less-service-buttons-container">
                        {next < service.services?.length &&
                           service.services?.length > 4 && (
                              <Button
                                 className="show-more-services-button"
                                 title="Show More"
                                 onClick={handleMoreServices}
                              />
                           )}
                        {next === service.services?.length &&
                           service.services?.length > 4 && (
                              <Button
                                 className="show-more-services-button"
                                 title="Show Less"
                                 onClick={handleLessServices}
                              />
                           )}
                     </div>
                  </>
               );
            }
            if (serviceGridType.includes('BIGBOX')) {
               return (
                  <ServiceRow
                     key={`${service.categoryName}_${i}`}
                     categoryTitle={service.categoryName}
                     gridProperties={{ rowSize: 1 }}
                     gridType={service.gridType}
                  >
                     <BigBoxServiceCard
                        services={service.services}
                        categoryCode={service.categoryCode}
                        onClick={() => handleModalClick()}
                        serviceDecoratorProps={serviceDecoratorProps}
                     />
                  </ServiceRow>
               );
            }

            if (serviceGridType.includes('BANNER')) {
               return (
                  <ServiceRow
                     key={`${service.categoryName}_${i}`}
                     gridProperties={{ rowSize: 1 }}
                     gridType={service.gridType}
                  >
                     {service.services.slice(0, 1).map((singleService) => (
                        <BannerServiceCard
                           key={singleService.serviceKey}
                           // categoryName={service.categoryName}
                           title={singleService.bannerTitle ?? ''}
                           description={singleService?.shortDescription ?? ''}
                           // serviceKey={singleService.serviceKey}
                           image={singleService.image}
                           // serviceDetailsURL={singleService.serviceDetailsURL}
                           // activateURL={singleService.activateURL}
                           serviceDecoratorProps={serviceDecoratorProps}
                        />
                     ))}
                  </ServiceRow>
               );
            }

            if (serviceGridType.includes('MULTI_IMAGE')) {
               const singleService = service.services[0];

               return (
                  <ServiceRow
                     key={`${service.categoryName}_${i}`}
                     categoryTitle={service.categoryName}
                     gridProperties={service.gridProperties}
                     sliderProperties={service.sliderProperties}
                     gridType={service.gridType}
                  >
                     {service.imageUrls?.map((imageUrl, index) => (
                        <SwiperSlide key={`${singleService.serviceKey}_${index}`}>
                           <Component
                              key={singleService.serviceKey}
                              onClick={() => handleModalClick()}
                              categoryName={service.categoryName}
                              categoryCode={service.categoryCode}
                              title={singleService.name}
                              description={singleService?.description ?? ''}
                              serviceKey={singleService.serviceKey}
                              image={imageUrl}
                              serviceDetailsURL={singleService.serviceDetailsURL}
                              activateURL={singleService.activateURL}
                              serviceDecoratorProps={serviceDecoratorProps}
                              disabled={singleService.disabled}
                              disabledServiceLabel={
                                 singleService.disabledServiceLabel
                              }
                           />
                        </SwiperSlide>
                     ))}
                  </ServiceRow>
               );
            }
            // THIS WILL HANDLE SLIDING, CAROUSEL AND THUMBNAILS (AND DEFAULT?)
            return (
               <ServiceRow
                  key={`${service.categoryName}_${i}`}
                  categoryTitle={service.categoryName}
                  gridProperties={service.gridProperties}
                  sliderProperties={service.sliderProperties}
                  gridType={service.gridType}
               >
                  {service.services.map((singleService: Service, index: any) => (
                     <SwiperSlide key={`${singleService.serviceKey}_${index}`}>
                        <Component
                           key={singleService.serviceKey}
                           // onClick={() => handleModalClick()}
                           categoryName={service.categoryName}
                           categoryCode={service.categoryCode}
                           title={singleService.name}
                           description={singleService?.description ?? ''}
                           serviceKey={singleService.serviceKey}
                           image={singleService.image}
                           serviceDetailsURL={singleService.serviceDetailsURL}
                           activateURL={singleService.activateURL}
                           serviceDecoratorProps={serviceDecoratorProps}
                           disabled={singleService.disabled}
                           disabledServiceLabel={singleService.disabledServiceLabel}
                        />
                     </SwiperSlide>
                  ))}
               </ServiceRow>
            );
         }

         return (
            <ServiceRow
               key={`${service.categoryName}_${i}`}
               categoryTitle={service.categoryName}
               gridProperties={service.gridProperties}
               gridType={service.gridType}
            >
               {service.services.map((singleService: any) => (
                  <IntroNavigation animationType="services" key={uuidv4()}>
                     <ServiceCard
                        key={singleService.serviceKey}
                        onClick={() => handleModalClick()}
                        categoryName={service.categoryName}
                        categoryCode={service.categoryCode}
                        title={singleService.name}
                        description={singleService?.description ?? ''}
                        serviceKey={singleService.serviceKey}
                        image={singleService.image}
                        serviceDetailsURL={singleService.serviceDetailsURL}
                        activateURL={singleService.activateURL}
                        serviceDecoratorProps={serviceDecoratorProps}
                        disabled={singleService.disabled}
                        disabledServiceLabel={singleService.disabledServiceLabel}
                     />
                  </IntroNavigation>
               ))}
            </ServiceRow>
         );
      });

   return (
      <>
         <section className="service-section" style={serviceStyle}>
            {showCategoryFilter && showCategoryFilterFlag && (
               <CategoryFilter
                  onClick={(e) => dispatch(changeServiceCategoryAction(e))}
               />
            )}
            {ifCategoryHasServices ? (
               services
            ) : (
               <div style={{ height: '200px' }}>
                  <h1 className="search-not-found-label">{searchNoResultLabel}</h1>
               </div>
            )}
         </section>
         {hasModalQueryKey && (
            <ServiceDetailsModal
               isOpen={hasModalQueryKey}
               onRequestClose={removeModalQueryKey}
            />
         )}
      </>
   );
};

export default Services;
