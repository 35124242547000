// 1.ACTION TYPES
// Actions constants
// eslint-disable-next-line import/no-cycle
import {
   ActivatedServicesResponse,
   ActiveServicesResponse,
   ActiveServicesConsent,
} from '../services/servicesTypes';

export enum UserActionTypes {
   GET_USER_BALANCE = '@@user/GET_USER_BALANCE',
   GET_USER_BALANCE_HISTORY_START = '@@services/GET_USER_BALANCE_HISTORY_START',
   GET_USER_BALANCE_HISTORY_SUCCESS = '@@services/GET_USER_BALANCE_HISTORY_SUCCESS',
   GET_USER_BALANCE_HISTORY_ERROR = '@@services/GET_USER_BALANCE_HISTORY_ERROR',
   GET_ACTIVE_SERVICE_WITH_TOKEN_START = '@@services/GET_ACTIVE_SERVICE_WITH_TOKEN_START',
   GET_ACTIVE_SERVICE_WITH_TOKEN_SUCCESS = '@@services/GET_ACTIVE_SERVICE_WITH_TOKEN_SUCCESS',
   GET_ACTIVE_SERVICE_WITH_TOKEN_ERROR = '@@services/GET_ACTIVE_SERVICE_WITH_TOKEN_ERROR',
   REMOVE_USER_ACTIVE_SERVICE_START = '@@services/REMOVE_USER_ACTIVE_SERVICE_START',
   REMOVE_USER_ACTIVE_SERVICE_SUCCESS = '@@services/REMOVE_USER_ACTIVE_SERVICE_SUCCESS',
   REMOVE_USER_ACTIVE_SERVICE_ERROR = '@@services/REMOVE_USER_ACTIVE_SERVICE_ERROR',
}

type GetUserBalance = {
   type: UserActionTypes.GET_USER_BALANCE;
   payload: BalanceResponseProps;
};

type GetUserBalanceHistoryStart = {
   type: UserActionTypes.GET_USER_BALANCE_HISTORY_START;
};
type GetUserBalanceHistorySuccess = {
   type: UserActionTypes.GET_USER_BALANCE_HISTORY_SUCCESS;
   payload: BalanceEntryResponse[];
};
type GetUserBalanceHistoryError = {
   type: UserActionTypes.GET_USER_BALANCE_HISTORY_ERROR;
   payload: string;
};
type GetActiveServicesWithTokenStart = {
   type: UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_START;
};
type GetActiveServicesWithTokenSuccess = {
   type: UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_SUCCESS;
   payload: ActiveServicesResponse;
};
type GetActiveServicesWithTokenError = {
   type: UserActionTypes.GET_ACTIVE_SERVICE_WITH_TOKEN_ERROR;
   payload: string;
};
type RemoveUserActiveServicesStart = {
   type: UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_START;
};
type RemoveUserActiveServicesSuccess = {
   type: UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_SUCCESS;
   payload: ActiveServicesResponse;
};
type RemoveUserActiveServicesError = {
   type: UserActionTypes.REMOVE_USER_ACTIVE_SERVICE_ERROR;
};

// Actions dispatch types
export type UserDispatchTypes =
   | GetUserBalance
   | GetUserBalanceHistoryStart
   | GetUserBalanceHistorySuccess
   | GetUserBalanceHistoryError
   | GetActiveServicesWithTokenStart
   | GetActiveServicesWithTokenError
   | GetActiveServicesWithTokenSuccess
   | RemoveUserActiveServicesStart
   | RemoveUserActiveServicesSuccess
   | RemoveUserActiveServicesError;

// 2.API REQUEST TYPES
// All services response
export interface BalanceResponseProps {
   keycloakId: string;
   amount: number;
   currency: string;
   storefrontCode: string;
}
export interface BalanceEntryResponse {
   id: number;
   keycloakId: string;
   serviceKey: string;
   country: string;
   storefrontCode: string;
   amount: string;
   currency: string;
   commited: boolean;
   expirationTime: string;
   timeCreated: string;
   description: string;
}

// 3.STATE TYPES
export interface UserState {
   isLoading: boolean;
   balance: BalanceResponseProps;
   balanceHistory: BalanceEntryResponse[];
   activeServices: {
      isLoading: boolean;
      services: ActivatedServicesResponse[];
      consent: ActiveServicesConsent | null;
      errorMessage: string | null;
   };
}
